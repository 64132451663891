import db from '@/firebase/init'
import Vue from 'vue'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  data: {},
  status: {
    updating: false,
    firstLoad: false,
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  updatingState(state, bol) {
    state.status.updating = bol
  },

  setSettings(state, payload) {
    payload.forEach(doc => {
      Vue.set(state.data, doc.id, doc.data().value)
    })
  },

  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * UPDATE SETTINGS
   *----------------------------------------------------------------------------*/
  updateSettings({ state, commit, dispatch }) {
    commit('updatingState', true)
    let batch = db.batch()
    
    Object.keys(state.data).forEach(key => {
      let docRef = db.collection('settings').doc(key)
      batch.set(docRef, { value: state.data[key] })
    })
    
    batch.commit()
    .then(() => {
      commit('updatingState', false)
      dispatch('showSuccess', 'Settings updated', { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * GET SETTINGS
   *----------------------------------------------------------------------------*/
  getSettings({ state, commit }, force = false) {
    if (!state.status.firstLoad || force) {
      db.collection('settings').get()
      .then(snapshot => {
        if (snapshot.size) {
          commit('setSettings', snapshot)
          commit('updateStatus', { firstLoad: true })
        }
      })
      .catch(error => {
        console.log(error.message)
      })
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}