import Vue from 'vue'
import moment from 'moment'
import _capitalize from 'lodash/capitalize'

Vue.filter('sliceText', text => {
  let txt = text.split("")
  return `${txt[0]}.`
})

Vue.filter('formatDate', date => {
  return moment(date).format('MMM D, YYYY')
})

Vue.filter('simpleDate', date => {
  return moment(date).format('D MMMM YYYY')
})

Vue.filter('monthYear', date => {
  return moment(date).format('MMMM YYYY')
})

Vue.filter('downloadDate', date => {
  return moment(date).format('D/MM/YYYY')
})

Vue.filter('fromNow', date => {
  return moment(date).fromNow(true)
})

Vue.filter('capitalize', text => {
  return _capitalize(text)
})

Vue.filter('numberFormat', number => {
  return new Intl.NumberFormat().format(number)
})

Vue.filter('fileSize', size => {
  let convert = size / 1000
  let type = 'KB'
  
  if (convert > 1000) {
    convert = convert / 1000
    type = 'MB'

    if (convert > 1000) {
      convert = convert / 1000
      type = 'GB'
    }
  }

  convert = new Intl.NumberFormat().format(convert)

  return `${Math.round(convert).toFixed(0)} ${type}`
})

Vue.filter('fileName', text => {
  let filename = text.split('_')
  filename.splice(0,1)
  return filename.join('_')
})

Vue.filter('amount', value => {
  return parseFloat(value/100).toFixed(2)
})

Vue.filter('amountNoneDecimal', value => {
  let amount = Math.round(parseFloat(value/100))
  amount = new Intl.NumberFormat().format(amount)
  return amount
})


