import db from '@/firebase/init'
// import e from 'cors'
import firebase from 'firebase'
import moment from 'moment'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  userAvailability: [],
  availability: [],
  setJobSeekerAvailability: [],
  isYearAvailable: {},
  tempDate: null,
  status: {
    error: null,
    showDialog: false,
    updating: false,
    firstLoad: false,
    getting: false,
    confirmError: null,
    adding: false,
  }
}

/*------------------------------------------------------------------------------
* MUTATIONS
*----------------------------------------------------------------------------*/
const mutations = {
  setError(state, message) {
    state.status.error = message
  },

  setFirstLoad(state, bol) {
    state.status.firstLoad = bol
  },

  setGetting(state, bol) {
    state.status.getting = bol
  },

  setAvailability(state, data) {
    if (Array.isArray(data)) {
      data = Object.assign({}, data)
    }
    
    state.availability = data
  },

  setUserAvailability(state, payload) {
    if (payload.size) {
      payload.forEach(item => {
        let data = item.data()
        data.id = item.id
        data.ref = item.ref
        state.userAvailability.push(data)
      })
    }
  },

  setJobSeekerAvailability(state, payload) {
    if (payload.size) {
      payload.forEach(item => {
        let data = item.data()
        data.id = item.id
        data.ref = item.ref
        state.setJobSeekerAvailability.push(data)
      })
    }
  },

  setIsYearAvailable(state, payload) {
    state.isYearAvailable = payload
  },

  initIsYearAvailable(state) {
    state.isYearAvailable = {}
  },

  initJobSeekerAvailability(state) {
    state.setJobSeekerAvailability = []
  },

  initUserAvailability(state) {
    state.userAvailability = []
  },

  removeUserAvailability(state, id) {
    let availability = state.userAvailability.find(a => a.id == id)
    state.userAvailability.splice(state.userAvailability.indexOf(availability), 1)
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {

  async availability({ commit, dispatch, rootState }, payload) {
    let currentUser = firebase.auth().currentUser

    commit('setGetting', true)

    if (payload.action == 'delete') {

      await db.collection('availabilities')
        .doc(payload.available.id)
        .get()
        .then(async snapshot => {
          if (snapshot.exists) {
            await snapshot.ref.delete()
              .then(() => {
                commit('setAvailability', payload.available)
                dispatch('showSuccess', 'Availability removed on the calendar.', { root: true })
                dispatch('checkAvailableThisYear')
                commit('setGetting', false)
              })
              .catch(error => {
                dispatch('showError', error.message, { root: true })
              })
          }
        })
        .catch(error => {
          commit('setError', error.message)
        })
    } else if (payload.action == 'update') {
      let eventData = {}
      let eventId = payload.available.id
      await db.collection('availabilities')
        .doc(eventId)
        .get()
        .then(async snapshot => {
          if (snapshot.exists) {
            eventData = {
              startDate: payload.available.startDate,
              endDate: payload.available.endDate,
              startHr: payload.available.startHours,
              startMin: payload.available.startMinutes,
              endHr: payload.available.endHours,
              endMin: payload.available.endMinutes,
              timezone: payload.available.timezone,
              display: rootState.user.user.firstName + "'s available time (" + payload.available.startHours + ":" + payload.available.startMinutes + '-' + payload.available.endHours + ":" + payload.available.endMinutes + ")",
              updatedAt: Date.now(),
            }
            await snapshot.ref.update(eventData)
              .then(() => {
                payload.available.display = rootState.user.user.firstName + "'s available time (" + payload.available.startHours + ":" + payload.available.startMinutes + '-' + payload.available.endHours + ":" + payload.available.endMinutes + ")"
                dispatch('showSuccess', 'Availability already updated on the calendar.', { root: true })
                commit('setAvailability', payload.available)
                commit('setGetting', false)
              })
              .catch(error => {
                dispatch('showError', error.message, { root: true })
              })
          }
        })
        .catch(error => {
          commit('setError', error.message)
        })
    } 
    else if (payload.action == 'store') {

      if (payload.available && payload.available.entireYear) {
        // let thisYear = moment().year()
        let startYear = moment().format('YYYY-MM-DD')
        let endYear = payload.available.entireYear == 'full' ? moment().endOf("year").format('YYYY-MM-DD') : moment().add(3, 'months').format('YYYY-MM-DD')

        await db.collection('availabilities')
        .where('userid', '==', currentUser.uid)
        .get()
        .then(snapshot => {
          if (snapshot.size) {
            let batch = db.batch()

            snapshot.forEach(doc => {
              if (doc.data().thisYear) batch.delete(doc.ref)
            })

            return batch.commit()
          }
        })

        let eventData = {
          userid: currentUser.uid,
          startDate: startYear,
          endDate: endYear,
          startHr: '00',
          startMin: '00',
          endHr: '12',
          endMin: '00',
          color: 'blue',
          thisYear: true,
          entireYear: payload.available.entireYear,
          display: rootState.user.user.firstName + " is available here",
          createdAt: Date.now(),
          updatedAt: Date.now()
        }

        await db.collection('availabilities').add(eventData)
          .then(() => {
            dispatch('showSuccess', 'Availability added on the calendar.', { root: true })
            // eventData.id = snapshot.id
            // commit('setAvailability', eventData)
            commit('setGetting', false)
            dispatch('checkAvailableThisYear')
          })
          .catch(error => {
            commit('setError', error.message)
          })
      }
    }
  },

  async saveAvailability({ commit, dispatch, rootState }, payload) {
    let currentUser = firebase.auth().currentUser
    commit('setGetting', true)
    let userName = rootState.user.user.firstName

    let saveDate = payload.eventData.data.split('/')
    let color = null
    let timeDefault = []
    let availStatus = null
    if (saveDate[1] == 'available') {
      availStatus = 'available'
      color = 'blue'
      timeDefault[0] = '00'
      timeDefault[1] = '00'
      timeDefault[2] = '12'
      timeDefault[3] = '00'
    } else {
      if (payload.eventData.notes) {
        availStatus = 'not available Notes: ' + payload.eventData.notes
      } else {
        availStatus = 'not available'
      }

      timeDefault[0] = '12'
      timeDefault[1] = '00'
      timeDefault[2] = '23'
      timeDefault[3] = '59'
      color = 'red'
    }


    let choosenDate = saveDate[0].split('|')
    let setDate = {
      start: null,
      end: null
    }

    if (choosenDate.length > 1) {
      setDate.start = choosenDate[0]
      setDate.end = choosenDate[1]
    } else {
      setDate.start = choosenDate[0]
      setDate.end = choosenDate[0]
    }

    let eventData = {
      userid: currentUser.uid,
      startDate: setDate.start,
      endDate: setDate.end,
      startHr: timeDefault[0],
      startMin: timeDefault[1],
      endHr: timeDefault[2],
      endMin: timeDefault[3],
      color: color,
      thisYear: false,
      display: userName + "'s " + availStatus,
      createdAt: Date.now(),
      updatedAt: Date.now()
    }

    await db.collection('availabilities').add(eventData)
      .then(snapshot => {
        dispatch('showSuccess', 'Availability added on the calendar.', { root: true })
        // dispatch('getUserAvailability')
        eventData.id = snapshot.id
        commit('setAvailability', eventData)
        commit('setGetting', false)
      })
      .catch(error => {
        commit('setError', error.message)
      })
  },

  async removeAvailability({ commit, dispatch }, payload) {
    commit('setGetting', true)

    await db.collection('availabilities')
    .doc(payload.available.id).delete()
    .then(() => {
      commit('setAvailability', payload.available)
      dispatch('showSuccess', 'Availability removed on the calendar.', { root: true })
      dispatch('checkAvailableThisYear')
      commit('setGetting', false)
      commit('removeUserAvailability', payload.available.id)
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
    })

  },

  checkAvailableThisYear({ commit }) {
    commit('setGetting', true)

    let currentUser = firebase.auth().currentUser

    commit('initIsYearAvailable')
    
    db.collection('availabilities')
      .where('userid', '==', currentUser.uid)
      .get()
      .then(snapshot => {
        if (snapshot.size) {

          snapshot.forEach(data => {
            let date = data.data()
            date.id = data.id
            date.ref = data.ref
            if (date.thisYear) {
              let thisYear = moment().year()
              let getYear = date.endDate.split('-')
              if (getYear[0] == thisYear) {
                commit('setIsYearAvailable', date)
              }
            }
          })
          // commit('setUserAvailability', snapshot)
        }
        commit('setGetting', false)
      })
      .catch(error => {
        commit('setError', error.message)
      })

  },

  /*------------------------------------------------------------------------------
   * GET USER AVAILABILITY
   *----------------------------------------------------------------------------*/
  async getUserAvailability({ commit }, payload) {
    let userId = ''
    
    if (payload) {
      userId = payload
    } 
    else {
      let currentUser = firebase.auth().currentUser
      userId = currentUser.uid
    }

    commit('setFirstLoad', true)
    commit('setGetting', true)
    commit('initUserAvailability')

    await db.collection('availabilities')
    .where('userid', '==', userId)
    .get()
    .then(snapshot => {
      if (snapshot.size) {
        commit('setUserAvailability', snapshot)
      }
      commit('setGetting', false)
    })
    .catch(error => {
      commit('setError', error.message)
    })
  },

  async getJobseekerAvailability({ commit }) {
    commit('setGetting', true)
    await db.collection('availabilities')
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          commit('initJobSeekerAvailability')
          commit('setJobSeekerAvailability', snapshot)
        }
        commit('setGetting', false)
      })
      .catch(error => {
        commit('setError', error.message)
      })
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
