import _omit from 'lodash/omit'
import _ from 'lodash'
import { talentIndex } from '@/algolia/init'
import algoliasearch from "algoliasearch"

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const searchData = () => {
  return {
    options: [],
    distance: 100,
    position: null,
    skillsets: null,
    dates: null,
    address: null,
  }
}

const state = {
  templates: [],
  hits: [],
  data: searchData(),
  mainSearch: {
    position: null,
    skills: [],
    dates: null,
    address: null,
  },
  status: {
    saving: false,
    orderBy: 'createdAt',
    ordering: 'desc',
    searching: false,
    searched: false,
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  savingState(state, bol){
    state.status.saving = bol
  },

  addSearch(state, payload) {
    if (!state.templates.find(t => t.id == payload.id)) {
      state.templates.push(payload)
    }
  },

  setData(state, payload) {
    if (!payload) state.data = { options: [] } 
    else state.data = Object.assign({}, payload)
  },

  clearDateName(state) {
    state.data.name = ''
  },

  removeTemplate(state, payload) {
    state.templates.splice(state.templates.indexOf(payload), 1)
  },

  clearExperience(state) {
    state.data.experience = null
  },
  
  clearDistance(state) {
    state.data.distance = null
  },

  setMainSearch(state, data) {
    state.data.position = data.position || null
    state.data.skillsets = data.skillsets || null
    state.data.dates = data.dates
    state.data.address = data.address
  },

  searchingState(state, bol) {
    state.status.searching = bol
  },

  setHits(state, payload) {
    let hits = _.uniq(payload)
    state.hits = hits
  },

  clearHits(state) {
    state.hits = []
    state.status.searched = false
  },

  resetData(state) {
    Object.assign(state.data, searchData())
  },

  searchedState(state, bol) {
    state.status.searched = bol
  },

  clearAddress(state) {
    state.mainSearch.address = null
  },
  
  setAddress(state, payload) {
    state.mainSearch.address = payload
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
 const actions = {
  /*------------------------------------------------------------------------------
   * SAVE SEARCH TEMPLATE
   *----------------------------------------------------------------------------*/
  async saveSearch({ state, commit, dispatch, rootState }) {
    commit('savingState', true)
    let user = rootState.user.user
    let data = state.data
    console.log('save template')
    
    await user.ref.collection('searchTemplates')
    .add(_omit(data, ['id', 'ref']))
    .then((docRef) => {
      data.ref = docRef
      data.id = docRef.id
      commit('addSearch', data)
      commit('savingState', false)
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
      commit('savingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * GET TEMPLATES
   *----------------------------------------------------------------------------*/
  getTemplates({ commit, rootState }) {
    let user = rootState.user.user
    
    if (user) {
      user.ref.collection('searchTemplates')
      .get().then(snapshot => {
        if (snapshot.size) {
          snapshot.forEach(doc => {
            let data = doc.data()
            data.id = doc.id
            data.ref = doc.ref
            commit('addSearch', data)
          })
        }
      })
      .catch(error => {
        console.log(error.message)
      })
    }
  },

  /*------------------------------------------------------------------------------
   * DELETE TEMPLATE
   *----------------------------------------------------------------------------*/
  async deleteTemplate({ commit, dispatch }, template) {
    await template.ref.delete()
    .then(() => {
      commit('removeTemplate', template)
      dispatch('showSuccess', 'Template deleted', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * SEARCH USER FROM ALGOLIA
   *----------------------------------------------------------------------------*/
  searchTeachers({ commit, dispatch }, term) {
    commit('searchingState', true)
    commit('searchedState', true)
    
    talentIndex.search(term)
    .then(({ hits }) => {
      if (hits.length) {
        commit('setHits', hits)
        commit('searchingState', false)
      }
      else {
        dispatch('showError', 'No results found.', { root: true })
        commit('searchingState', false)
      }
    })
    .catch(error => {
      console.log(error)
      commit('searchingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * MIGRATE FROM 
   *----------------------------------------------------------------------------*/
  migrateUsersOldAccount() {
    const sourceAlgolia = algoliasearch(
      'GKI21A85ES',
      '719dae9f09e0391738b8f06937b6da2c'
    )
    
    const destinationAlgolia = algoliasearch(
      'RTMAK76GOV',
      'fdbaaaf6e2d120872c57d8de7feb8634'
    )

    var sourceIndex = sourceAlgolia.initIndex('Talents')
    var destinationIndex = destinationAlgolia.initIndex('Talents')

    sourceIndex.search('')
    .then(({ hits }) => {
      if (hits.length) {
        destinationIndex
        .saveObjects(hits)
        .then(() => {
          console.log('objects saved')
        })
      }
    }) 
  }
 }

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}