import db from '@/firebase/init'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  schools: [{
    CentreName: 'Please wait'
  }],
  status: {
    error: null,
    showDialog: false,
    getting: false,
    updating: false,
    firstLoad: false,
    confirmError: null,
    adding: false,
  }
}


/*------------------------------------------------------------------------------
* MUTATIONS
*----------------------------------------------------------------------------*/
const mutations = {
  setError(state, message) {
    state.status.error = message
  },

  setAddingState(state, bol) {
    state.status.adding = bol
  },

  setShowDialog(state, bol) {
    state.status.showDialog = bol
  },

  setUpdatingState(state, bol) {
    state.status.updating = bol
  },

  setFirstLoad(state, bol) {
    state.status.firstLoad = bol
  },

  setGetting(state, bol) {
    state.status.getting = bol
  },

  setSchoolsList(state, payload) {
    state.schools = []
    payload.forEach(school => {
      let data = school.data()
      data.id = school.id
      data.ref = school.ref
      state.schools.push(data)
    })
  },
  
  setSchool(state, payload) {
    state.schools.forEach(school => {
      if(school.CentreName == 'Please wait') {
        state.schools = []
      }
    })
      let data = payload.data()
      data.id = payload.id
      data.ref = payload.ref
      state.schools.push(data)
  },

  defaultSchoolList(state) {
    state.schools = [{
      CentreName: 'Please wait'
    }]
  },

  initSchoolsList(state) {
    state.schools = []
  },
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  
/* -------------------------------------------------------------------------- */
/*                               GET SCHOOL LIST                              */
/* -------------------------------------------------------------------------- */
  getSchoolList({ commit }) {
    commit('setGetting', true)
    commit('setFirstLoad', true)

    commit('defaultSchoolList')
    
    db.collection('schoolsList')
    .get()
    .then( snapshot => {
      if (snapshot.size) {
        commit('setSchoolsList', snapshot)
        commit('setGetting', false)
      }
    })
    .catch( error => {
      console.log(error.message)
    })
  },

/* -------------------------------------------------------------------------- */
/*                               GET SCHOOL LIST                              */
/* -------------------------------------------------------------------------- */
getSchoolByName({ commit }, payload) {
  commit('setGetting', true)
  
  db.collection('schoolsList')
  .where('CentreName', '==', payload)
  .limit(1).get()
  .then( snapshot => {
    if (snapshot.size) {
      commit('setSchool', snapshot.docs[0])
      commit('setGetting', false)
    }
  })
  .catch( error => {
    console.log(error.message)
  })
},




}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
