import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      themes: {
        light: {
          accent: '#FC7E29',
          primary: '#10353E',
          secondary: '#10353E',
          dark: '#4d4d4d',
          anchor: '#10353E',
          success: '#8bdf55'
        },
        
        dark: {
          accent: '#FC7E29',
          primary: '#10353E',
          secondary: '#10353E',
          dark: '#4d4d4d',
          anchor: '#10353E',
          success: '#8bdf55'
        },
      },
      options: { customProperties: true },
    },
  });
