import db from '@/firebase/init'
import firebase from 'firebase'
import moment from 'moment'
import Vue from 'vue'
import _ from 'lodash'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  jobs: [],
  job: null,
  myJobs: [],
  favorites: [],
  adminJobs: [],
  templates: [],
  employerJobs: [],
  jobStatus: {
    inprogress: 'Application In Progress',
    hired: 'Hired',
    closed: 'Closed',
    declined: 'Declined',
    open: 'Open',
  },
  status: {
    error: null,
    adding: false,
    activePage: 1,
    getting: false,
    closing: false,
    updating: false,
    deleting: false,
    detailFiles: [],
    firstLoad: false,
    displayRange: 60,
    uploading: false,
    showDialog: false,
    savingNote: false,
    confirmError: null,
    myJobFirstLoad: false,
    gettingTemplates: false,
    gettingEmployerJobs: false,
  }
}

/* -------------------------------------------------------------------------- */
/*                                   GETTERS                                  */
/* -------------------------------------------------------------------------- */
const getters = {
  isOwner: () => (job) => {
    let user = firebase.auth().currentUser
    return job.useruid == user.uid
  },

  isFavorite: (state) => (id) => {
    if (state.favorites.length) return !!state.favorites.find(fav => fav.jobId == id)
    else return false
  },

  getClassStatus: (state) => (status) => {
    let returnClass = ['warning--text', 'grey--text' , 'success--text', 'red--text','indigo--text'];
    
    if (status == state.jobStatus.inprogress || status == 'Inprogress') {
      return returnClass[0]
    } 
    else if (status == state.jobStatus.hired) {
      return returnClass[2]
    } 
    else if (status == state.jobStatus.declined || status == 'Declined') {
      return returnClass[3]
    } 
    else if (status == state.jobStatus.open || status == 'Open') {
      return returnClass[4]
    } 
    else {
      return returnClass[1]
    }
  },

  jobData: (state) => (id) => {
    if (state.jobs) return state.jobs.find(job => job.id == id) || {}
    else return {}
  },

  activeJobs: (state) => (all = false) => {
    let jobs = all ? state.jobs : state.myJobs

    if (jobs) {
      jobs = jobs.filter(job => {
        return Vue.prototype.$startDate(job.contractDates) > moment().valueOf()
      })

      jobs = jobs.filter(job => job.status !== 'Closed')
      
      return jobs
    }
    else return []
  },
  
  lapsedJobs: (state) => (all) => {
    let jobs = all ? state.jobs : state.myJobs
    
    if (jobs) {
      jobs = jobs.filter(job => {
        return Vue.prototype.$startDate(job.contractDates) < moment().valueOf()
      })

      return jobs.filter(job => job.status !== 'Closed')
    }
    else return []
  },

}

/*------------------------------------------------------------------------------
* MUTATIONS
*----------------------------------------------------------------------------*/
const mutations = {
  setAddingState(state, bol) {
    state.status.adding = bol
  },

  gettingState(state, bol) {
    state.status.getting = bol
  },

  setJob(state, payload) {
    state.job = payload
  },

  setError(state, message) {
    state.status.error = message
  },

  setFirstLoad(state, bol) {
    state.status.firstLoad = bol
  },

  setAdminJobs(state, payload) {
    if(payload.size) {
      payload.forEach(job => {
        let data = job.data()
        data.id = job.id
        data.ref = job.ref
        state.adminJobs.push(data)
      })
    }
  },

  reInitAdminJobs( state ) {
      state.adminJobs = []
  },

  setMyJobFirstLoad(state, bol) {
    state.status.myJobFirstLoad = bol
  },

  setJobs(state, payload) {
    state.jobs.push(payload)
  },

  addFavorites(state, payload) {
      state.favorites = []
  
      payload.forEach(fav => {
        if (!state.favorites.find(f => f.id == fav.id)) {
          let data = fav.data()
          data.id = fav.id
          data.ref = fav.ref
          state.favorites.push(data)
        }
      })
    },

  addFavorite(state, payload) {
    if (!state.favorites.find(f => f.id == payload.id)) {
      state.favorites.push(payload)
    }
  },

  removeFavorite(state, payload) {
      state.favorites.splice(state.favorites.indexOf(payload), 1)
    },

  setMyJobs(state, payload) {
    if (!state.myJobs.find(j => j.id == payload.id)) {
      state.myJobs.push(payload)
    }

    state.status.getting = false
  },

  reInitJobs(state) {
    state.jobs = []
  },

  reInitMyJobs(state) {
    state.myJobs = []
  },

  setDisplayRange(state, num) {
    state.status.displayRange = num
  },

  closingState(state, bol) {
    state.status.closing = bol
  },

  closedJobStatus(state, payload) {
    let newData = Object.assign({}, payload)
    newData.status = 'Closed'
    Vue.set(state.myJobs, state.myJobs.indexOf(payload), newData)
    Vue.set(state.jobs, state.jobs.indexOf(payload), newData)
  },

  deletingState(state, bol) {
    state.status.deleting = bol
  },

  removeJob(state, payload) {
    state.myJobs.splice(state.myJobs.indexOf(payload), 1)
    state.templates.splice(state.myJobs.indexOf(payload), 1)
    state.jobs.splice(state.jobs.indexOf(payload), 1)
  },

  insertJob(state, payload) {
    if (!state.myJobs.find(j => j.id == payload.id)) {
      state.myJobs.push(payload)
    }
  },
  
  insertToJobs(state, payload) {
    if (!state.jobs.find(j => j.id == payload.id)) {
      state.jobs.push(payload)
    }
  },

  savingNoteState(state, bol) {
    state.status.savingNote = bol
  },
  
  setActivePage(state, page) {
    state.status.activePage = page
  },

  resetFile(state) {
    state.status.detailFile = null
  },

  removeFile(state, payload) {
    if (typeof payload == 'object')
      payload.job.attachments.splice(payload.job.attachments.indexOf(payload.attachment), 1)
    else
      state.status.detailFiles.splice(payload, 1)
  },

  addFile(state, files) {
    if (files && files.length) {
      files.forEach(file => {
        state.status.detailFiles.push(file)
      })
    }
  },

  uploadingState(state, bol) {
    state.status.uploading = bol
  },

  resetDetailFiles(state) {
    state.status.detailFiles = []
  },

  gettingEmployerJobsState(state, bol) {
    state.status.gettingEmployerJobs = bol
  },

  setEmployerJobs(state, payload) {
    state.employerJobs = []

    if (payload.size) {
      payload.forEach(doc => {
        let data = doc.data()
        data.id = doc.id
        data.ref = doc.ref
        state.employerJobs.push(data)
      })
    }

    state.status.gettingEmployerJobs = false
  },

  gettingTemplatesState(state, bol) {
    state.status.gettingTemplates = bol
  },

  setTemplates(state, payload) {
    state.templates = []

    if (payload.size) {
      payload.forEach(doc => {
        let template = doc.data()
        template.id = doc.id
        template.ref = doc.ref
        state.templates.push(template)
      })
    }

    state.status.gettingTemplates = false
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * CREATE JOB
   *----------------------------------------------------------------------------*/
  async storeJob({ state, commit, dispatch, rootState }, action = 'store') {
    commit('setAddingState', true)
    let user = firebase.auth().currentUser
    let data = rootState.job.job
    let task
    data.status = 'Open'
    data.createdAt = Date.now(),
    data.updatedAt = Date.now(),
    data.useruid = user.uid
    data.private = data.private || false
    data = _.omit(data, ['id', 'ref'])

    if (action == 'store') {
      task = db.collection('jobs')
    }
    else if (action == 'template') {
      task = db.collection('JobTemplates')
    }
    
    await task.add(_.omitBy(data, _.isUndefined))
    .then((docRef) => {
      dispatch('showSuccess', action == 'template' ? 'Job template saved' : 'Job successfully added.', { root: true })
      
      if (state.status.detailFiles) {
        data.ref = docRef
        data.id = docRef.id
        dispatch('uploadDetailFiles', data)
      }
      else {
        commit('setAddingState', false)
      }
    })
    .catch(error => {
      commit('setError', error.message)
      commit('setAddingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * UPLOAD DETAIL FILE
   *----------------------------------------------------------------------------*/
  async uploadDetailFiles({ state, commit }, job) {
    if (state.status.detailFiles) commit('uploadingState', true)
    var storageRef = firebase.storage().ref()
    var files = state.status.detailFiles
    var names = []
    var tasks = []
    for (var key in files) {
      let file = files[key]

      var metadata = {
        contentType: file.type
      }
      
      let name = `${Date.now()}_${file.name}`
      var task = storageRef.child(`attachments/${name}`).put(file, metadata)
      tasks.push(task)
      names.push(name)
    }

    Promise.all(tasks)
    .then(() => {
      if (job.attachments && job.attachments.length) names = names.concat(job.attachments)
      
      job.ref.update({ attachments: names })
      .then(() => {
        commit('uploadingState', false)
        commit('setAddingState', false)
        commit('resetDetailFiles')
      })
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE JOB
   *----------------------------------------------------------------------------*/
  async updateJobPost({ state, commit, dispatch, rootState }) {
    if (state.status.detailFiles) commit('uploadingState', true)
    let job = rootState.job.job
    let data = _.omit(job, ['ref', 'id', 'attachments'])
    commit('setAddingState', true)
    data.updated = Date.now()

    await job.ref.update(_.omitBy(data, _.isUndefined))
    .then(() => {
      dispatch('showSuccess', 'Job data was successfully updated.', { root: true })
      
      if (state.status.detailFiles) {
        dispatch('uploadDetailFiles', job)
      }
      else {
        commit('setAddingState', false)
      }
    })
    .catch(error => {
      commit('setError', error.message)
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE FILE
   *----------------------------------------------------------------------------*/
  deleteFile({ dispatch, rootState }, attachment) {
    var storageRef = firebase.storage().ref()
    let job = rootState.job.job
    
    storageRef.child(`attachments/${job.id}/${attachment}`)
    .delete().then(() => {
      job.attachments.splice(job.attachments.indexOf(attachment), 1)

      job.ref.update({ attachments: job.attachments })
      .then(() => {
        dispatch('showSuccess', 'Attachment deleted', { root: true })
      })
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
      job.attachments.splice(job.attachments.indexOf(attachment), 1)
      job.ref.update({ attachments: job.attachments })
    })
  },

  /*------------------------------------------------------------------------------
   * GET ALL JOBS
   *----------------------------------------------------------------------------*/
  getAllJobs({ commit, dispatch }) {
    commit('setFirstLoad', true)

    let jobs = db.collection('jobs')
    jobs = jobs.where('status', '==', 'Open')

    commit('gettingState', true)
    
    jobs = jobs.where('status', '==', 'Open')
    jobs = jobs.where('private', '==', false)
          .orderBy('createdAt', 'desc')
    jobs
      .get()
      .then(snapshot => {
        
        if (snapshot.size) {
          var storage = firebase.storage()
          commit('reInitJobs')
          
          snapshot.forEach(job => {
            let jobData = job.data()
            jobData.id = job.id
            jobData.ref = job.ref

            dispatch('schools/getSchoolByUid', jobData.useruid, { root: true })
            
            let statusTxt = 'Open'
            
            // if(!jobData.private){
              db.collection('schools')
              .where('useruid', '==', jobData.useruid)
              .limit(1).get()
              .then(snapshot => {
                if (snapshot.size) {
                  let school = snapshot.docs[0].data()
                  school.id = snapshot.docs[0].id
                  school.ref = snapshot.docs[0].ref
  
                  storage.ref(`schoollogos/${school.logo}`).getDownloadURL()
                    .then(url => {
                      jobData.logoURL = url
                      jobData.school = school
  
                      //Get Job Status
                      db.collection('applies')
                        .where('jobId', '==', jobData.id)
                        .get()
                        .then(snapshot => {
                          if (snapshot.size) {
                            statusTxt = state.jobStatus.inprogress
                            snapshot.forEach(applies => {
                              let apply = applies.data()
                              apply.id = applies.id
                              apply.ref = applies.ref
  
                              if (apply.status == state.jobStatus.hired) {
                                statusTxt = state.jobStatus.hired
                              }
                            })
                            jobData.updatedStatus = statusTxt
                            commit('setJobs', jobData)
                          } else {
                            commit('gettingState', false)
                            jobData.updatedStatus = jobData.status
                            commit('setJobs', jobData)
                          }
                        })
                        .catch(error => {
                          console.log(error.message)
                        })
  
                    })
                    .catch(error => {
                      console.log(error.message)
                      storage.ref(`schoollogos/${school.logo}`).getDownloadURL()
                        .then(url => {
                          jobData.logoURL = url
                          jobData.school = school
  
                          //Get Job Status
                          db.collection('applies')
                            .where('jobId', '==', jobData.id)
                            .get()
                            .then(snapshot => {
                              if (snapshot.size) {
                                statusTxt = state.jobStatus.inprogress
  
                                snapshot.forEach(applies => {
                                  let apply = applies.data()
                                  apply.id = applies.id
                                  apply.ref = applies.ref
  
                                  if (apply.status == state.jobStatus.hired) {
                                    statusTxt = state.jobStatus.hired
                                  }
                                })
                                jobData.updatedStatus = statusTxt
                                commit('setJobs', jobData)
                              }
                              commit('gettingState', false)
                            })
                            .catch(error => {
                              console.log(error.message)
                              commit('gettingState', false)
                            })
                        })
                    })
                  }
                  commit('gettingState', false)
                })
            // }

          })
        }
        commit('gettingState', false)
      })
      .catch(error => {
        console.log(error.message)
        commit('setError', error.message)
      })

  },

  /*------------------------------------------------------------------------------
   * SEARCH JOBS
   *----------------------------------------------------------------------------*/
  searchedJobs({ commit }, payload) {
    commit('gettingState', true)
    commit('reInitJobs')
    if (payload.data.length) {
      payload.data.forEach(job => {
        let jobs = db.collection('jobs')
        let jobSnapshot = jobs.doc(job.objectID)
        jobSnapshot
          .get()
          .then(snapshot => {
            if (snapshot.exists) {
              var storage = firebase.storage()
              let jobData = snapshot.data()
              jobData.id = snapshot.id
              jobData.ref = snapshot.ref
              
              if (jobData.status == 'Open' && jobData.private == false) {
                
                let statusTxt = 'Open'
                
                db.collection('schools')
                .where('useruid', '==', jobData.useruid)
                .limit(1).get()
                .then(snapshot => {
                  if (snapshot.size) {
                    let school = snapshot.docs[0].data()
                    school.id = snapshot.docs[0].id
                    school.ref = snapshot.docs[0].ref

                    storage.ref(`schoollogos/${school.logo}`).getDownloadURL()
                      .then(url => {
                        jobData.logoURL = url
                        jobData.school = school
                        //Get Job Status
                        db.collection('applies')
                          .where('jobId', '==', jobData.id)
                          .get()
                          .then(snapshot => {
                            if (snapshot.size) {
                              statusTxt = state.jobStatus.inprogress
                              snapshot.forEach(applies => {
                                let apply = applies.data()
                                apply.id = applies.id
                                apply.ref = applies.ref

                                if (apply.status == state.jobStatus.hired) {
                                  statusTxt = state.jobStatus.hired
                                }
                              })
                              jobData.updatedStatus = statusTxt
                              commit('setJobs', jobData)
                            } else {
                              jobData.updatedStatus = jobData.status
                              commit('setJobs', jobData)
                            }
                          })
                          .catch(error => {
                            console.log(error.message)
                          })

                      })
                      .catch(error => {
                        console.log(error.message)
                        storage.ref(`schoollogos/${school.logo}`).getDownloadURL()
                          .then(url => {
                            jobData.logoURL = url
                            jobData.school = school

                            //Get Job Status
                            db.collection('applies')
                              .where('jobId', '==', jobData.id)
                              .get()
                              .then(snapshot => {
                                if (snapshot.size) {
                                  statusTxt = state.jobStatus.inprogress

                                  snapshot.forEach(applies => {
                                    let apply = applies.data()
                                    apply.id = applies.id
                                    apply.ref = applies.ref

                                    if (apply.status == state.jobStatus.hired) {
                                      statusTxt = state.jobStatus.hired
                                    }
                                  })
                                  jobData.updatedStatus = statusTxt
                                  commit('setJobs', jobData)
                                }
                              })
                              .catch(error => {
                                console.log(error.message)
                              })
                          })
                      })
                    }
                  })
              }
            }
            commit('gettingState', false)
          })
          .catch(error => {
            commit('setError', error.message)
          })
      })
    }
    commit('gettingState', false)
  },

  /*------------------------------------------------------------------------------
   * GET JOBS CREATED BY EMPLOYER
   *----------------------------------------------------------------------------*/
  getCreatedJobs({ state, commit }) {
    commit('setMyJobFirstLoad', true)

    let currentUser = firebase.auth().currentUser
    commit('gettingState', true)

    db.collection('jobs')
      .where('useruid', '==', currentUser.uid)
      .orderBy('createdAt', 'desc')
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          var storage = firebase.storage()
          commit('reInitMyJobs')
          snapshot.forEach(job => {
            let jobData = job.data()
            jobData.id = job.id
            jobData.ref = job.ref

            //School Photo
            let statusTxt = 'Open'

            db.collection('schools')
              .where('useruid', '==', currentUser.uid)
              .limit(1).get()
              .then(snapshot => {
                if (snapshot.size) {
                  let school = snapshot.docs[0].data()
                  school.id = snapshot.docs[0].id
                  school.ref = snapshot.docs[0].ref

                  storage.ref(`schoollogos/${school.logo}`).getDownloadURL()
                    .then(url => {
                      jobData.logoURL = url
                      jobData.school = school

                      //Get Job Status
                      db.collection('applies')
                        .where('jobId', '==', jobData.id)
                        .get()
                        .then(snapshot => {
                          if (snapshot.size) {
                            statusTxt = state.jobStatus.inprogress
                            snapshot.forEach(applies => {
                              let apply = applies.data()
                              apply.id = applies.id
                              apply.ref = applies.ref

                              if (apply.status == state.jobStatus.hired) {
                                statusTxt = state.jobStatus.hired
                              }
                            })
                            jobData.updatedStatus = statusTxt
                            commit('setMyJobs', jobData)
                          } else {
                            jobData.updatedStatus = jobData.status
                            commit('setMyJobs', jobData)
                          }
                        })
                        .catch(error => {
                          console.log(error.message)
                        })

                    })
                    .catch(error => {
                      console.log(error.message)
                      storage.ref(`schoollogos/${school.logo}`).getDownloadURL()
                        .then(url => {
                          jobData.logoURL = url
                          jobData.school = school

                          //Get Job Status
                          db.collection('applies')
                            .where('jobId', '==', jobData.id)
                            .get()
                            .then(snapshot => {
                              if (snapshot.size) {
                                statusTxt = state.jobStatus.inprogress

                                snapshot.forEach(applies => {
                                  let apply = applies.data()
                                  apply.id = applies.id
                                  apply.ref = applies.ref

                                  if (apply.status == state.jobStatus.hired) {
                                    statusTxt = state.jobStatus.hired
                                  }
                                })
                                jobData.updatedStatus = statusTxt
                                commit('setMyJobs', jobData)
                              }
                            })
                            .catch(error => {
                              console.log(error.message)
                            })
                        })
                    })
                  }
                })
              })
          }
          else {
            commit('gettingState', false)
          }
        })
        .catch(error => {
          console.log(error.message)
          commit('gettingState', false)
          commit('setError', error.message)
      })

  },

  /*------------------------------------------------------------------------------
  * GET FAVORITES
  *----------------------------------------------------------------------------*/
  getFavorites({ commit }) {
    let currentUser = firebase.auth().currentUser

    db.collection('favorites')
    .where('userid', '==', currentUser.uid)
    .get()
    .then(snapshot => {
      if (snapshot.size) {
          commit('addFavorites', snapshot)
      }
    })
    .catch(error => {
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
  * TOGGLE FAVORITE
  *----------------------------------------------------------------------------*/
  toggleFavorite({ state, commit, dispatch }, id) {
    let user = firebase.auth().currentUser
    let favorite = state.favorites.find(f => f.jobId == id)
    
    if (favorite) {
      favorite.ref.delete()
      .then(() => {
          commit('removeFavorite', favorite)
          dispatch('showSuccess', 'Removed from saved jobs.', { root: true })
      })
      .catch(error => {
          console.log(error.message)
          dispatch('showError', error.message, { root: true })
      })
    }
    else {
      let data = {
          userid: user.uid,
          jobId: id,
          createdAt: Date.now(),
          updatedAt: Date.now()
      }

      db.collection('favorites').add(data)
      .then((docRef) => {
          dispatch('showSuccess', 'Job added to saved jobs.', { root: true })
          data.ref = docRef
          data.id = docRef.id
          commit('addFavorite', data)
      })
      .catch(error => {
          console.log(error.message)
          dispatch('showError', error.message, { root: true })
      })
    }
  },

  /* -------------------------------------------------------------------------- */
  /*                                    ADMIN                                   */
  /* -------------------------------------------------------------------------- */


  /* -------------------------------------------------------------------------- */
  /*                                    JOBS                                    */
  /* -------------------------------------------------------------------------- */
  getJobsAdmin({ state, commit }) {
    commit('setFirstLoad', true)

    let daysAgo = moment().subtract(parseInt(state.status.displayRange), 'days')
    daysAgo = moment(daysAgo).valueOf()
    
    let jobs = db.collection('jobs')

    if (state.status.displayRange) {
        jobs = jobs.where('createdAt', '>=', daysAgo)
    }
    
    commit('gettingState', true)
    commit('reInitAdminJobs')
    
    jobs
    .orderBy('createdAt', 'desc')
    .get()
    .then(snapshot => {
        if (snapshot.size) {   
            commit('setAdminJobs', snapshot) 
        }
        commit('gettingState', false)
    })
    .catch(error => {
        console.log(error.message)
        commit('setError', error.message)
    })

  },

  /*------------------------------------------------------------------------------
   * CLOSE JOB
   *----------------------------------------------------------------------------*/
  async closeJob({ commit, dispatch }, job) {
    commit('closingState', true)
    
    await job.ref.update({ status: 'Closed', dateClosed: Date.now() })
    .then(() => {
      commit('closedJobStatus', job)
      commit('closingState', false)
      dispatch('showSuccess', 'Moved job status to closed', { root: true })

      db.collection('applies')
      .where('jobId', '==', job.id)
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          snapshot.forEach(doc => {
            let applicant = doc.data()
            
            dispatch('notifications/createAlert', {
              recipient: applicant.userid,
              type: 'job_closed',
              source: job.title,
              created: Date.now()
            }, { root: true })
          })
        }

      })
    })
    .catch(error => {
      console.log(error.message)
      commit('closingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE JOB
   *----------------------------------------------------------------------------*/
  async deleteJob({ commit, dispatch }, job) {
    commit('deletingState', true)

    await job.ref.delete()
    .then(() => {
      commit('removeJob', job)
      commit('deletingState', false)
      dispatch('showSuccess', 'Job deleted successfully', { root: true })

      db.collection('applies')
      .where('jobId', '==', job.id)
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          snapshot.forEach(doc => {
            let applicant = doc.data()
            
            dispatch('notifications/createAlert', {
              recipient: applicant.userid,
              type: 'job_closed',
              source: job.title,
              created: Date.now()
            }, { root: true })
          })
        }

      })
    })
    .catch(error => {
      console.log(error.message)
      commit('deletingState', false)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * SAVE NOTE
   *----------------------------------------------------------------------------*/
  async saveNotes({ commit, dispatch }, job) {
    commit('savingNoteState', true)

    await job.ref.update({ notes: job.notes || null })
    .then(() => {
      commit('savingNoteState', false)
      dispatch('showSuccess', 'Job note saved', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('savingNoteState', false)
      dispatch('showError', error.message, { root: true })
    })

  },

  /*------------------------------------------------------------------------------
   * GET CLOSED JOBS
   *----------------------------------------------------------------------------*/
  async getEmployerJobs({ commit, dispatch }) {
    commit('gettingEmployerJobsState', true)
    let user = firebase.auth().currentUser
    
    db.collection('jobs')
    .where('useruid', '==', user.uid)
    .get()
    .then(snapshot => {
      if (snapshot.size) {
        commit('setEmployerJobs', snapshot)
        dispatch('apply/getApplicantByJobIds', snapshot, { root: true })

        snapshot.forEach(doc => {
          dispatch('invite/getInviteds', doc, { root: true })
        })
      }
      else {
        commit('gettingEmployerJobsState', false)
      }
    })
    .catch(error => {
      console.log(error.message)
      commit('gettingEmployerJobsState', false)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * GET JOBS BY IDS
   *----------------------------------------------------------------------------*/
  getJobsByIds({ commit, dispatch }, snapshot) {
    let ids = []
    let promises = []
    
    snapshot.forEach(application => {
      let data = application.data()

      if (data.status == 'Hired') {
        ids.push(data.jobId)
      }
    })

    for (let i in ids) {
      promises.push(db.collection('jobs').doc(ids[i]).get())
    }

    Promise.all(promises)
    .then(response => {
      if (response.length) {
        response.forEach(doc => {
          if (doc.exists) {
            let data = doc.data()
            data.id = doc.id
            data.ref = doc.ref
            commit('setJobs', data)
            dispatch('schools/getSchoolByUid', data.useruid, { root: true })
          }
        })
      }
    })
  },

  /*------------------------------------------------------------------------------
   * GET JOB BY ID
   *----------------------------------------------------------------------------*/
  getJobById({ state, commit, dispatch }, id) {
    if (!state.jobs.find(j => j.id == id)) {
      db.collection('jobs')
      .doc(id).get()
      .then(doc => {
        if (doc.exists) {
          let data = doc.data()
          data.id = doc.id
          data.ref = doc.ref
          commit('insertToJobs', data)
          dispatch('schools/getSchoolByUid', data.useruid, { root: true })
        }
      })
      .catch(error => {
        console.log(error.message)
      })
    }
  },

  /*------------------------------------------------------------------------------
   * GET JOB TEMPLATES
   *----------------------------------------------------------------------------*/
  getTemplates({ commit }) {
    let user = firebase.auth().currentUser
    commit('gettingTemplatesState', true)
    
    db.collection('JobTemplates')
    .where('useruid', '==', user.uid)
    .get()
    .then((snapshot) => {
      commit('setTemplates', snapshot)
    })
    .catch(error => {
      commit('gettingTemplatesState', false)
      console.log(error.message)
    })
  }
}
            
                  
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}