import db from '@/firebase/init'
import firebase from 'firebase'
import { loadStripe } from '@stripe/stripe-js'
import _orderBy from 'lodash/orderBy'
import moment from 'moment'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  prices: [],
  subscriptions: [],
  invoices: [],
  status: {
    getting: false,
    updating: false,
    subscribing: false,
    gettingSubs: false,
    cancelling: false,
    gettingPreview: false,
    preview: null,
    showPreview: false,
    negative: false,
    downgrading: false,
  }
}

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  activePrices: (state) => {
    if (state.prices) {
      let prices = state.prices.filter(price => {
        return price.active
      })

      prices = _orderBy(prices, (price) => {
        return price.metadata.order
      }, 'asc')

      return prices
    }
    else {
      return []
    }
  },

  activeCount: (state) => {
    return state.subscriptions.filter(sub => {
      return sub.status == 'active'
    })
  },

  getName: (state) => (id) => {
    if (state.prices.length) {
      let price = state.prices.find(p => p.id == id)
      if (price) return price.description
      else return 0
    }
  },
  
  getAmount: (state) => (id) => {
    if (state.prices.length) {
      let price = state.prices.find(p => p.id == id)
      if (price) return price.unit_amount
      else return 0
    }
  },

  getCurrency: (state) => (id) => {
    if (state.prices.length) {
      let price = state.prices.find(p => p.id == id)
      if (price) return price.currency
      else return 0
    }
  },
  
  getInterval: (state) => (id) => {
    if (state.prices.length) {
      let price = state.prices.find(p => p.id == id)
      if (price) return price.interval
      else return 0
    }
  },
  
  getIntervalCount: (state) => (id) => {
    if (state.prices.length) {
      let price = state.prices.find(p => p.id == id)
      if (price) return price.interval_count
      else return 1
    }
  },

  isSubscribed: () => {
    let sub = state.subscriptions.filter(sub => {
      return sub.status == 'active' || (sub.status == 'canceled' && (sub.current_period_end * 1000) > Date.now())
    })

    return sub.length
  },

  freeTrial: (state, getters, rootState) => {
    if (!rootState.settings.data.enableFreeTrial) 
      return false
    else if (rootState.user.user) {
      let user = rootState.user.user
      
      if (getters.isSubscribed) 
        return false
      else if (user.role == 'employer') {
        return parseInt(moment(user.trialEnd).diff(moment(), 'days'))
      }
      else 
        return true
    }
    else 
      return false
  },

  withActiveSub: (state, getters, rootState) => {
    if (!firebase.auth().currentUser) return false
    
    else if ((rootState.user.user && ['admin', 'jobseeker'].includes(rootState.user.user.role)) || (getters.freeTrial > 0)) {
      return true
    }
    else if (state.subscriptions) {
      return !!getters.isSubscribed
    }
    else {
      return false
    }
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  gettingState(state, bol) {
    state.status.getting = bol
  },

  setPrices(state, payload) {
    state.prices = []

    if (payload.size) {
      payload.forEach(doc => {
        let price = doc.data()
        price.id = doc.id
        price.ref = doc.ref
        state.prices.push(price)
      })
    }
  },

  updatingState(state, bol) {
    state.status.updating = bol
  },

  subscribingState(state, bol) {
    state.status.subscribing = bol
  },

  gettingSubsState(state, bol) {
    state.status.gettingSubs = bol
  },

  setSubscriptions(state, payload) {
    state.subscriptions = []

    if (payload.size) {
      payload.forEach(doc => {
        let sub = doc.data()
        sub.id = doc.id
        sub.ref = doc.ref
        state.subscriptions.push(sub)
      })
    }

    state.status.gettingSubs = false
  },

  cancellingState(state, bol) {
    state.status.cancelling = bol
  },

  gettingPreviewState(state, bol) {
    state.status.gettingPreview = bol
  },

  setPreview(state, payload) {
    state.status.preview = payload
  },

  showPreviewState(state, bol) {
    state.status.showPreview = bol
  },

  setInvoices(state, payload) {
    state.invoices = payload
  },

  downgradingState(state, bol) {
    state.status.downgrading = bol
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * GET PRICINGS
   *----------------------------------------------------------------------------*/
  getPackage({ commit, dispatch }) {
    commit('gettingState', true)

    db.collection('packages')
    .where('name', '==', 'TeacherFinder')
    .limit(1).get()
    .then(snapshot => {
      if (snapshot.size) {
        let doc = snapshot.docs[0]
        doc.ref.collection('prices')
        .get()
        .then(snapshot => {
          commit('setPrices', snapshot)
        })
      }
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError')
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE STATUS
   *----------------------------------------------------------------------------*/
  updateStatus({ commit, dispatch }, data) {
    commit('updatingState', true)
    
    let price = data.price
    let value = data.value
    
    price.ref.update({ active: value })
    .then(() => {
      dispatch('showSuccess', 'Status updated', { root: true })
      commit('updatingState', false)
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
      commit('updatingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * SUBSCRIBE
   *----------------------------------------------------------------------------*/
  async subscribe({ commit, dispatch }, price) {
    commit('subscribingState', true)
    let currentUser = firebase.auth().currentUser
    
    const docRef = await db
    .collection('customers')
    .doc(currentUser.uid)
    .collection('checkout_sessions')
    .add({
      price: price.id,
      automatic_tax: true,
      success_url: window.location.href,
      cancel_url: window.location.href,
    })

    docRef.onSnapshot(async (snap) => {
      const { error, sessionId } = snap.data()
      if (error) {
        console.log(error.message)
        commit('subscribingState', false)
        dispatch('showError', error.message, { root: true })
      }
      if (sessionId) {
        commit('setSubscribingState', false)
        const stripe = await loadStripe('pk_live_51Is3PcLze6jdWfG7Gn9fnQBimNcAk4NkJypJ7MOLtEbUUw6epMKWknSlSfwxdyfemA3DTLN6nn8KFSfJB4WtelHR00gf2OOSlt')
        stripe.redirectToCheckout({ sessionId })
      }
    })
  },

  /*------------------------------------------------------------------------------
   * CANCEL SUBSCRIPTION
   *----------------------------------------------------------------------------*/
  async cancelSubscription({ commit, dispatch }, sub) {
    commit('cancellingState', true)
    var cancelUserSubscription = firebase.functions().httpsCallable('cancelSubscription')

    console.log(sub.id)
    
    await cancelUserSubscription({text: sub.id})
    .then(() => {
      commit('cancellingState', false)
      dispatch('getSubscriptions')
      dispatch('showSuccess', 'Subscription cancelled', { root: true })
    })
    .catch(error => {
      console.log(error.message)  
      commit('cancellingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE SUBSCRIPTION
   *----------------------------------------------------------------------------*/
  async updateSubscription({ state, commit, dispatch }, data) {
    commit('updatingState', true)
    var updateSub = firebase.functions().httpsCallable('updateSubscription')
    data.behavior = state.status.downgrading ? 'none' : 'create_prorations'

    await updateSub(data)
    .then(() => {
      commit('updatingState', false)
      commit('showPreviewState', false)
      dispatch('showSuccess', 'Subscription updated', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('updatingState', false)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * PREVIEW PRORARTION
   *----------------------------------------------------------------------------*/
  async getPreview({ state, commit, dispatch }, data) {
    commit('gettingPreviewState', true)
    commit('downgradingState', false)
    var updateSub = firebase.functions().httpsCallable('previewProration')
    let user = firebase.auth().currentUser
    let subscription = state.subscriptions.find(sub => sub.status == 'active') 
    let newPrice = state.prices.find(price => price.id == data.price)
    let current = 0

    await subscription.price.get()
    .then(doc => current = doc.exists ? doc.data() : 0)

    await db.collection('customers')
    .doc(user.uid)
    .get()
    .then(async (doc) => {
      if (doc.exists) {
        let cust = doc.data()
        data.customer = cust.stripeId
        data.behavior = current.unit_amount > newPrice.unit_amount ? 'none' : 'create_prorations'
        if (data.behavior == 'none') commit('downgradingState', true)

        await updateSub(data)
        .then(response => {
          commit('setPreview', response)
          commit('gettingPreviewState', false)
          commit('showPreviewState', true)
        })
        .catch(error => {
          console.log(error.message)
          commit('gettingPreviewState', false)
          dispatch('showError', error.message, { root: true })
        })
      }
    })
    .catch(error => {
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * GET SUBSCRIPTIONS
   *----------------------------------------------------------------------------*/
  getSubscriptions({ commit }) {
    commit('gettingSubsState', true)
    let user = firebase.auth().currentUser

    db.collection('customers')
    .doc(user.uid)
    .collection('subscriptions')
    .orderBy('created', 'desc')
    .get()
    .then(snapshot => {
      commit('setSubscriptions', snapshot)
    })
    .catch(error => {
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * GET INVOICES BY CUSTOMER
   *----------------------------------------------------------------------------*/
  getInvoices({ commit }) {
    let user = firebase.auth().currentUser
    db.collection('customers')
    .doc(user.uid).get()
    .then(doc => {
      if (doc.exists) {
        let data = doc.data()
        var getFunc = firebase.functions().httpsCallable('getInvoices')
        
        getFunc({customer: data.stripeId})
        .then(invoices => {
          commit('setInvoices', invoices.data.data)
        })
        .catch(error => {
          console.log(error.message)
        })
      }
    })

  }

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}