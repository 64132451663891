import algoliasearch from "algoliasearch"

const algolia = algoliasearch(
  process.env.VUE_APP_ALGOLIA_APP_ID,
  process.env.VUE_APP_ALGOLIA_API_KEY
)

const userIndex = algolia.initIndex('Users')
const jobIndex = algolia.initIndex('TeacherFinder')
const talentIndex = algolia.initIndex('Talents')

export {
  userIndex,
  jobIndex,
  talentIndex
}

export default userIndex