import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import firebase from 'firebase'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/job/Jobs.vue'),
    meta: { requiresAuth: true, fullWidth: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/account/Login.vue'),
    meta: { guestOnly: true, fullWidth: true }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../views/account/Signup.vue'),
    meta: { guestOnly: true, fullWidth: true }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../views/account/ResetPassword.vue'),
    meta: { guestOnly: true, fullWidth: true }
  },
  {
    path: '/confirm-reset-password',
    name: 'ConfirmResetPassword',
    component: () => import('../views/account/ConfirmResetPassword.vue'),
    meta: { guestOnly: true, fullWidth: true }
  },
  {
    path: '/admin',
    component: () => import('../views/common/Main.vue'),
    meta: { requiresAuth: true, access: ['admin'] },
    children: [
      {
        path: '/',
        name: 'Admin',
        component: () => import('../views/account/myaccount/Main.vue'),
        meta: { access: ['admin'] }
      },
      {
        path: 'users',
        name: 'Users',
        component: () => import('../views/admin/Users.vue'),
        meta: { access: ['admin'] }
      },
      {
        path: 'jobs',
        name: 'AdminJobs',
        component: () => import('../views/admin/Jobs.vue'),
        meta: { access: ['admin'] }
      },
      {
        path: 'packages',
        name: 'Packages',
        component: () => import('../views/admin/Packages.vue'),
        meta: { access: ['admin'] }
      },
      {
        path: 'import-users',
        name: 'ImportUsers',
        component: () => import('../views/admin/ImportUsers.vue'),
      },
      {
        path: 'skillsets',
        name: 'Skillsets',
        component: () => import('../views/admin/Skillsets.vue'),
      },
      {
        path: 'positions',
        name: 'Positions',
        component: () => import('../views/admin/Positions.vue'),
      },
      {
        path: 'reporting',
        name: 'Reporting',
        component: () => import('../views/admin/Reports.vue'),
      },
      {
        path: 'general-settings',
        name: 'GeneralSettings',
        component: () => import('../views/admin/GeneralSettings.vue'),
      },
    ]
  },
  {
    path: '/inbox',
    name: 'Message',
    component: () => import('../views/Message/Message.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/inbox/:id',
    name: 'MessageData',
    component: () => import('../views/Message/MessageData.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/jobs',
    component: () => import('../views/common/Main.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '/',
        name: 'Jobs',
        component: () => import('../views/job/Jobs.vue'),
        meta: { access: ['jobseeker'], fullWidth: true }
      },
      {
        path: 'create',
        name: 'Job',
        component: () => import('../views/job/Job.vue'),
        meta: { access: ['employer', 'admin'] },
      },
      {
        path: 'my-jobs/:id',
        name: 'JobUpdate',
        component: () => import('../views/job/Job.vue'),
        meta: { access: ['admin', 'employer'] }
      },
      {
        path: 'open-job-ads',
        name: 'MyJobs',
        component: () => import('../views/job/employer/createdJobs.vue'),
        meta: { requiresAuth: true,  access: ['employer', 'admin'] },
      },
      {
        path: 'lapsed-job-ads',
        name: 'LapsedJobs',
        component: () => import('../views/job/employer/LapsedJobs.vue'),
        meta: { requiresAuth: true,  access: ['employer'] },
      },
      {
        path: 'closed-job-ads',
        name: 'JobsBooked',
        component: () => import('../views/job/employer/BookedJobs.vue'),
        meta: { requiresAuth: true,  access: ['employer', 'admin'] },
      },
      {
        path: 'job/applied',
        name: 'AppliedJob',
        component: () => import('../views/job/JobSubmitted.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'my-job/:id',
        name: 'JobProfile',
        component: () => import('../views/job/JobProfile.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'all-jobs',
        name: 'AllJobs',
        component: () => import('../views/job/admin/AllJobs.vue'),
        meta: { requiresAuth: true,  access: ['admin'] },
      },
      {
        path: 'all-booked-jobs',
        name: 'AllBookedJobs',
        component: () => import('../views/job/admin/AllBookedJobs.vue'),
        meta: { requiresAuth: true,  access: ['admin'] },
      },
      {
        path: 'templates',
        name: 'JobTemplates',
        component: () => import('../views/job/JobTemplates.vue'),
        meta: { requiresAuth: true,  access: ['admin', 'employer'] },
      },
    ]
  },
  {
    path: '/school',
    component: () => import('../views/common/Main.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'edit-school',
        name: 'School',
        component: () => import('../views/school/School.vue'),
        meta: { access: ['admin', 'employer'] }
      },
      {
        path: '/',
        name: 'Schools',
        component: () => import('../views/school/Schools.vue'),
        meta: { access: ['admin', 'employer'] }
      },
      {
        path: ':id',
        name: 'SchoolsProfile',
        component: () => import('../views/school/SchoolProfile.vue'),
        meta: { requiresAuth: true },
      }
    ]
  },
  {
    path: '/talent',
    component: () => import('../views/common/Main.vue'),
    children: [
      {
        path: '/find-a-teacher',
        name: 'Talent',
        component: () => import('../views/talent/Talents.vue'),
        meta: { fullWidth: true }
      },
    ]
  },
  {
    path: '/invites',
    component: () => import('../views/common/Main.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '/',
        name: 'Invites',
        component: () => import('../views/invite/Invites.vue'),
        meta: { access: ['jobseeker'] }
      },
    ]
  },
  {
    path: '/profile',
    component: () => import('../views/common/Main.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '/',
        name: 'Profile',
        component: () => import('../views/account/Profile.vue'),
        meta: { requiresAuth: true, fullWidth: true },
      },
      {
        path: ':id',
        name: 'TalentProfile',
        component: () => import('../views/talent/TalentProfile.vue'),
        meta: { requiresAuth: true, fullWidth: true },
      },
      {
        path: '/employer/:id',
        name: 'EmployerProfile',
        component: () => import('../views/Employer/EmployerProfile.vue'),
        meta: { requiresAuth: true, fullWidth: true },
      },
    ]
  },
  {
    path: '/availability',
    component: () => import('../views/common/Main.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '/',
        name: 'Availability',
        component: () => import('../views/availability/Availability.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: ':id',
        name: 'UserAvailability',
        component: () => import('../views/availability/UserAvailability.vue'),
        meta: { requiresAuth: true, fullWidth: true }
      },
    ]
  },
  {
    path: '/saved-jobs',
    name: 'Favorite',
    component: () => import('../views/favorite/Favorites.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/preferred-teachers',
    name: 'Favorites',
    component: () => import('../views/favorite/employer/TalentFavorites.vue'),
    meta: { access: ['employer'] }
  },
  {
    path: '/applied',
    component: () => import('../views/common/Main.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '/',
        name: 'Applied',
        component: () => import('../views/apply/Applies.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'jobs-booked',
        name: 'ClosedJobs',
        component: () => import('../views/job/JobsBooked.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: ':id',
        name: 'ReviewApplicants',
        component: () => import('../views/apply/ReviewApplicants.vue'),
        meta: { access: ['employer', 'admin'] }
      },
    ]
  },
  {
    path: '/withdraw-application',
    name: 'WithdrawApplication',
    component: () => import('../views/withdrawJob/Withdraw.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/account-settings',
    name: 'AccountSettings',
    component: () => import('../views/account/Settings.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/my-profile',
    name: 'MyProfile',
    component: () => import('../views/account/MyProfile.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/subscription',
    name: 'Subscription',
    component: () => import('../views/subscription/Subscription.vue'),
    meta: { requiresAuth: true, access: ['employer'] }
  },
  {
    path: '/my-account',
    name: 'MyAccount',
    component: () => import('../views/account/myaccount/Main.vue'),
    meta: { requiresAuth: true}
  },
  {
    path: '/employer-reports',  
    name: 'EmployerReports',
    component: () => import('../views/reports/Employer/Main'),
    meta: { requiresAuth: true, access: ['employer'] }
  },
  {
    path: '/teacher-reports',  
    name: 'TeacherReports',
    component: () => import('../views/reports/Teacher/Main'),
    meta: { requiresAuth: true, access: ['jobseeker'] }
  },
  {
    path: '/invitations',  
    name: 'Invitations',
    component: () => import('../views/talent/Invitations'),
    meta: { requiresAuth: true, access: ['jobseeker'] }
  },
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },  
  routes
})

router.beforeEach((to, from, next) => {
  let user = firebase.auth().currentUser
  
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (user && to.matched.some(record => record.meta.access)) {
      to.matched.some(record => {
        var access = record.meta.access

        if (store.state.user) {
          var checkUser = setInterval(function () {
            if (store.state.user.user && access) {
              let role = store.state.user.user.role
              access.includes(role) ? next() : next({ name: 'Home' })
              clearInterval(checkUser)
            }
          }, 100)
        }
      })

    }
    else {
      !user ? next({ name: 'Login' }) : next()
    }
  }
  else if (to.matched.some(record => record.meta.guestOnly)) {
    if (!user) next()
    else next({ name: 'MyAccount' })
  } 
  else {
    next()
  }
})

export default router
