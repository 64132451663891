<template>
  <div class="d-inline-block">
    <v-menu
      max-width="250"
      offset-y
      left
    >
      <template v-slot:activator="{ on }">
        <v-badge
          :value="!!alerts.length"
          color="accent"
          overlap
          dot
        >
          <v-btn v-on="on" small icon>
            <v-icon small>mdi-bell</v-icon>
          </v-btn>
        </v-badge>
      </template>

      <v-card>
        <v-card-text>

          <template v-if="awards.length">
            <!-- JOB AWARDED -->
            <v-card 
              v-for="award in awards"
              :key="award.id"
              :to="{ name: 'JobProfile', params: { id: award.source }, query: { notification: award.id } }" 
              class="mb-2"
              outlined
              hover
            >
              <v-card-text class="caption">
                <div class="d-flex">
                  <v-icon color="primary" left small>mdi-account-check</v-icon>
                  <span class="primary--text">Congratulations!!! A job has been awarded to you.</span>
                </div>
              </v-card-text>
            </v-card>
          </template>
          
          <!-- APPLICATION DECLINED -->
          <template v-if="declined.length">
            <v-card 
              v-for="decline in declined"
              :key="decline.id"
              :to="{ name: 'JobProfile', params: { id: decline.source }, query: { notification: decline.id } }" 
              class="mb-2"
              outlined
              hover
            >
              <v-card-text class="caption">
                <div class="d-flex">
                  <v-icon color="accent" left small>mdi-account-alert-outline</v-icon>
                  <span class="accent--text">Your application has been declined.</span>
                </div>
              </v-card-text>
            </v-card>
          </template>
          
          <!-- JOB CLOSED -->
          <template v-if="jobClosed.length">
            <v-card 
              v-for="alert in jobClosed"
              :key="alert.id"
              class="mb-2"
              outlined
              hover
            >
              <v-btn @click="deleteAlert(alert.source)" class="mr-n4" icon small absolute right>
                <v-icon small>mdi-close</v-icon>
              </v-btn>
              <v-card-text class="caption">
                <div class="d-flex align-start">
                  <v-icon color="accent" left small>mdi-account-alert-outline</v-icon>
                  <span class="accent--text">A job listing witha a title "{{ alert.source }}" you recently applied for  has been closed.</span>
                </div>
              </v-card-text>
            </v-card>
          </template>
          
          <!-- JOB INVITES -->
          <template v-if="jobInvites.length">
            <v-card 
              :to="{ name: 'Invitations'}"
              class="mb-2"
              outlined
              hover
            >
              <v-card-text class="caption">
                <div class="d-flex align-start">
                  <v-icon left small>mdi-account-plus</v-icon>
                  <span>A You have {{ jobInvites.length }} job {{ pluralize('invitation', jobInvites.length) }}.</span>
                </div>
              </v-card-text>
            </v-card>
          </template>
          
          <!-- NEW MESSAGE -->
          <v-card v-if="messages.length" :to="{ name: 'Message' }" outlined hover>
            <v-card-text class="caption">
              <v-icon color="info" left small>mdi-email-outline</v-icon>
              <span class="info--text">You have {{ messages.length }} new {{ pluralize('message', messages.length) }}</span>
            </v-card-text>
          </v-card>

          <v-alert v-if="!alerts.length" class="mb-0" type="success" border="left" text>
            You don't have any new notifications.
          </v-alert>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import pluralize from 'pluralize'
import db from '@/firebase/init'
import firebase from 'firebase'

export default {
  data() {
    return {
      pluralize
    }
  },

  computed: {
    ...mapState({
      alerts: state => state.notifications.alerts
    }),

    messages: function () {
      return this.alerts.filter(alert => {
        return alert.type == 'message'
      })
    },

    awards: function () {
      return this.alerts.filter(alert => {
        return alert.type == 'job_awarded'
      })
    },
    
    declined: function () {
      return this.alerts.filter(alert => {
        return alert.type == 'job_declined'
      })
    },
    
    jobClosed: function () {
      return this.alerts.filter(alert => {
        return alert.type == 'job_closed'
      })
    },
    
    jobInvites: function () {
      return this.alerts.filter(alert => {
        return alert.type == 'job_invitation'
      })
    },
  },

  watch: {
    '$route': function () {
      this.checkAlerts()
    }
  },

  methods: {
    ...mapActions('notifications', [
      'getAlerts',
      'deleteAlert'
    ]),

    checkAlerts() {
      // IF JOB PROFILE PAGE
      if (this.$route.name == 'Invitations') {
        if (this.jobInvites.length) {
          let batch = db.batch()

          this.jobInvites.forEach(invite => {
            batch.delete(invite.ref)
          })

          batch.commit()
        }
      }
    }
  },

  mounted() {
    this.getAlerts()
    this.checkAlerts()

    let user = firebase.auth().currentUser

    db.collection('notifications')
    .doc(user.uid)
    .collection('alerts')
    .onSnapshot(snapshot => {
      this.$store.commit('notifications/insertAlerts', snapshot)
      this.checkAlerts()
    })
  }
}
</script>