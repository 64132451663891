<template>
  <v-card max-width="270" outlined>
    <template v-if="$store.getters['user/completeProfile']">
      <v-list-item :to="{ name: 'MyAccount' }">
        <v-list-item-title class="font-weight-black primary--text heading-font">My Account Overview</v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item :to="{ name: 'Jobs' }">
        <v-list-item-title class="font-weight-black primary--text heading-font">Find a Job</v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>
      <v-card-text>
        <div class="font-weight-black primary--text heading-font">My Jobs</div>
        <v-list class="custom-items">
          <v-list-item :to="{ name: 'Applied' }" exact>
            <v-list-item-title>Open job applications</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'ClosedJobs' }">
            <v-list-item-title>Confirmed jobs</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'Favorite' }">
            <v-list-item-title>Saved jobs</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'Invitations' }">
            <v-list-item-title>Invitations</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>
      <v-list-item :to="{ name: 'Message' }">
        <v-list-item-title class="font-weight-black primary--text heading-font">My Inbox</v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>
    </template>
    <v-card-text>
      <div class="font-weight-black primary--text heading-font">My Details</div>
      <v-list class="custom-items">
        <v-list-item :to="{ name: 'Availability' }">
          <v-list-item-title>My availability</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'MyProfile' }">
          <v-list-item-title>My profile</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'AccountSettings' }">
          <v-list-item-title>Account settings</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'TeacherReports' }">
          <v-list-item-title>Reports</v-list-item-title>
        </v-list-item>
        <v-list-item @click="signout()" class="mt-5">
          <v-list-item-title>Log out</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text>
      <v-btn @click="showInviteDialog()" color="accent gradient" block>
        Refer A Friend
      </v-btn>

      <div class="mt-3 font-weight-medium caption">Click to send an email notifying your friend about TeacherFinder</div>

      <invite-dialog
        ref="inviteDialog"
      ></invite-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import InviteDialog from '../../views/talent/InviteDialog.vue'
import signout from '@/mixins/signout'

export default {
  mixins: [signout],

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      dialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: { InviteDialog },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    showInviteDialog() {
      this.$refs.inviteDialog.show = true
    }
  }
}
</script>

<style lang="scss">
.custom-items {
  .v-list-item {
    min-height: 30px;
  }
}
</style>