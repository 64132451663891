import db from '@/firebase/init'
import _omit from 'lodash/omit'
import Vue from 'vue'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  data: [{
    name: 'Please wait'
  }],
  newData: {},
  status: {
    error: null,
    showDialog: false,
    getting: false,
    updating: false,
    firstLoad: false,
    confirmError: null,
    adding: false,
    deleting: false,
  }
}


/*------------------------------------------------------------------------------
* MUTATIONS
*----------------------------------------------------------------------------*/
const mutations = {
  setError(state, message) {
    state.status.error = message
  },

  addingState(state, bol) {
    state.status.adding = bol
  },

  setShowDialog(state, bol) {
    state.status.showDialog = bol
  },

  setUpdatingState(state, bol) {
    state.status.updating = bol
  },

  setFirstLoad(state, bol) {
    state.status.firstLoad = bol
  },

  gettingState(state, bol) {
    state.status.getting = bol
  },

  setPositions(state, payload) {
    state.data = []
    payload.forEach(position => {
      let data = position.data()
      data.id = position.id
      data.ref = position.ref
      state.data.push(data)
    })
  },

  setData(state, payload) {
    state.newData = payload
  },

  insertPosition(state, payload) {
    state.data.push(payload)
  },

  updatePositionData(state, payload) {
    let position = state.data.find(p => p.id == payload.id)
    Vue.set(state.data, state.data.indexOf(position), payload)
  },

  deletingState(state, bol) {
    state.status.deleting = bol
  },

  removePosition(state, payload) {
    state.data.splice(state.data.indexOf(payload), 1)
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  
  /*------------------------------------------------------------------------------
   * GET POSITIONS
   *----------------------------------------------------------------------------*/
  getPositions({ commit, dispatch }) {
    commit('gettingState', true)
    commit('setFirstLoad', true)
    
    db.collection('positions')
    .get()
    .then( snapshot => {
      if (snapshot.size) {
        commit('setPositions', snapshot)
        commit('gettingState', false)
      }
    })
    .catch( error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
      commit('gettingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * ADD POSITION
   *----------------------------------------------------------------------------*/
  async addPosition({ state, commit, dispatch }) {
    commit('addingState', true)
    let data = Object.assign({}, state.newData)
    let task
    
    if (!data.id) task = db.collection('positions').doc()
    else task = db.collection('positions').doc(data.id)
    
    await task.set(_omit(data, ['ref', 'id']))
    .then(() => {
      dispatch('showSuccess', `Position ${data.id ? 'updated' : 'added'}`, {root: true})
      
      if (!data.id) {
        data.id = task.id
        data.ref = task
        commit('insertPosition', data)
      }
      else {
        commit('updatePositionData', data)
      }
      
      commit('addingState', false)
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, {root: true})
      commit('addingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE POSITION
   *----------------------------------------------------------------------------*/
  async deletePosition({ commit, dispatch }, position) {
    commit('deletingState', true)
    
    await position.ref.delete()
    .then(() => {
      dispatch('showSuccess', 'Position deleted', {root: true})
      commit('deletingState', false)
      commit('removePosition', position)
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, {root: true})
      commit('deletingState', false)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}

