import db from '@/firebase/init'
import firebase from 'firebase'
import moment from 'moment'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  favorite: null,
  favorites: [],
  talentFavorites: [],
  status: {
    error: null,
    showDialog: false,
    updating: false,
    firstLoad: false,
    getting: false,
    confirmError: null,
    displayRange: 60,
    adding: false,
    remoteWork: 0,
  }
}

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  favoriteTeachers: (state) => (id) => {
    if (state.talentFavorites) {
      return state.talentFavorites.find(t => t.talentId == id)
    }
  },

  isFavorite: (state) => (id) => {
    if (state.talentFavorites.length) {
      return !!state.talentFavorites.find(fav => fav.talentId == id)
    }
    else {
      return false
    }
  }
}

/*------------------------------------------------------------------------------
* MUTATIONS
*----------------------------------------------------------------------------*/
const mutations = {
  setError(state, message) {
    state.status.error = message
  },

  setFirstLoad(state, bol) {
    state.status.firstLoad = bol
  },

  setGetting(state, bol) {
    state.status.getting = bol
  },

  setJobFavorite(state, payload) {
    let data = payload.data()
    data.id = payload.id
    data.ref = payload.ref
    state.favorite = data
  },

  setJobFavorites(state, payload) {
    state.favorites = []
    
    payload.forEach(doc => {
      if (doc.exists) {
        let data = doc.data()
        data.id = doc.id
        data.ref = doc.ref
        state.favorites.push(data)
      }
    })

    state.status.getting = false
  },

  setTalentFavorites(state, payload) {
    state.talentFavorites = []

    if (payload.size) {
      payload.forEach(doc => {
        let favorite = doc.data()
        favorite.id = doc.id
        favorite.ref = doc.ref
        state.talentFavorites.push(favorite)
      })
    }

    state.status.getting = false
  },

  removeTalentFavorites(state, id) {
    let favorite = state.talentFavorites.find(f => f.id == id)
    state.talentFavorites.splice(state.talentFavorites.indexOf(favorite), 1)
  },

  addTalentToFavorites(state, payload) {
    if (!state.talentFavorites.find(f => f.id == payload.id)) 
    state.talentFavorites.push(payload)
  },

  initFavorites(state) {
    state.favorites = []
  },

  initTalentFavorites(state) {
    state.talentFavorites = []
  },

  setDisplayRange(state, num) {
    state.status.displayRange = num
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {

  async storeFavorite({ commit, dispatch }, payload) {
    let currentUser = firebase.auth().currentUser

    commit('setGetting', true)

    if (payload.update) {
      await payload.favoriteObj.ref.delete()
        .then(() => {
          dispatch('showSuccess', 'This job is remove to favourites.', { root: true })
          commit('setGetting', false)
        })
        .catch(error => {
          dispatch('showError', error.message, { root: true })
        })
    } else {
      await db.collection('favorites').add({
        userid: currentUser.uid,
        jobId: payload.id,
        createdAt: Date.now(),
        updatedAt: Date.now()
      })
        .then(() => {
          dispatch('showSuccess', 'This job is added to saved jobs.', { root: true })
          commit('setGetting', false)
        })
        .catch(error => {
          commit('setError', error.message)
        })
    }
  },


  async setTalentFavorite({ commit, dispatch }, payload) {
    let currentUser = firebase.auth().currentUser

    commit('setGetting', true)

    if (payload.update) {
      await payload.favoriteObj.ref.delete()
        .then(() => {
          dispatch('showSuccess', 'The talent has been removed from your favourites', { root: true })
          commit('setGetting', false)
        })
        .catch(error => {
          dispatch('showError', error.message, { root: true })
        })
    } else {
      await db.collection('talentFavorites').add({
        userid: currentUser.uid,
        talentId: payload.userid,
        createdAt: Date.now(),
        updatedAt: Date.now()
      })
        .then(() => {
          dispatch('showSuccess', 'This talent is added to your favourites.', { root: true })
          commit('setGetting', false)
        })
        .catch(error => {
          commit('setError', error.message)
        })
    }
  },

  getTalentFavorites({ commit, dispatch }) {
    let currentUser = firebase.auth().currentUser
    commit('setGetting', true)
    commit('setFirstLoad', true)
    commit('initTalentFavorites')

    if (currentUser) {
      db.collection('talentFavorites')
      .where('userid', '==', currentUser.uid)
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          commit('setTalentFavorites', snapshot)
          
          snapshot.forEach(doc => {
            dispatch('users/getUserByUid', doc.data().talentId, { root: true })
          })
        }
        else {
          commit('setGetting', false)
        }
      })
      .catch(error => {
        commit('setError', error.message)
      })
    }
  },

  getRemoteTalentsFav({ state, commit, dispatch }) {
    commit('setGetting', true)
    commit('setFirstLoad', true)

    let currentUser = firebase.auth().currentUser

    let daysAgo = moment().subtract(parseInt(state.status.displayRange), 'days')
    daysAgo = moment(daysAgo).valueOf()

    commit('initTalentFavorites')

    db.collection('talentFavorites')
      .where('userid', '==', currentUser.uid)
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          snapshot.forEach(favTalent => {
            let talent = favTalent.data()
            talent.id = favTalent.id
            talent.ref = favTalent.ref

            let users = db.collection('users')
            if (state.status.displayRange) {
              users = users.where('createdAt', '>=', daysAgo)
            }

            users.where('public', '==', true)
              .orderBy('createdAt', 'desc')
            users
              .get()
              .then(snapshot => {
                if (snapshot.size) {
                  snapshot.forEach(item => {
                    let data = item.data()
                    data.id = item.id
                    data.ref = item.ref
                    if (data.userid == talent.talentId) {
                      db.collection('users').doc(data.id).collection('profile')
                        .limit(1)
                        .get()
                        .then(profile => {
                          if (profile.size) {
                            let profileData = profile.docs[0].data()
                            if (state.status.remoteWork == 'all') {
                              data.profile = profileData
                              commit('setTalentFavorites', data)
                            } else if (state.status.remoteWork == 'yes') {
                              if (profileData.remote) {
                                data.profile = profileData
                                commit('setTalentFavorites', data)
                              }
                            } else if (state.status.remoteWork == 'no') {
                              if (!profileData.remote) {
                                data.profile = profileData
                                commit('setTalentFavorites', data)
                              }
                            }
                          }
                        })
                        .catch(error => {
                          commit('setError', error.message)
                        })
                    }
                  })
                } else {
                  commit('setTalentFavorites', [])
                }
                commit('setGetting', false)
              })
              .catch(error => {
                console.log(error.message)
                dispatch('showError', error.message, { root: true })
              })
          })
        }
        commit('setGetting', false)
      })
      .catch(error => {
        commit('setError', error.message)
      })
  },

  /*------------------------------------------------------------------------------
   * GET FAGORITE JOBS
   *----------------------------------------------------------------------------*/
  getFavorites({ commit, dispatch }) {
    commit('setFirstLoad', true)
    commit('setGetting', true)
    commit('initFavorites')
    let currentUser = firebase.auth().currentUser

    let daysAgo = moment().subtract(parseInt(state.status.displayRange), 'days')
    daysAgo = moment(daysAgo).valueOf()

    let favorites = db.collection('favorites')

    if (state.status.displayRange) {
      favorites = favorites.where('createdAt', '>=', daysAgo)
    }

    favorites.where('userid', '==', currentUser.uid)
    .orderBy('createdAt', 'desc')
    .get()
    .then(snapshot => {
      if (snapshot.size) {
        let promises = []
        let docs = snapshot.docs
        
        for (var key in docs) {
          let doc = docs[key]
          promises.push(db.collection('jobs').doc(doc.data().jobId).get())
        }
        
        Promise.all(promises)
        .then(docs => {
          commit('setJobFavorites', docs)

          docs.forEach(doc => {
            if (doc.exists) {
              dispatch('schools/getSchoolByUid', doc.data().useruid, { root: true })
            }
          })
        })

      } 
      else {
        commit('setGetting', false)
      }
    })
    .catch(error => {
      commit('setError', error.message)
    })
  },

  removeFromFavorites({ commit }, id) {
    commit('removeTalentFavorites', id)
  },

  addToFavorites({ commit }, favorite) {
    commit('addTalentToFavorites', favorite)
  },

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
