<template>
  <v-app id="builder">
    <slot name="adminDrawer"></slot>

    <!-- DASHBOARD NAVIGATION DRAWER -->
    <v-navigation-drawer
      v-if="dashboardDrawer()"
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      :width="$drawer.medium"
      color="secondary"
    >
      <admin-menu />

      <template v-slot:append>
        <v-btn
          @click="signout()"
          color="secondary darken-1"
          class="secondary--text text--lighten-2"
          block
          medium
          tile
        >
          <span class="caption">Logout</span>
          <v-spacer></v-spacer>
          <v-icon right>mdi-account-arrow-right-outline</v-icon>
        </v-btn>
      </template>
    </v-navigation-drawer> 
    
    <!-- MOBILE NAVIGATION DRAWER -->
    <v-navigation-drawer
      v-model="mobileDrawer"
      :clipped="$vuetify.breakpoint.xs"
      temporary
      fixed
    >
      <v-card class="mt-13" flat>
        <AdMenu v-if="user && user.role == 'admin'" />
        <EmployerMenu v-if="user && user.role == 'employer'" />
        <TeacherMenu v-if="user && user.role == 'jobseeker'" />
      </v-card>
    </v-navigation-drawer> 

    <!-- APP BAR -->
    <v-app-bar
      v-show="!$store.state.preview"
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      :clipped-right="$vuetify.breakpoint.lgAndUp"
      class="main-app-bar shadow-sm"
      style="z-index: 20"
      color="primary"
      dark
      flat
      app
    >
      <v-container class="d-flex py-0 fill-height">
        <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="d-none"></v-app-bar-nav-icon> -->
        <router-link :to="{ name: 'Home' }" class="hidden-sm-and-dow text-decoration-none" :class="{ 'ml-3' : $vuetify.breakpoint.xs }">
          <v-img
            src="/images/teacher_finder.svg"
            :width="$vuetify.breakpoint.xs ? '180' : '240'"
            contain
          ></v-img>
        </router-link>

        <v-spacer></v-spacer>

        <div class="d-flex gap-10 align-center fill-height">
          <Alert v-if="user" />

          <v-btn 
            v-if="user"
            href="https://www.teacherfinder.com.au/how-to/"
            target="_blank"
            icon small
          >
            <v-icon small>mdi-help-circle-outline</v-icon>
          </v-btn>

          <template v-if="!$vuetify.breakpoint.xs">
            <v-btn
              v-if="user && user.role != 'admin'"
              :to="{ name: 'MyAccount' }"
              color="accent gradient"
            >
              <v-icon color="white" left>mdi-account</v-icon>
              <span>My Account</span>
            </v-btn>

            <v-btn
              v-if="user && user.role == 'admin'"
              :to="{ name: 'Admin' }"
              color="accent gradient"
              exact
            >
              <v-icon color="white" left>mdi-account</v-icon>
              <span>Admin</span>
            </v-btn>
          </template>

          <v-btn
            v-if="user"
            @click="mobileDrawer = !mobileDrawer" 
            class="d-inline-block d-sm-none" 
            icon
          >
            <v-icon>{{ mobileDrawer ? 'mdi-close' : 'mdi-menu' }}</v-icon>
          </v-btn>
          
          <v-btn
            v-if="!user && $route.name !== 'Login'"
            :to="{ name: 'Login' }"
            class="mr-2"
            color="accent gradient"
          >
            <v-icon color="white" left small>mdi-login</v-icon>
            <span class="ls-0 white--text text-none font-weight-medium">Login</span>
          </v-btn>
        </div>
      </v-container>
    </v-app-bar>
    
    <v-app-bar v-if="user && !$vuetify.breakpoint.xs" :style="{ marginTop: '64px', zIndex: '20' }" height="55" flat app>
      <v-container>
        <div class="d-flex align-center">
          <router-link 
            v-for="(nav, i) in filterNavs"
            :key="i"
            :to="{ name: nav.route }" 
            class="text-decoration-none nav-menu heading-font mr-7 text-uppercase font-weight-bold"
            :style="{ fontSize: '14px' }"
          >
            {{ nav.name }}
          </router-link>
          <v-spacer></v-spacer>
          <a
            @click="signout()"
            v-if="user"
            class="text-decoration-none"
          >
             <v-icon color="primary" left small>mdi-logout</v-icon>
            <span class="ls-0 text-none font-weight-medium">Log out</span>
          </a>
        </div>
      </v-container>
    </v-app-bar>
    <!-- END APP BAR -->

    <!-- CONTENT -->
    <v-main :class="{ 'withBackground' : ['Login', 'Signup', 'ResetPassword', 'ConfirmResetPassword'].includes($route.name) && !$vuetify.breakpoint.xs }">
      <v-container
        class="align-start fill-height"
        :class="$vuetify.breakpoint.xs ? 'pb-0 px-3' : 'pb-12'"
        :style="{ marginTop: $vuetify.breakpoint.xs ? '0' : '70px' }"
        fluid
      >
        <v-alert 
          v-if="
            user && user.role == 'employer' 
            && ($store.getters['pricing/freeTrial'] > 0) 
            && showTrialMessage 
            && !['Subscription', 'TalentProfile'].includes($route.name)
          "
          class="mx-auto body-lg" color="accent" transition="fade" dense dark dismissible
        >
          <span>You have <strong>{{ $store.getters['pricing/freeTrial'] }} {{ pluralize('day', $store.getters['pricing/freeTrial']) }}</strong> left on your free trial. Subscribe <router-link :to="{ name: 'Subscription' }" class="white--text">here</router-link> to continue your service.</span>
        </v-alert>
        
        <template v-if="$store.state.settings.data.enableFreeTrial">
          <v-alert 
            v-if="
              user && user.role == 'employer' 
              && (!$store.getters['pricing/freeTrial'] && !$store.getters['pricing/withActiveSub']) 
              && !['Subscription', 'TalentProfile'].includes($route.name)
            "
            class="mx-auto body-lg" color="accent" transition="fade" dense dark dismissible
          >
            <span>Your subscription has ended. Subscribe <router-link :to="{ name: 'Subscription' }" class="white--text">here</router-link> to continue your service.</span>
          </v-alert>
        </template>
        
        <v-alert 
          v-if="
            user && user.role == 'jobseeker'
            && !$store.getters['user/completeProfile']
            && $route.name !== 'MyProfile'
          "
          class="mx-auto body-lg" color="accent" transition="fade" dense dark
        >
          <span>To unlock job opportunities please complete your <router-link class="white--text" :to="{ name: 'MyProfile' }">profile</router-link>.</span>
        </v-alert>

        <v-row v-if="!fullWidth">
          <v-col v-if="!$vuetify.breakpoint.xs" cols="3">
            <AdMenu v-if="user && user.role == 'admin'" />
            <EmployerMenu v-if="user && user.role == 'employer'" />
            <TeacherMenu v-if="user && user.role == 'jobseeker'" />
          </v-col>
          <v-col cols="12" md="9">
            <router-view></router-view>
          </v-col>
        </v-row>
        <router-view v-if="fullWidth"></router-view>
      </v-container>
    </v-main>

    <!-- SUCCESS -->
    <success />

    <!-- ERROR -->
    <error />

    <!-- UPDATING DIALOG -->
    <updating />

    <Footer />

    <!-- CONFIRM DELETE -->
    <confirm-delete
      :show="$store.state.showDelete"
      :message="$store.state.deleteMessage"
      :deleting="$store.state.deleting"
      @cancel="$store.commit('confirmDeleteState', false)"
      @confirmed="deleteConfirmed()"
    />
  </v-app>
</template>

<script>
  import firebase from 'firebase'
  import { mapState, mapActions } from 'vuex'
  import success from './components/common/SuccessSnackbar'
  import error from './components/common/ErrorSnackbar'
  import Updating from './components/common/UpdatingDialog'
  import AdminMenu from './components/dashboard/AdminMenu'
  import Alert from './views/common/Alert'
  import AdMenu from './components/common/AdminMenu'
  import EmployerMenu from './components/common/EmployerMenu'
  import TeacherMenu from './components/common/TeacherMenu'
  import signout from '@/mixins/signout'
  import Footer from '@/views/common/Footer'
  import pluralize from 'pluralize'

  export default {

    metaInfo: {
      title: process.env.VUE_APP_COMPANY_NAME,
      titleTemplate: `%s | ${process.env.VUE_APP_COMPANY_NAME}`
    },

    mixins: [signout],

    components: {
      AdminMenu,
      AdMenu,
      success,
      error,
      Updating,
      Alert,
      EmployerMenu,
      TeacherMenu,
      Footer
    },

    props: {
      source: String,
    },

    /*------------------------------------------------------------------------------
     * DATA
     *----------------------------------------------------------------------------*/
    data: () => ({
      pluralize,
      drawer: null,
      projectDrawer: null,
      showLogin: false,
      uploading: true,
      mobileDrawer: false,
      showTrialMessage: true,
      appName: process.env.VUE_APP_COMPANY_NAME,
      navs: [
        {
          name: 'Find A Teacher',
          route: 'Talent',
          roles: ['employer']
        },
        {
          name: 'Create A Job',
          route: 'Job',
          roles: ['admin', 'employer']
        },
        {
          name: 'My Current Jobs',
          route: 'MyJobs',
          roles: ['employer']
        },
        {
          name: 'Find Teaching Jobs',
          route: 'Jobs',
          roles: ['jobseeker', 'complete']
        },
        {
          name: 'Update My Details',
          route: 'AccountSettings',
          roles: ['jobseeker']
        },
        {
          name: 'My Current Jobs',
          route: 'Applied',
          roles: ['jobseeker', 'complete']
        },
        {
          name: 'My Availability',
          route: 'Availability',
          roles: ['jobseeker']
        },
      ]
    }),

    /*------------------------------------------------------------------------------
     * COMPUTED
     *----------------------------------------------------------------------------*/
    computed: {
      ...mapState({
        user: state => state.user.user,
        view: state => state.view
      }),

      filterNavs: function () {
        return this.navs.filter(nav => {
          if(nav.roles == 'all') {
            return true
          }
          else {
            let isTrue = this.user && nav.roles.includes(this.user.role)

            if (this.user && this.user.role == 'jobseeker') {
              if (nav.roles.includes('complete')) {
                return this.$store.getters['user/completeProfile']
              }
              else {
                return isTrue
              }
            }
            else return isTrue
          }
        })
      },

      fullWidth: function () {
        return this.$route.meta.fullWidth
      }
    },

    /*------------------------------------------------------------------------------
     * METHODS
     *----------------------------------------------------------------------------*/
    methods: {
      ...mapActions('user', [
        'setUser'
      ]),

      dashboardDrawer() {
        let showDrawer = [
          // 'Admin',
        ]

        return showDrawer.includes(this.$route.name)
      },

      deleteConfirmed() {
        this.$root.$emit('deleteConfirmed')
      }
    },

    created() {
      firebase.auth().onAuthStateChanged(() => {
        if (firebase.auth().currentUser) {
          this.setUser()
          this.$store.dispatch('pricing/getSubscriptions')
          this.$store.dispatch('settings/getSettings')
        }
      })
    }
  }
</script>

<style lang="scss">

.nav-menu {
  transition: all 0.3s;

  &:hover, &.router-link-active {
    color: var(--v-accent-base) !important;
  }
}

.v-toolbar__content {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.main-app-bar {
  border-bottom: 1px solid #fff !important;
}

.v-footer {
  background: linear-gradient(178.21deg, #154047 0%, #123942 99.91%);
}

.footer-social {
  opacity: 0.6 !important;

  &:hover {
    opacity: 1 !important;
  }
}

</style>