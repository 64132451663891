import db from '@/firebase/init'
import _omit from 'lodash/omit'
import firebase from 'firebase'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  alerts: [],
  status: {
    sending: false,
    saving: false,
    getting: false,
    deleting: false,
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  sendingState(state, bol) {
    state.status.sending = bol
  },

  savingState(state, bol) {
    state.status.saving = bol
  },

  gettingState(state, bol) {
    state.status.getting = bol
  },

  setAlerts(state, payload) {
    state.alerts = []

    if (payload.size) {
      payload.forEach(doc => {
        let alert = doc.data()
        alert.id = doc.id
        alert.ref = doc.ref
        state.alerts.push(alert)
      })
    }
  },

  deletingState(state, bol) {
    state.status.deleting = bol
  },

  removeAlert(state, payload) {
    state.alerts.splice(state.alerts.indexOf(payload), 1)
  },

  insertAlerts(state, payload) {
    state.alerts = []

    if (payload.size) {
      payload.forEach(doc => {
        if (!state.alerts.find(alert => alert.id == doc.id)) {
          let data = doc.data()
          data.id = doc.id
          data.ref = doc.ref
          state.alerts.push(data)
        }
      })
    }
  }

}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * SEND NOTIFICATION
   *----------------------------------------------------------------------------*/
  async send({ commit, dispatch }, data) {
    commit('sendingState', true)

    let message = {
      to: data.recipient,
      template: {
        name: "notification",
        data: {
          subject: data.subject,
          message: data.message,
        },
      },
    }

    await db.collection('mail')
    .add(message)
    .then(() => {
      commit('sendingState', false)
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * CREATE NOTIFICATION
   * 
   * @PARAMS
   * $source - document id
   * $type - e.g. message
   * $created - Date.now()
   * $recipient - user uid
   *----------------------------------------------------------------------------*/
  createAlert({ commit }, data) {
    commit('savingState', true)

    db.collection('notifications').doc(data.recipient)
    .collection('alerts').add(_omit(data, ['recipient']))
    .catch(error => {
      console.log(error.message)
      commit('savingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * REMOVE ALERT
   *----------------------------------------------------------------------------*/
  deleteAlert({ state, commit }, id) {
    commit('deletingState', true)
    let alert = state.alerts.find(alert => alert.source == id)
    
    if (alert) {
      alert.ref.delete()
      .then(() => {
        commit('deletingState', true)
        commit('removeAlert', alert)
      })
      .catch(error => {
        console.log(error.message)
      })
    }
  },

  /*------------------------------------------------------------------------------
   * GET ALERTS
   *----------------------------------------------------------------------------*/
  getAlerts({ commit }) {
    commit('gettingState', true)
    let user = firebase.auth().currentUser

    db.collection('notifications')
    .doc(user.uid)
    .collection('alerts')
    .get()
    .then(snapshot => {
      commit('setAlerts', snapshot)
    })
    .catch(error => {
      console.log(error.message)
      commit('gettingState', false)
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}