import Vue from 'vue'
import _camelCase from 'lodash/camelCase'
import _kebabCase from 'lodash/kebabCase'
import db from '@/firebase/init'
import firebase from 'firebase'
import moment from 'moment'
import jsonexport from 'jsonexport'

var storageRef = firebase.storage().ref()

Vue.prototype.$classes = {
  drawerTitle: 'mb-5 subtitle-1 font-weight-bold primary--text',
}

Vue.prototype.$drawer = {
  medium: '300',
  large: '450',
}

Vue.prototype.$dialog = {
  large: '1200',
  medium: '770',
  small: '450',
  content: '570'
}

Vue.prototype.$formatData = (payload) => {
  let data = payload.data()
  data.id = payload.id
  data.ref = payload.ref
  return data
}

Vue.prototype.$colors = {
  panelHeader: 'rgba(147,222,251,0.1)',
  panelContent: '#222c80',
}

Vue.prototype.$shortcodes = (block, section) => {
  let shortcodeIndex = _camelCase(`${section.orientation}-${section.color}-shortcode`)

  let data = {
    type: section.type,
    data: block[shortcodeIndex],
    order: section.order,
    id: section.id,
  }

  return data
}

Vue.prototype.$cssCodes = (block, section) => {
  let cssCodeIndex = _camelCase(`${section.orientation}-${section.color}-css`)

  let data = {
    type: section.type,
    data: block[cssCodeIndex],
    order: section.order,
    color: section.color,
    id: section.id,
    name: block.name,
    identifier: _kebabCase(`${block.name} ${section.color}`)
  }
  
  return data
}

Vue.prototype.$getBlock = async (id) => {
  let block = null
  
  await db.collection('blocks')
  .doc(id).get()
  .then(doc => {
    if (doc.exists) {
      block = doc.data()
      block.id = doc.id
      block.ref = doc.ref
    }
  })
  .catch(error => {
    console.log(error.message)
  })

  return block
}

Vue.prototype.$getUrl = async (link) => {
  var storage  = firebase.storage()
  let returnUrl = ''

  await storage.ref(link).getDownloadURL()
  .then(url => {
    returnUrl = url
  })
  .catch(error => {
    console.log(error.message)
  })

  return returnUrl
}

Vue.prototype.$getFileExtension = (file) => {
  let data = file.split('.')
  return data[data.length - 1]
}

Vue.prototype.$downloadFile = (folder, file) => {
  storageRef.child(`${folder}/${file}`).getDownloadURL()
  .then(function(url) {
    var xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'

    xhr.onload = function() {
      var blob = xhr.response
      var a = document.createElement('a')
      a.href = window.URL.createObjectURL(blob)
      a.download = file
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }

    xhr.open('GET', url)
    xhr.send()

  }).catch(function(error) {
    console.log(error.message)
  })
  
}

Vue.prototype.$getBlob = async (folder, file) => {
  var blob = null
  
  await storageRef.child(`${folder}/${file}`).getDownloadURL()
  .then((url) => {
    var xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'

    xhr.onload = function() {
      blob = xhr.response
    }

    xhr.open('GET', url)
    xhr.send()
  }).catch(function(error) {
    console.log(error.message)
  })

  return blob
}

Vue.prototype.$downloadFile = (folder, file) => {
  storageRef.child(`${folder}/${file}`).getDownloadURL()
  .then(function(url) {
    var xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'

    xhr.onload = function() {
      var blob = xhr.response
      var a = document.createElement('a')
      a.href = window.URL.createObjectURL(blob)
      a.download = file
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }

    xhr.open('GET', url)
    xhr.send()

  }).catch(function(error) {
    console.log(error.message)
  })
  
}

Vue.prototype.$scrollTop = () => {
  window.scroll({
    top: 0, 
    left: 0, 
    behavior: 'smooth'
  })
}

Vue.prototype.$text = {
  label: 'heading-font font-weight-bold primary--text mb-1'
}

Vue.prototype.$mapLink = (lat, long) => {
  return `https://www.google.com/maps/search/?api=1&query=${lat},${long}`
}

Vue.prototype.$startDate = (contractDates) => {
  let startDate = null

  if (contractDates && contractDates.length) {
    contractDates.forEach((dates, i) => {
      if (i == 0) startDate = moment(dates[0]).valueOf()
      else startDate = moment(dates[0]).valueOf() < startDate ? moment(dates[0]).valueOf() : startDate
    })
  }

  return startDate
}

Vue.prototype.$endDate = (contractDates) => {
  let startDate = null

  if (contractDates && contractDates.length) {
    contractDates.forEach((dates, i) => {
      if (i == 0) startDate = moment(dates[1]).valueOf()
      else startDate = moment(dates[1]).valueOf() > startDate ? moment(dates[1]).valueOf() : startDate
    })
  }

  return startDate
}

Vue.prototype.$downloadTemplate = () => {
  let data = []

  data.push({
    'First Name': '',
    'Last Name': '',
    'Phone': '',
    'Email Address': '',
  })

  jsonexport(data, (err, csv) => {
    if (err) return console.error(err)
    else {
      let filename = 'import.csv'

      var link = document.createElement("a")
      let csvContent = "data:text/csv;charset=utf-8," + csv
      var encodedUri = encodeURI(csvContent)
      link.setAttribute("href", encodedUri)
      link.setAttribute("download", filename)
      document.body.appendChild(link)
      link.click()
    }
  })
}

Vue.prototype.$freeTrialLeft = (day) => {
  return moment(day).diff(moment(), 'days')
}