<template>
  <v-card
    max-width="280"
    class="mx-auto my-12"
  >
    <v-card-text class="px-7 py-2">
      <v-list class="admin-menu">
        <v-list-item-group>
          <template v-for="(item, i) in guardedItems">
            <v-list-item
              :key="i"
              :to="{ name: item.route }"
              class="pl-0"
              exact
            >
              <v-list-item-icon><v-icon>mdi-{{ item.icon }}</v-icon></v-list-item-icon>
              <v-list-item-title class="font-weight-bold admin-item-title">{{ item.name }}</v-list-item-title>
            </v-list-item>

          </template>
        </v-list-item-group>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'AdminMenu',

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      items: [
        {
          name: 'Users',
          route: 'Admin',
          icon: 'account-group-outline',
          roles: ['admin']
        },
        {
          name: 'Message',
          route: 'Message',
          icon: 'message-text-outline',
          roles: ['all']
        },
        {
          name: 'Jobs',
          route: 'Jobs',
          icon: 'account-hard-hat',
          roles: ['all']
        },
        {
          name: 'Profile',
          route: 'Profile',
          icon: 'account-hard-hat',
          roles: ['all']
        },
        {
          name: 'Account Settings',
          route: 'AccountSettings',
          icon: 'account-cog-outline',
          roles: ['all']
        },
      ]
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    guardedItems: function () {
      return this.items.filter(item => {
        return item.separator || item.roles.includes('all') || item.roles.includes(this.$store.state.user.user ? this.$store.state.user.user.role : '')
      })
    }
  },
}

</script>
