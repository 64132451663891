import db from '@/firebase/init'
import firebase from 'firebase'
import _omit from 'lodash/omit'
import Vue from 'vue'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  school: {},
  userSchool: null,
  selectedSchool: null,
  selectedSchoolLogo: null,
  queries: [],
  schools: [],
  status: {
    error: null,
    showDialog: false,
    updating: false,
    firstLoad: false,
    getting: false,
    confirmError: null,
    displayRange: 60,
    orderBy: 'desc',
    adding: false,
    deleting: false,
    deletingLogo: false,
    gettingSchool: false,
  }
}

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  isOwner: () => (school) => {
    let user = firebase.auth().currentUser
    return user.uid == school.useruid
  },

  userSchool: (state) => (id) => {
    let school = null
    
    if (state.schools) {
      school = state.schools.find(s => s.useruid == id)
    }

    return school || {}
  }
}

/*------------------------------------------------------------------------------
* MUTATIONS
*----------------------------------------------------------------------------*/
const mutations = {
  setError(state, message) {
    state.status.error = message
  },

  setAddingState(state, bol) {
    state.status.adding = bol
  },

  setShowDialog(state, bol) {
    state.status.showDialog = bol
  },

  setUpdatingState(state, bol) {
    state.status.updating = bol
  },

  setConfirmError(state, message) {
    state.status.confirmError = message
  },

  setFirstLoad(state, bol) {
    state.status.firstLoad = bol
  },

  setGetting(state, bol) {
    state.status.getting = bol
  },

  setData(state, payload) {
    state.school = payload
  },

  setSchool(state, payload) {
    let data

    try {
      let data = payload.data()
      data.id = payload.id
      data.ref = payload.ref
    }
    catch {
      data = payload
    }

    state.school = data
    state.status.getting = false
  },

  insertSelectedSchool(state, payload) {
    let data = payload.data()
    data.id = payload.id
    data.ref = payload.ref
    state.selectedSchool = data
  },
  
  setQueries(state, payload) {
    payload.forEach(query => {
      let data = query.data()
      data.id = query.id
      data.ref = query.ref
      state.queries.push(data)
    })
  },

  initQueries(state) {
    state.queries = []
  },

  insertUserSchools(state, payload) {
    state.userSchools.push(payload)
  },

  initUserSchool(state) {
    state.userSchools = []
  },

  reInitSchool(state) {
    state.school = {}
  },

  setLogoUrl(state, url) {
    state.selectedSchoolLogo = url
  },

  deletingState(state, bol) {
    state.status.deleting = bol
  },

  removeSchool(state, payload) {
    state.userSchools.splice(state.userSchools.indexOf(payload), 1)
  },

  setAddress(state, address) {
    Vue.set(state.school, 'address', address)
  },

  deletingLogoState(state, bol) {
    state.status.deletingLogo = bol
  },

  removeLogo(state) {
    state.school.logo = null
  },

  clearSchool(state) {
    state.school = {}
  },

  pushSchool(state, payload) {
    let data = payload.data()
    data.id = payload.id
    data.doc = payload.doc

    if (!state.schools.find(school => school.useruid == data.useruid)) {
      state.schools.push(data)
    }
  },

  gettingSchoolState(state, bol) {
    state.status.gettingSchool = bol
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {

  /*------------------------------------------------------------------------------
   * ADD JOB
   *----------------------------------------------------------------------------*/
  async storeSchool({ state, commit, dispatch }) {
    commit('setAddingState', true)
    
    var storageRef = firebase.storage().ref()
    let user = firebase.auth().currentUser
    let data = Object.assign({}, state.school)
    let name = null
    
    if (typeof state.school.logo == 'object') {
      var metadata = {
        contentType: state.school.logo.type
      }
      
      name = `${Date.now()}_${state.school.logo.name}`
      await storageRef.child(`schoollogos/${name}`).put(state.school.logo, metadata)
    }
    
    let docRef = data.id ? db.collection('schools').doc(data.id) : db.collection('schools').doc()
    
    data.logo = name || data.logo
    data.useruid = user.uid
    data.createdAt =  data.createdAt || Date.now()
    data.updatedAt = Date.now()

    await docRef.set(_omit(data, ['ref', 'id']), { merge: true })
    .then((docRef) => {
      dispatch('showSuccess', 'School successfully added.', { root: true })
      commit('setAddingState', false)

      data.ref = docRef
      data.id = docRef.id
      commit('insertUserSchools', data)
    })
    .catch(error => {
      commit('setError', error.message)
      commit('setAddingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * GET SCHOOLS
   *----------------------------------------------------------------------------*/
  getUserSchool({ commit }) {
    var storage = firebase.storage()
    let user = firebase.auth().currentUser
    commit('setGetting', true)

    if (user) {
      db.collection('schools')
      .where('useruid', '==', user.uid)
      .limit(1).get()
      .then(snapshot => {
        if (snapshot.size) {
          commit('setFirstLoad', true)
          commit('initUserSchool')
  
          let school = snapshot.docs[0]
          let data = school.data()
          data.id = school.id
          data.ref = school.ref
  
          if (data.logo) {
            storage.ref(`schoollogos/${data.logo}`).getDownloadURL()
            .then(url => {
              data.logoURL = url
              commit('setSchool', data)
            })
            .catch(error => {
              console.log(error.message)
              data.logoURL = require('@/assets/placeholder.svg')
              commit('setSchool', data)
            })
          }
          else {
            data.logoURL = require('@/assets/placeholder.svg')
            commit('setSchool', data)
          }
        }
        else {
          commit('setGetting', false)
        }
        
      })
      .catch(error => {
        commit('setGetting', false)
        console.log(error.message)
        commit('setError', error.message)
      })
    }


  },

  /*------------------------------------------------------------------------------
   * GET SCHOOL BY ID
   *----------------------------------------------------------------------------*/
  getSelectedSchoolById({ commit, dispatch }, id) {
    commit('setGetting', true)
    
    db.collection('schools').doc(id)
    .get()
    .then(doc => {
      if (doc.exists) {
        commit('setGetting', false)
        commit('insertSelectedSchool', doc)
        let data = doc.data()
        //get school query
        commit('initQueries')
        db.collection('schools').doc(id).collection('queries')
        .get()
        .then(snapshot => {
          if(snapshot.size){
            commit('setQueries', snapshot)
          }
        })
        .catch(error => {
          console.log(error.message)
        })

        if (data.logo) {
          var storage = firebase.storage()
          storage.ref(`schoollogos/${data.logo}`).getDownloadURL()
          .then(url => {
            commit('setLogoUrl', url)
          })
          .catch(error => {
            console.log(error.message)
            commit('setLogoUrl', require('@/assets/placeholder.svg'))
          })
        }
        else {
          commit('setLogoUrl', require('@/assets/placeholder.svg'))
        }
      }
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE SCHOOL
   *----------------------------------------------------------------------------*/
  async deleteSchool({ commit, dispatch }, school) {
    commit('deletingState', true)
    
    await school.ref.delete()
    .then(() => {
      commit('deletingState', false)
      commit('removeSchool', school)
      dispatch('showSuccess', 'School successfully deleted', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE SCHOOL LOGO
   *----------------------------------------------------------------------------*/
  async deleteSchoolLogo({ state, commit, dispatch }) {
    commit('deletingLogoState', true)
    var storage = firebase.storage()
    
    await storage.ref(`schoollogos/${state.school.logo}`)
    .delete()
    .then(() => {
      return state.school.ref.update({ logo: null })
      .then(() => {
        commit('deletingLogoState', false)
        commit('removeLogo')
        dispatch('showSuccess', 'School logo deleted', { root: true })
      })
    })
    .catch(error => {
      console.log(error.message)
      commit('deletingLogoState', false)
      dispatch('showError', 'School logo deleted', { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * GET SCHOOL BY USERID
   *----------------------------------------------------------------------------*/
  getSchoolByUid({ state, commit }, id) {
    if (!state.schools.find(school => school.useruid == id)) {
      commit('gettingSchoolState', true)
      
      db.collection('schools')
      .where('useruid', '==', id)
      .limit(1).get()
      .then(snapshot => {
        if (snapshot.size) {
          let doc = snapshot.docs[0]
          commit('pushSchool', doc)
          commit('gettingSchoolState', false)
        }
      })
      .catch(error => {
        console.log(error.message)
        commit('gettingSchoolState', false)
      })
    }
  }

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
