<template>
  <v-dialog
    v-model="show"
    :max-width="$dialog.small"
    :persistent="sending"
  >
    <v-card>
      <v-card-title class="mb-3">
        <span class="font-weight-bold primary--text">Refer A Friend</span>

        <v-btn @click="closeDialog()" :disabled="sending" icon absolute right>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div v-if="emails.length" class="mb-5 d-flex gap-5 flex-wrap">
          <v-chip
            v-for="(email, i) in emails"
            :key="i"
          >
            {{ email }}

            <v-btn @click="removeEmail(i)" class="ml-1" icon small>
              <v-icon small>mdi-trash-can</v-icon>
            </v-btn>
          </v-chip>
        </div>

        <v-form ref="emailForm" @submit.prevent="addEmail()">
          <v-text-field
            v-model="email"
            :rules="[rules.required, rules.email]"
            label="Email Address"
            outlined
          >
            <template slot="append">
              <v-btn 
                @click="addEmail()"
                color="accent gradient" 
                class="mt-n2" 
                small fab depressed
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-form>

        <v-btn
          @click="sendInvite()"
          v-if="emails.length"
          :loading="sending"
          color="accent gradient"
        >
          Send
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/rules'
import { mapState, mapActions } from 'vuex'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      email: null,
      emails: [],
      show: false,
      sending: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: (state) => state.user.user
    })
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('notifications', ['send']),

    addEmail() {
      if (this.$refs.emailForm.validate()) {
        this.emails.push(this.email)  
        this.email = null
        this.$refs.emailForm.resetValidation()
      }
    },

    removeEmail(i) {
      this.emails.splice(i, 1)
      this.$refs.emailForm.resetValidation()
    },

    closeDialog() {
      this.show = false
      this.sending = false
      this.emails = []
      this.email = null
    },

    sendInvite() {
      this.sending = true
      let promises = []

      if (this.emails.length) {
        for (var key in this.emails) {
          let data = {
            recipient: this.emails[key],
            subject: `${this.user.firstName} wants to tell you about TeacherFinder`,
            message: `
              Hi there,<br>
              <br>
              Your friend ${this.user.firstName} has joined TeacherFinder and thought you might be interested too. It’s the best new (free) way to secure teaching contracts. To get found by hiring schools or search available jobs, sign up now at <a href="${window.location.origin}/signup" target="_blank">${window.location.origin}/signup</a>. <br>
              <br>
              We’d love to help you secure your next contract!!<br>
              <br>
              Regards,<br>
              The Team at TeacherFinder
            `
          }

          promises.push(this.send(data))
        }

        Promise.all(promises)
        .then(() => {
          this.$store.dispatch('showSuccess', 'Invitation sent', { root: true })
          this.closeDialog()
        })
      }
    }
  }
}
</script>