import db from '@/firebase/init'
import firebase from 'firebase'
// import moment from 'moment'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  invite: null,
  invites: [],
  openJobs: [],
  invitedJobs: [],
  myInvites: [],
  inviteds: [],
  status: {
    error: null,
    showDialog: false,
    updating: false,
    firstLoad: false,
    getting: false,
    adding: false,
    displayRange: 60,
    inviting: false,
    deleting: false,
  }
}

/*------------------------------------------------------------------------------
* MUTATIONS
*----------------------------------------------------------------------------*/
const mutations = {
  setError(state, message) {
    state.status.error = message
  },

  setFirstLoad(state, bol) {
    state.status.firstLoad = bol
  },

  setGetting(state, bol) {
    state.status.getting = bol
  },

  setAdding(state, bol) {
    state.status.adding = bol
  },

  setUpDialog(state, bol) {
    state.status.showDialog = bol
  },

  setOpenJobs(state, payload) {
    state.openJobs = []

    if (payload.size) {
      payload.forEach(item => {
        let data = item.data()
        data.id = item.id
        data.ref = item.ref
        if (data.status == 'Open') {
          state.openJobs.push(data)
        }
      })
    }
  },

  setInviteJobs(state, payload) {
    if (payload.size) {
      payload.forEach(item => {
        let data = item.data()
        data.id = item.id
        data.ref = item.ref
        state.invitedJobs.push(data)
      })
    }
  },

  setMyInvites(state, payload) {
    state.myInvites = []

    if (payload.size) {
      payload.forEach(doc => {
        let data = doc.data()
        data.id = doc.id
        data.ref = doc.ref
        state.myInvites.push(data)
      })
    }

    state.status.getting = false
  },

  initOpenJobs(state) {
    state.openJobs = []
  },

  initMyInvites(state) {
    state.myInvites = []
  },

  setInviteds(state, payload) {
    if (payload.size) {
      payload.forEach(doc => {
        if (!state.inviteds.find(invite => invite.id == doc.id))  {
          let data = doc.data()
          data.id = doc.id
          data.ref = doc.ref
          state.inviteds.push(data)
        }
      })
    }
  },

  invitingState(state, bol) {
    state.status.inviting = bol
  },

  deletingState(state, bol) {
    state.status.deleting = bol
  },

  removeInvite(state, payload) {
    state.myInvites.splice(state.myInvites.indexOf(payload), 1)
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * GET OPEN JOB
   *----------------------------------------------------------------------------*/
  getOpenJob({ commit }) {
    commit('setGetting', true)
    commit('initOpenJobs')
    let currentUser = firebase.auth().currentUser

    db.collection('jobs')
    .where('useruid', '==', currentUser.uid)
    .where('status', '==', 'Open')
    .get()
    .then(snapshot => {
      console.log(snapshot.size)
      commit('setOpenJobs', snapshot)
      commit('setGetting', false)
    })
    .catch(error => {
      commit('setError', error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * CHECK INVITED TALENT
   *----------------------------------------------------------------------------*/
  checkInvitedTalent({ commit }, payload) {
    commit('setGetting', true)

    db.collection('invites')
      .where('talentId', '==', payload.talentId)
      .get().then(snapshot => {
        if (snapshot.size) {
          commit('setInviteJobs', snapshot)
        }

        commit('setGetting', false)
      })
      .catch(error => {
        commit('setError', error.message)
      })
  },

  /*------------------------------------------------------------------------------
   * INVITE TALENT
   *----------------------------------------------------------------------------*/
  async inviteTalent({ commit, dispatch }, payload) {
    commit('invitingState', true)
    let jobs = payload.jobIds
    let teacher = payload.talentId

    if (jobs.length) {
      for (var key in jobs) {
        let job = jobs[key]

        await db.collection('invites')
        .where('talentId', '==', teacher)
        .where('jobId', '==', job)
        .get()
        .then(snapshot => {
          if (snapshot.size) {
            dispatch('showError', 'Already invited', { root: true })
          } 
          else {
            let invities = db.collection('invites').doc()
    
            return invities.set({
              talentId: teacher,
              jobId: job,
              createdAt: Date.now(),
              updatedAt: Date.now()
            })
            .then(() => {
              dispatch('showSuccess', 'Candidate invited.', { root: true })
              commit('setGetting', false)
    
              // NOTIFY TEACHER VIA EMAIL
              db.collection('users')
              .where('userid', '==', teacher)
              .limit(1).get()
              .then(snapshot => {
                if (snapshot.size) {
                  let doc = snapshot.docs[0]
                  
                  dispatch('notifications/send', {
                    recipient: doc.data().email,
                    subject: 'TeacherFinder Job Invitation',
                    message: `
                            Hi ${doc.data().firstName}, <br><br>
                            You have been invited to apply for a job on Teacher Finder. Click <a href="${window.location.origin}/jobs/my-job/${job}" target="_blank">here</a> to view the job details.<br>
                          `
                  }, { root: true })
                }
              })

              // CREATE ALERT
              dispatch('notifications/createAlert', {
                recipient: teacher,
                source: job,
                type: 'job_invitation',
                created: Date.now()
              }, { root: true })

            })
            .catch(error => {
              commit('setError', error.message)
            })
          }
        })
        .catch(error => {
          commit('setError', error.message)
        })
      }

      commit('invitingState', false)
    }
  },

  /*------------------------------------------------------------------------------
   * GET INVITES
   *----------------------------------------------------------------------------*/
  getInvites({ commit, dispatch }) {
    let user = firebase.auth().currentUser
    commit('setGetting', true)

      db.collection('invites')
      .where('talentId', '==', user.uid)
      .get()
      .then((snapshot) => {
        commit('setMyInvites', snapshot)

        if (snapshot.size) {
          snapshot.forEach(doc => {
            dispatch('jobs/getJobById', doc.data().jobId, { root: true })
          })
        }
      })
      .catch(error => {
        console.log(error.message)
        commit('setError', error.message)
      })
  },

  /*------------------------------------------------------------------------------
   * GET INVITEDS
   *----------------------------------------------------------------------------*/
  getInviteds({ commit, dispatch }, job) {
    db.collection('invites')
    .where('jobId', '==', job.id)
    .get()
    .then(snapshot => {
      commit('setInviteds', snapshot)

      if (snapshot.size) {
        snapshot.forEach(doc => {
          dispatch('users/getUserByUid', doc.data().talentId, { root: true })
        })
      }
    })
    .catch(error => {
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE JOB INVITATION
   *----------------------------------------------------------------------------*/
  async deleteInvitation({ commit, dispatch }, invite) {
    commit('deletingState', true)
    
    await invite.ref.delete()
    .then(() => {
      commit('deletingState', false)
      commit('removeInvite', invite)
      dispatch('showSuccess', 'Job invite successfully deleted', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('deletingState', false)
      dispatch('showError', error.message, { root: true })
    })
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
