import db from '@/firebase/init'
import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/auth'
import _filter from 'lodash/filter'
import _orderBy from 'lodash/orderBy'
// import _pick from 'lodash/pick'
import moment from 'moment'
import Vue from 'vue'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  applied: null,
  applies: [],
  allApplicants: [],
  appliedUserInfo: [],
  contractApplicant: [],
  appliedJobs: [],
  withdrawApplied: [],
  contract: {},
  applicants: [],
  hits: [],
  hiredApplicants: [],
  status: {
    error: null,
    showDialog: false,
    updating: false,
    firstLoad: false,
    getting: false,
    applicantsFirstload: false,
    confirmError: null,
    adding: false,
    displayRange: 60,
    uploadContractDialog: false,
    uploadingContract: false,
    uploadProgress: 0,
    uploadError: null,
    activePage: 1,
    applying: false,
    withdrawing: false,
    gettingApplied: false,
  }
}

/* -------------------------------------------------------------------------- */
/*                                   GETTERS                                  */
/* -------------------------------------------------------------------------- */

const getters = {
  getApplicantsCount: (state) => (id) => {
    if (state.allApplicants) {
      let applicants = _filter(state.allApplicants, { 'jobId': id })
      return applicants.length
    }
  },

  getApplicantObjects: (state) => (id) => {
    if (state.allApplicants) {
      let applicants = _filter(state.allApplicants, { 'jobId': id })
      return _orderBy(applicants, 'createdAt', 'asc')
    }
  },

  getApplicantStatus: (state) => (id) => {
    if (state.appliedJobs.length) {
      let job = state.appliedJobs.find(apply => apply.jobId == id)
      return job ? job.status : null
    }
  },

  getHired: (state) => (id) => {
    if (state.applicants) {
      return state.applicants.find(a => a.jobId == id && a.status == 'Hired') || null
    }
  },

  applicants: (state) => (job) => {
    if (state.applicants) {
      return state.applicants.filter(a => a.jobId == job.id)
    }
  },

  bookings: (state) => {
    return state.allApplicants.filter(application => {
      return application.status == 'Hired'
    })
  }
}
/*------------------------------------------------------------------------------
* MUTATIONS
*----------------------------------------------------------------------------*/
const mutations = {
  setError(state, message) {
    state.status.error = message
  },

  setFirstLoad(state, bol) {
    state.status.firstLoad = bol
  },

  setApplicantsFirstLoad(state, bol) {
    state.status.applicantsFirstload = bol
  },

  setGetting(state, bol) {
    state.status.getting = bol
  },

  setJobApply(state, payload) {
    state.applied = []
    if (payload.size) {
      payload.forEach(item => {
        let data = item.data()
        data.id = item.id
        data.ref = item.ref
        state.applied.push(data)
      })
    }
  },

  setWithdrawJob(state, payload) {
    state.withdrawApplied.push(payload)
  },

  setAllApplicants(state, payload) {
    if (payload.size) {
      payload.forEach(item => {
        let data = item.data()
        data.id = item.id
        data.ref = item.ref
        state.allApplicants.push(data)
      })
    }
  },

  setAppliedUserInfo(state, payload) {
    let applicant = state.appliedUserInfo.find(a => a.id == payload.id)
    if (applicant) Vue.set(state.appliedUserInfo, state.appliedUserInfo.indexOf(applicant), payload)
    else state.appliedUserInfo.push(payload)
  },

  initAppliedUserInfo() {
    state.appliedUserInfo = []
  },

  initApply(state) {
    state.applied = []
  },

  initAllApplicants(state) {
    state.allApplicants = []
  },

  setJobApplied(state, payload) {
    payload.forEach(doc => {
      if (doc.exists) {
        let data = doc.data()
        data.id = doc.id
        data.ref = doc.ref
        state.applies.push(data)
      }
    })

    state.status.getting = false
  },

  initApplies(state) {
    state.applies = []
  },

  setUploadingContract(state, bol) {
    state.status.uploadingContract = bol
  },

  setUploadProgress(state, progress) {
    state.status.uploadProgress = progress
  },

  resetUploadState(state) {
    state.status.uploadProgress = 0
    state.contract.contractFile = null
  },

  setContractApplicant(state, obj) {
    state.contractApplicant = obj
  },

  setUploadContractDialog(state, bol) {
    state.status.uploadContractDialog = bol
  },

  addApplied(state, payload) {
    state.appliedJobs = []

    payload.forEach(apply => {
      if (!state.appliedJobs.find(a => a.id == apply.id)) {
        let data = apply.data()
        data.id = apply.id
        data.ref = apply.ref
        state.appliedJobs.push(data)
      }
    })

    state.status.gettingApplied = false
  },

  addToShortlist(state, payload) {
    Vue.set(payload, 'shortlisted', true)
  },

  removeFromShortlist(state, payload) {
    // payload.shortlisted = false
    Vue.set(payload, 'shortlisted', false)
  },

  setApplicants(state, payload) {
    state.applicants = []

    if (payload.size) {
      payload.forEach(doc => {
        let data = doc.data()
        data.id = doc.id
        data.ref = doc.ref
        state.applicants.push(data)
      })
    }
  },

  setHits(state, payload) {
    state.hits = payload
  },

  setActivePage(state, page) {
    state.status.activePage = page
  },

  applyingState(state, bol) {
    state.status.applying = bol
  },

  withdrawingState(state, bol) {
    state.status.withdrawing = bol
  },

  removeApplication(state, payload) {
    let job = state.applies.find(a => a.id == payload)
    state.applies.splice(state.applies.indexOf(job), 1)
  },

  gettingAppliedState(state, bol) {
    state.status.gettingApplied = bol
  },

  setHiredApplicants(state, payload) {
    state.hiredApplicants = payload
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {

  /*------------------------------------------------------------------------------
   * APPLY FOR THE JOB
   *----------------------------------------------------------------------------*/
  async storeApplyJob({ commit, dispatch, rootState }, payload) {
    let currentUser = firebase.auth().currentUser
    commit('applyingState', true)

    if (payload.update) {
      await payload.applies.ref.delete()
        .then(() => {
          dispatch('showSuccess', 'You have removed an applied job.', { root: true })
          commit('applyingState', false)
        })
        .catch(error => {
          dispatch('showError', error.message, { root: true })
          commit('applyingState', false)
        })
    }
    else {
      let data = {
        userid: currentUser.uid,
        jobId: payload.id,
        contractFile: null,
        contractStatus: null,
        status: rootState.jobs.jobStatus.inprogress,
        answers: payload.answers ? payload.answers : null,
        createdAt: Date.now(),
        updatedAt: Date.now()
      }

      await db.collection('applies').add(data)
      .then(() => {
        dispatch('showSuccess', 'You have applied a job.', { root: true })
        commit('applyingState', false)
        dispatch('sendNotification', payload)
      })
      .catch(error => {
        commit('setError', error.message)
        commit('applyingState', false)
      })
    }
  },

  /*------------------------------------------------------------------------------
   * SEND NOTIFICATION
   *----------------------------------------------------------------------------*/
  sendNotification({ dispatch, rootState }, job) {
    db.collection('users')
    .where('userid', '==', job.useruid)
    .limit(1).get()
    .then(snapshot => {
      if (snapshot.size) {
        let doc = snapshot.docs[0]
        let user = doc.data()
        let currentUser = rootState.user.user
        
        let data = {
          recipient: user.email,
          subject: 'You’ve got a new job applicant!',
          message: `
            <strong>You’ve got a new job applicant!</strong><br>
            ${currentUser.firstName} ${currentUser.lastName} has applied for the role ${job.title}.<br>
            You can review their profile here ${window.location.origin}/profile/${currentUser.userid}.
          `
        }
    
        dispatch('notifications/send', data, { root: true })
      }
    })
    .catch(error => {
      console.log(error.message)
    })
  },

  getJobApplied({ commit }) {
    let currentUser = firebase.auth().currentUser

    db.collection('applies')
      .where('userid', '==', currentUser.uid)
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          commit('setJobApply', snapshot)
        }
        else {
          commit('initApply')
        }
      })
      .catch(error => {
        commit('setError', error.message)
      })
  },

  /*------------------------------------------------------------------------------
   * GET WITHDRAW JOB APPLICATIONS
   *----------------------------------------------------------------------------*/
  getWithdrawJob({ commit }) {
    commit('setFirstLoad', true)
    commit('setGetting', true)
    commit('initApplies')

    let currentUser = firebase.auth().currentUser

    let daysAgo = moment().subtract(parseInt(state.status.displayRange), 'days')
    daysAgo = moment(daysAgo).valueOf()

    let applies = db.collection('applies')

    if (state.status.displayRange) {
      applies = applies.where('createdAt', '>=', daysAgo)
    }

    applies = applies.where('userid', '==', currentUser.uid)
      .orderBy('createdAt', 'desc')

    applies
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          snapshot.forEach(item => {
            let data = item.data()
            data.id = item.id

            if (data.status == 'Application In Progress' || data.status == 'Inprogress') {
              db.collection('jobs')
                .doc(data.jobId)
                .get()
                .then(snapshot => {
                  if (snapshot.exists) {
                    let jobData = snapshot.data()
                    jobData.id = snapshot.id
                    jobData.ref = snapshot.ref
                    var storage = firebase.storage()

                    db.collection('schools')
                      .where('useruid', '==', jobData.useruid)
                      .limit(1).get()
                      .then(snapshot => {
                        if (snapshot.size) {
                          let school = snapshot.docs[0].data()
                          school.id = snapshot.docs[0].id
                          school.ref = snapshot.docs[0].ref
                          jobData.school = school
                          storage.ref(`schoollogos/${school.logo}`).getDownloadURL()
                            .then(url => {
                              jobData.logoURL = url
                              commit('setWithdrawJob', jobData)
                            })
                            .catch(error => {
                              console.log(error.message)
                              storage.ref(`schoollogos/${school.logo}`).getDownloadURL()
                                .then(url => {
                                  jobData.logoURL = url
                                  commit('setWithdrawJob', jobData)
                                })
                            })
                        }
                      })
                  }
                  commit('setGetting', false)
                })
                .catch(error => {
                  commit('setError', error.message)
                })
            }

          })
        } else {
          commit('setGetting', false)
        }
      })
      .catch(error => {
        commit('setError', error.message)
      })
  },

  /*------------------------------------------------------------------------------
   * GET APPLICATIONS
   *----------------------------------------------------------------------------*/
  getApplies({ commit, dispatch }) { 
    commit('setGetting', true)
    commit('initApplies')

    let currentUser = firebase.auth().currentUser

    let daysAgo = moment().subtract(parseInt(state.status.displayRange), 'days')
    daysAgo = moment(daysAgo).valueOf()

    let applies = db.collection('applies')

    if (state.status.displayRange) {
      applies = applies.where('createdAt', '>=', daysAgo)
    }

    applies.where('userid', '==', currentUser.uid)
    .orderBy('createdAt', 'desc')
    .get()
    .then(snapshot => {
      if (snapshot.size) {
        let promises = []
        commit('addApplied', snapshot)        

        for (let key in snapshot.docs) {
          let data = snapshot.docs[key].data()
          promises.push(db.collection('jobs').doc(data.jobId).get())
        }

        Promise.all(promises)
        .then(docs => {
          commit('setJobApplied', docs)

          if (docs.length) {
            docs.forEach(doc => {
              if (doc.exists) {
                dispatch('schools/getSchoolByUid', doc.data().useruid, { root: true })
                dispatch('users/getUserByUid', doc.data().useruid, { root: true })
              }
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
      } 
      else {
        commit('setGetting', false)
      }
    })
    .catch(error => {
      commit('setError', error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * GET USER INFO
   *----------------------------------------------------------------------------*/
  getAppliedUserInfo({ commit, dispatch }, payload) {
    commit('setGetting', true)
    commit('initAppliedUserInfo')
    
    db.collection('applies')
      .where('jobId', '==', payload)
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          snapshot.forEach(function (data) {
            let applied = data.data()
            applied.id = data.id
            applied.ref = data.ref

            db.collection('users')
              .where('userid', '==', applied.userid)
              .limit(1).get()
              .then(snapshot => {
                if (snapshot.size) {
                  let doc = snapshot.docs[0]
                  applied.user = doc.data()
                  applied.user.id = doc.id
                  applied.user.ref = doc.ref
                  commit('setAppliedUserInfo', applied)
                  dispatch('profile/getProfileById', { id: applied.user.id, userid: applied.userid }, { root: true })
                  dispatch('users/getUserByUid', applied.userid, { root: true })
                }
              })
              .catch(error => {
                commit('setError', error.message)
              })
          })
        }
        commit('setGetting', false)
      })
      .catch(error => {
        commit('setError', error.message)
      })
  },

  /*------------------------------------------------------------------------------
   * SEND EMAIL NOTIFICATION
   *----------------------------------------------------------------------------*/
  async updateApplicantStatus({ commit, dispatch, rootGetters, rootState }, payload) {
    if (payload.applicant) {
      commit('setGetting', true)
      
      await db.collection('jobs')
        .doc(payload.applicant.jobId)
        .get()
        .then(async snapshot => {
          if (snapshot.exists) {
            let jobData = snapshot.data()
            jobData.id = snapshot.id
            jobData.ref = snapshot.ref

            if (jobData.status != rootState.jobs.jobStatus.closed) {
              await payload.applicant.ref.update({
                status: payload.actions,
                contractStatus: 'Responded'
              })
                .then(() => {
                  dispatch('showSuccess', 'Application status updated', { root: true })

                  if (payload.actions == rootState.jobs.jobStatus.hired) {
                    jobData.ref.update({
                      status: rootState.jobs.jobStatus.closed
                    })
                    .then(() => {
                      commit('setGetting', false)
                    })

                    dispatch('notifications/createAlert', {
                      recipient: payload.applicant.userid,
                      type: 'job_awarded',
                      source: jobData.id,
                      created: Date.now()
                    }, { root: true })

                    // notify user via email
                    dispatch('notifications/send', {
                      recipient: rootGetters['users/getEmail'](payload.applicant.userid),
                      subject: 'TeacherFinder – You Got The Job!',
                      message: `
                                Hi ${rootGetters['users/userData'](payload.applicant.userid).firstName}, <br><br>
                                Congratulations! The job has been awarded to you.<br><br>
                                <a href="${window.location.origin}/job/${payload.applicant.jobId}" target="_blank">View Application</a>
                              `
                    }, { root: true })
                  }
                  else {
                    // notify user via email
                    dispatch('notifications/send', {
                      recipient: rootGetters['users/getEmail'](payload.applicant.userid),
                      subject: 'TeacherFinder Application Status',
                      message: `
                                Hi ${rootGetters['users/userData'](payload.applicant.userid).firstName}, <br><br>
                                Your job application status was updated.<br><br>
                                <a href="${window.location.origin}/job/${payload.applicant.jobId}" target="_blank">View Application</a>
                              `
                    }, { root: true })

                    if (payload.actions == 'Declined') {
                      dispatch('notifications/createAlert', {
                        recipient: payload.applicant.userid,
                        type: 'job_declined',
                        source: jobData.id,
                        created: Date.now()
                      }, { root: true })
                    }
                  }
                })
                .catch(error => {
                  commit('setError', error.message)
                })
            } else {
              dispatch('showError', 'Job is already closed.', { root: true })
            }
          }
        })
        .catch(error => {
          commit('setError', error.message)
        })
    }
  },

  async allApplicants({ commit }) {
    commit('setApplicantsFirstLoad', true)

    await db.collection('applies')
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          commit('initAllApplicants')
          commit('setAllApplicants', snapshot)
        }
      })
      .catch(error => {
        commit('setError', error.message)
      })
  },

  /*------------------------------------------------------------------------------
  * UPLOAD PROFILE BACKGROUND PHOTO
  *----------------------------------------------------------------------------*/
  async uploadContract({ commit, state, dispatch, rootGetters }) {
    commit('setUploadingContract', true)
    var storageRef = firebase.storage().ref()

    let name = `${Date.now()}_${state.contract.contractFile.name}`

    var metadata = {
      contentType: state.contract.contractFile.type
    }

    var uploadTask = storageRef.child(`contract/${name}`).put(state.contract.contractFile, metadata)

    await uploadTask.on('state_changed', snapshot => {
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      commit('setUploadProgress', progress)
    }, error => {
      commit('setUploadError', error.message)
    }, () => {
      commit('setUploadingContract', false)

      if (state.contractApplicant.contractFile) {
        storageRef.child(`contract/${state.contractApplicant.contractFile}`).delete().catch(error => console.log(error.message))
      }

      state.contractApplicant.ref.update({
        contractFile: name,
        contractStatus: 'Waiting for response',
        status: 'Uploaded Contract'
      })
        .then(() => {
          dispatch('showSuccess', 'Contract successfully uploaded.', { root: true })
          commit('resetUploadState')
          commit('setUploadContractDialog', false)

          dispatch('getAppliedUserInfo', state.contractApplicant.jobId)
          dispatch('job/getSelectedJobById', state.contractApplicant.jobId, { root: true })
          // notify user via email
          dispatch('notifications/send', {
            recipient: rootGetters['users/getEmail'](state.contractApplicant.userid),
            subject: 'TeacherFinder Application Status',
            message: `
                    Hi ${rootGetters['users/userData'](state.contractApplicant.userid).firstName}, <br><br>
                    Your job application status has changed.<br><br>
                    <a href="${window.location.origin}/job/${state.contractApplicant.jobId}" target="_blank">Job Status</a>
                `
          }, { root: true })
        })
    })
  },


  /* -------------------------------------------------------------------------- */
  /*               GET ALL APPLIED FROM THE JOBSEEKER FOR GETTERS               */
  /* -------------------------------------------------------------------------- */
  async getApplied({ commit, dispatch }) {
    let user = firebase.auth().currentUser
    commit('gettingAppliedState', true)
    
    await db.collection('applies')
    .where('userid', '==', user.uid)
    .get()
    .then(snapshot => {
      if (snapshot.size) {
        commit('addApplied', snapshot)
        dispatch('jobs/getJobsByIds', snapshot, { root: true })
      }
      else {
        commit('gettingAppliedState', true)
      }
    })
    .catch(error => {
      console.log(error.message)
      commit('gettingAppliedState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * SHORTLIST APPLICANT
   *----------------------------------------------------------------------------*/
  shortList({ commit, dispatch }, applicant) {
    applicant.ref.update({ shortlisted: true })
      .then(() => {
        dispatch('showSuccess', 'Applicant added to shortlist', { root: true })
        commit('addToShortlist', applicant)
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * SHORTLIST APPLICANT
   *----------------------------------------------------------------------------*/
  removeFromShortList({ commit, dispatch }, applicant) {
    applicant.ref.update({ shortlisted: false })
      .then(() => {
        dispatch('showSuccess', 'Removed from shortlist', { root: true })
        commit('removeFromShortlist', applicant)
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * GET APPLICANTS
   *----------------------------------------------------------------------------*/
  getApplicants({ commit, dispatch }) {
    let user = firebase.auth().currentUser

    if (user) {
      db.collection('jobs')
        .where('useruid', '==', user.uid)
        .where('status', '==', 'Open')
        .get()
        .then(snapshot => {
          let jobs = []

          if (snapshot.size) {
            snapshot.forEach(doc => {
              jobs.push(doc.id)
            })

            db.collection('applies')
            .where('jobId', 'in', jobs)
            .get()
            .then(snapshot => {
              commit('setApplicants', snapshot)
              
              if (snapshot.size) {
                snapshot.forEach(doc => {
                  dispatch('users/getUserByUid', doc.data().userid, { root: true })
                })
              }
            })
          }
          else {
            commit('setApplicants', { size: 0 })
          }
        })
        .catch(error => {
          console.log(error.message)
        })
    }
  },

  /*------------------------------------------------------------------------------
   * WITHDRAW APPLICATION
   *----------------------------------------------------------------------------*/
  async withDrawApplication({ commit, dispatch }, job) {
    let user = firebase.auth().currentUser
    commit('withdrawingState', true)
    
    await db.collection('applies')
    .where('jobId', '==', job)
    .where('userid', '==', user.uid)
    .limit(1).get()
    .then(snapshot => {
      if (snapshot.size) {
        let doc = snapshot.docs[0]
        
        return doc.ref.delete()
        .then(() => {
          commit('removeApplication', job)
          commit('withdrawingState', false)
          dispatch('showSuccess', 'Application has been withdrawn', { root: true })
        })
      }
    })
    .catch(error => {
      console.log(error.message)
      commit('withdrawingState', false)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * GET APPLICANT BY JOB ID
   *----------------------------------------------------------------------------*/
  getApplicantByJobIds({ commit, dispatch }, snapshot) {
    let ids = []

    snapshot.forEach(doc => {
      ids.push(doc.id)
    })

    db.collection('applies')
    .where('jobId', 'in', ids)
    .get()
    .then(snapshot => {
      if (snapshot.size) {
        commit('setApplicants', snapshot)

        snapshot.forEach(doc => {
          dispatch('users/getUserByUid', doc.data().userid, { root: true })
        })
      }
    })
    .catch(error => {
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * GET HIRED APPLICANTS
   *----------------------------------------------------------------------------*/
  getHiredApplicants({ commit }) {
    let user = firebase.auth().currentUser

    if (user) {
      db.collection('jobs')
      .where('useruid', '==', user.uid)
      .where('status', '==', 'Closed')
      .get()
      .then((snapshot) => {
        if (snapshot.size) {
          let promises = []
  
          snapshot.forEach(doc => {
            promises.push(
              db.collection('applies')
              .where('jobId', '==', doc.id)
              .where('status', '==', 'Hired')
              .get()
            )
          })
  
          Promise.all(promises)
          .then(snapshots => {
            if (snapshots.length) {
              let users = []
  
              snapshots.forEach(snapshot => {
                if (snapshot.size) {
                  snapshot.forEach(doc => {
                    let data = doc.data()
                    data.id = doc.id
                    data.ref = doc.ref
                    users.push(data)
                  })
                }
              })
  
              commit('setHiredApplicants', users)
            }
          })
        }
      })
      .catch(error => {
        console.log(error.message)
      })
    }
  }

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
