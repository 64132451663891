const geofire = require('geofire-common')
import db from '@/firebase/init'
import firebase from 'firebase'
import moment from 'moment'
import _ from 'lodash'
import Vue from 'vue'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/

const state = {
  user: null,
  data: {},
  password: {},
  talentFavorite: null,
  favorites: [],
  status: {
    error: null,
    signingUp: false,
    signingIn: false,
    signingInWithGoogle: false,
    showDialog: false,
    updating: false,
    showConfirmPassword: false,
    reauthenticating: false,
    accountConfirmed: false,
    confirmError: null,
    validatingPassword: false,
    passwordError: null,
    resetting: false,
    uploadingPhoto: false,
    uploadProgress: 0,
    uploadError: null,
    uploadPhotoDialog: false,
    uploadPhotoBackgroundDialog: false,
    action: 'login',
    success: null,
    importing: false,
  }
}

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  provider: () => {
    let user = firebase.auth().currentUser
    if (user.providerData[0].providerId == 'google.com') return 'Google'
    else if (user.providerData[0].providerId == 'facebook.com') return 'Facebook'
    else return 'Email and Password'
  },

  isFavorite: (state) => (id) => {
    if (state.favorites.length) {
      return !!state.favorites.find(fav => fav.talentId == id)
    }
    else {
      return false
    }
  },

  completeProfile: (state, getters, rootState) => {
    if (
        state.user && 
        rootState.profile.profile && 
        rootState.profile.referees &&
        rootState.profile.education
      ) {
      let complete = false
      let user = state.user
      let profile = rootState.profile.profile
      // let referees = rootState.profile.referees
      // let education = rootState.profile.education

      if (
        user.firstName && 
        user.lastName && 
        // user.declaration && 
        user.address &&
        Object.keys(profile).length &&
        // profile.phone &&
        profile.position &&
        profile.position.length &&
        // profile.aboutMe &&
        profile.teachingLvl &&
        profile.skillset &&
        profile.skillset.length
        // profile.relevantRegistrationDetails
      ) {
        complete = true
      }

      return complete
    }
    else {
      return false
    }
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  setSigningUp(state, bol) {
    state.status.signingUp = bol
  },

  setError(state, message) {
    state.status.error = message
  },

  clearData(state) {
    state.data = {}
  },

  setSigningIn(state, bol) {
    state.status.signingIn = bol
  },

  setUserData(state, payload) {
    let data = payload.data()
    data.ref = payload.ref
    data.id = payload.id
    state.user = data
  },

  setShowDialog(state, bol) {
    state.status.showDialog = bol
  },

  clearUser(state) {
    state.user = null
  },

  setSigningInWithGoogle(state, bol) {
    state.status.signingInWithGoogle = bol
  },

  setUpdatingState(state, bol) {
    state.status.updating = bol
  },

  setShowConfirmPassword(state, bol) {
    state.status.showConfirmPassword = bol
  },

  setReauthenticatingState(state, bol) {
    state.status.reauthenticating = bol
  },

  setAccountConfirmed(state, bol) {
    state.status.accountConfirmed = bol
  },

  setConfirmError(state, message) {
    state.status.confirmError = message
  },

  setValidatingPassword(state, bol) {
    state.status.validatingPassword = bol
  },

  setPasswordError(state, message) {
    state.status.passwordError = message
  },

  clearPassword(state) {
    state.data.password = null
  },

  setResettingState(state, bol) {
    state.status.resetting = bol
  },

  setUploadingPhoto(state, bol) {
    state.status.uploadingPhoto = bol
  },

  setUploadProgress(state, progress) {
    state.status.uploadProgress = progress
  },

  setUploadError(state, error) {
    state.status.uploadError = error
  },

  setUploadPhotoDialog(state, bol) {
    state.status.uploadPhotoDialog = bol
  },

  setUploadPhotoBackgroundDialog(state, bol) {
    state.status.uploadPhotoBackgroundDialog = bol
  },

  resetUploadState(state) {
    state.status.uploadProgress = 0
    state.data.avatar = null
  },

  updateUserAvatarName(state, name) {
    state.user.avatar = name
  },

  updateProfileBackground(state, name) {
    state.user.profileBackground = name
  },

  setActionType(state, type) {
    state.status.action = type
  },

  setTalentFavorite(state, payload) {
      let data = payload.data()
      data.ref = payload.ref
      state.talentFavorite = data
  },

  initTalentFavorite(state) {
    state.talentFavorite = null
  },

  addFavorites(state, payload) {
    state.favorites = []

    payload.forEach(fav => {
      if (!state.favorites.find(f => f.id == fav.id)) {
        let data = fav.data()
        data.id = fav.id
        data.ref = fav.ref
        state.favorites.push(data)
      }
    })
  },
  
  addFavorite(state, payload) {
    if (!state.favorites.find(f => f.id == payload.id)) {
      state.favorites.push(payload)
    }
  },

  removeFavorite(state, payload) {
    state.favorites.splice(state.favorites.indexOf(payload), 1)
  },

  successState(state, message) {
    state.status.success = message
  },

  setUserAddress(state, payload) {
    Vue.set(state.user, 'address', payload)
  },

  importingState(state, bol) {
    state.status.importing = bol
  },

  confirmingState(state, bol) {
    state.status.confirming = bol
  },

  updateDataObject(state, payload) {
    Vue.set(state.data, Object.keys(payload)[0], Object.values(payload)[0])
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * SIGNUP
   *----------------------------------------------------------------------------*/
  async signup({ commit, state, dispatch, rootState }) {
    commit('setSigningUp', true)
    var profile = Object.assign({}, rootState.profile.profile)

    await db.collection('users')
    .where('email', '==', state.data.email)
    .limit(1).get()
    .then(async (snapshot) => {
      let docRef
      
      if (snapshot.size) docRef = snapshot.docs[0].ref
      else docRef = db.collection('users').doc()

      return firebase.auth().createUserWithEmailAndPassword(state.data.email, state.data.password)
      .then(async (response) => {
        
        if (Object.keys(profile).length && state.data.role === 'jobseeker') {
          docRef.collection('profile')
          .doc(response.user.uid)
          .set(profile)
          .catch(error => {
            console.log(error.message)
          })
        } 

        let data = {
          fullName: `${state.data.firstName} ${state.data.lastName}`,
          address: state.data.address || null,
          firstName: state.data.firstName,
          lastName: state.data.lastName,
          userid: response.user.uid,
          email: state.data.email,
          role: state.data.role,
          createdAt: Date.now(),
          updatedAt: Date.now(),
          avatar: null,
          public: true,
        }

        if (state.data.role === 'employer') {
          data.trialEnd = moment(Date.now()).add(rootState.settings.data.freeTrial, 'days').valueOf()
        }

        return docRef.set(data)
        .then(() => {
          commit('setSigningUp', false)
          commit('setShowDialog', false)
          dispatch('sendSigupNotification', data)
          commit('clearData')

          dispatch('availability/availability', {
            available: { entireYear: 'full' },
            action: 'store',
          }, { root: true } )
        }) 
      })
      .catch(error => {
        commit('setError', error.message)
        commit('setSigningUp', false)
      })
    })
  },

  /*------------------------------------------------------------------------------
   * SIGN IN
   *----------------------------------------------------------------------------*/
  async signin({ commit, state, dispatch }) {
    commit('setSigningIn', true)
    commit('setError', null)

    
    await firebase.auth().signInWithEmailAndPassword(state.data.email, state.data.password)
    .then(() => {
      commit('setSigningIn', false)
      commit('setShowDialog', false)
      commit('clearData')
      dispatch('showSuccess', 'Login successful', { root: true })
      
      // fetch for school list
      dispatch('skillSets/getSkillSets', { data: null}, { root: true })
    })
    .catch(error => {
      commit('setError', error.message)
      commit('setSigningIn', false)
      commit('clearPassword')
    })
  },

  /*------------------------------------------------------------------------------
   * SET CURRENT USER
   *----------------------------------------------------------------------------*/
  setUser({ commit, dispatch }) {
    let currentUser = firebase.auth().currentUser

    if (currentUser) {
      db.collection('users')
      .where('userid', '==', currentUser.uid)
      .limit(1)
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          commit('setUserData', snapshot.docs[0])
          dispatch('profile/getProfile', null, { root: true })
        }
      })
      .catch(error => {
        commit('setError', error.message)
      })
    }
  },

  /*------------------------------------------------------------------------------
   * SIGNIN WITH GOOGLE
   *----------------------------------------------------------------------------*/
  async socialSignin({ state, commit, dispatch, rootState }, type) {
    var provider = type == 'google' ? new firebase.auth.GoogleAuthProvider() : new firebase.auth.FacebookAuthProvider()
    commit('setSigningIn', true)
    commit('setSigningUp', true)
    commit('setError', null)

    
    await firebase.auth().signInWithPopup(provider)
    .then(result => {
        var user = result.user

        let data = {
          userid: user.uid,
          email: user.email,
          fullName: user.displayName,
          firstName: user.displayName,
          lasttName: null,
          role: state.data.role || 'jobseeker',
          avatar: null,
          createdAt: Date.now(),
          updatedAt: Date.now(),
          public: true,
        }

        if (state.data.role == 'employer') {
          data.trialEnd = moment(Date.now()).add(rootState.settings.data.freeTrial, 'days').valueOf()
        }

        return db.collection('users')
          .where('userid', '==', user.uid)
          .get()
          .then(snapshot => {
            if (!snapshot.size) {
              db.collection('users').add(data)
                .then(() => {
                  commit('setSigningUp', false)
                  commit('setSigningIn', false)
                  commit('setShowDialog', false)
                  commit('clearData')
                  dispatch('setUser')
                  dispatch('sendSigupNotification', data)
                })
            }
            else {
              commit('setSigningIn', false)
              commit('setSigningUp', false)
              commit('setShowDialog', false)
              commit('clearData')
              dispatch('setUser')
            }

            // fetch for school list and skillsets
            dispatch('skillSets/getSkillSets', { data: null}, { root: true })
          })

      })
      .catch(error => {
        commit('setError', error.message)
        commit('setSigningIn', false)
        commit('setSigningUp', false)
        commit('clearData')
      })

  },

  /*------------------------------------------------------------------------------
   * UPDATE PROFILE
   *----------------------------------------------------------------------------*/
  updateProfile({ state, dispatch, commit }) {
    let user = firebase.auth().currentUser
    commit('setUpdatingState', true)
    if (state.user.address) state.user.geohash = geofire.geohashForLocation([state.user.address.latitude, state.user.address.longitude])

    state.user.ref.update(_.pick(state.user, ['firstName', 'lastName', 'public', 'jobTitle', 'address']))
      .then(() => {
        if (state.user.email !== user.email) {
          user.updateEmail(state.user.email)
          .then(() => {
            state.user.ref.update(_.pick(state.user, ['email']))
            dispatch('showSuccess', 'Profile successfully updated.', { root: true })
            commit('setUpdatingState', false)
            commit('users/updateUserData', state.user, { root: true })
          })
        }
        else {
          dispatch('showSuccess', 'Profile successfully updated.', { root: true })
          commit('setUpdatingState', false)
          commit('users/updateUserData', state.user, { root: true })
        }
      })
      .catch(error => {
        commit('setUpdatingState', false)
        commit('setError', error.message)
      })
  },

  /*------------------------------------------------------------------------------
   * REVALIDATE CURRENT PASSWORD
   *----------------------------------------------------------------------------*/
  async reauthenticate({ state, commit, dispatch }, password = null) {
    if (state.status.confirmError) commit('setConfirmError', null)
    commit('setReauthenticatingState', true)

    let user = firebase.auth().currentUser
    let credential = firebase.auth.EmailAuthProvider.credential(user.email, password)

    await user.reauthenticateWithCredential(credential)
      .then(() => {
        commit('setAccountConfirmed', true)
        commit('setShowConfirmPassword', false)
        commit('setReauthenticatingState', false)
        dispatch('showSuccess', 'Account authenticated', { root: true })
      })
      .catch(error => {
        commit('setConfirmError', error.message)
        commit('setReauthenticatingState', false)
      })

  },

  /*------------------------------------------------------------------------------
   * UPDATE PASSWORD
   *----------------------------------------------------------------------------*/
  async updatePassword({ state, commit, dispatch }) {
    commit('setValidatingPassword', true)
    if (state.status.passwordError) commit('setPasswordError', null)

    let user = firebase.auth().currentUser
    let credential = firebase.auth.EmailAuthProvider.credential(user.email, state.password.current)

    await user.reauthenticateWithCredential(credential)
      .then(() => {
        return user.updatePassword(state.password.new)
          .then(() => {
            commit('setValidatingPassword', false)
            commit('show')
            dispatch('showSuccess', 'Password successfully updated.', { root: true })
          })
      })
      .catch(error => {
        commit('setPasswordError', error.message)
        commit('setValidatingPassword', false)
      })

  },

  /*------------------------------------------------------------------------------
   * RESET PASSWORD
   *----------------------------------------------------------------------------*/
  resetPassword({ state, commit }) {
    commit('setResettingState', true)
    if (state.status.error) commit('setError', null)
    let user = firebase.auth().currentUser

    firebase.auth().sendPasswordResetEmail(user ? user.email : state.data.email)
      .then(() => {
        commit('setShowDialog', false)
        commit('setResettingState', false)
        commit('clearData')
        commit('successState', 'Reset email sent. Please check your inbox.')
      })
      .catch(error => {
        commit('setResettingState', false)
        commit('setError', error.message)
      })
  },

  /*------------------------------------------------------------------------------
   * UPLOAD PHOTO
   *----------------------------------------------------------------------------*/
  async uploadPhoto({ commit, state, dispatch }) {
    commit('setUploadingPhoto', true)
    var storageRef = firebase.storage().ref()
    let name = `${Date.now()}_${state.data.avatar.name}`

    var metadata = {
      contentType: state.data.avatar.type
    }

    var uploadTask = storageRef.child(`profilephotos/${name}`).put(state.data.avatar, metadata)

    await uploadTask.on('state_changed', snapshot => {
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      commit('setUploadProgress', progress)
    }, error => {
      commit('setUploadError', error.message)
    }, () => {
      commit('setUploadingPhoto', false)

      if (state.user.avatar) {
        storageRef.child(`profilephotos/${state.user.avatar}`).delete().catch(error => console.log(error.message))
        // storageRef.child(`profilephotos/thumb_${state.user.avatar}`).delete().catch(error => console.log(error.message))
        // storageRef.child(`profilephotos/medium_${state.user.avatar}`).delete().catch(error => console.log(error.message))
        // storageRef.child(`profilephotos/large_${state.user.avatar}`).delete().catch(error => console.log(error.message))
      }

      state.user.ref.update({
        avatar: name
      })
        .then(() => {
          dispatch('showSuccess', 'Profile photo successfully updated.', { root: true })
          commit('resetUploadState')
          commit('setUploadPhotoDialog', false)
          commit('updateUserAvatarName', name)
        })
    })
  },

  /*------------------------------------------------------------------------------
  * UPLOAD PROFILE BACKGROUND PHOTO
  *----------------------------------------------------------------------------*/
  async uploadProfileBackground({ commit, state, dispatch }) {
    commit('setUploadingPhoto', true)
    var storageRef = firebase.storage().ref()
    let name = `${Date.now()}_${state.data.profileBackground.name}`

    var metadata = {
      contentType: state.data.profileBackground.type
    }

    var uploadTask = storageRef.child(`profilebackground/${name}`).put(state.data.profileBackground, metadata)

    await uploadTask.on('state_changed', snapshot => {
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      commit('setUploadProgress', progress)
    }, error => {
      commit('setUploadError', error.message)
    }, () => {
      commit('setUploadingPhoto', false)

      if (state.user.profileBackground) {
        storageRef.child(`profilebackground/${state.user.profileBackground}`).delete().catch(error => console.log(error.message))
        // storageRef.child(`profilebackground/thumb_${state.user.profileBackground}`).delete().catch(error => console.log(error.message))
        // storageRef.child(`profilebackground/medium_${state.user.profileBackground}`).delete().catch(error => console.log(error.message))
        // storageRef.child(`profilebackground/large_${state.user.profileBackground}`).delete().catch(error => console.log(error.message))
      }

      state.user.ref.update({
        profileBackground: name
      })
        .then(() => {
          dispatch('showSuccess', 'Profile Background Cover successfully updated.', { root: true })
          commit('resetUploadState')
          commit('setUploadPhotoBackgroundDialog', false)
          commit('updateProfileBackground', name)
        })
    })
  },

  checkFavorite({ commit} , payload) {
    let currentUser = firebase.auth().currentUser

      db.collection('talentFavorites')
          .where('userid', '==', currentUser.uid)
          .where('talentId', '==', payload.userid)
          .limit(1)
          .get()
          .then(snapshot => {
              if (snapshot.size) {
                  commit('setTalentFavorite', snapshot.docs[0])
              }else{
                  commit('initTalentFavorite')
              }
          })
  },

  /*------------------------------------------------------------------------------
   * GET FAVORITES
   *----------------------------------------------------------------------------*/
  getFavorites({ commit, dispatch }) {
    let currentUser = firebase.auth().currentUser

    if (currentUser) {
      db.collection('talentFavorites')
      .where('userid', '==', currentUser.uid)
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          commit('addFavorites', snapshot)

          snapshot.forEach(doc => {
            dispatch('users/getUserByUid', doc.data().talentId, { root: true })
          })
        }
      })
      .catch(error => {
        console.log(error.message)
      })
    }
  },

  /*------------------------------------------------------------------------------
   * TOGGLE FAVORITE
   *----------------------------------------------------------------------------*/
  toggleFavorite({ commit, dispatch, rootState }, id) {
    let user = firebase.auth().currentUser
    let favorite = rootState.favorite.talentFavorites.find(f => f.talentId == id)
    
    if (favorite) {
      favorite.ref.delete()
      .then(() => {
        commit('removeFavorite', favorite)
        dispatch('favorite/removeFromFavorites', favorite.id, { root: true })
        dispatch('showSuccess', 'The talent has been removed from your favourites', { root: true })
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
    }
    else {
      let data = {
        userid: user.uid,
        talentId: id,
        createdAt: Date.now(),
        updatedAt: Date.now()
      }
      
      db.collection('talentFavorites').add(data)
      .then((docRef) => {
        dispatch('showSuccess', 'The talent is added to your favourites', { root: true })
        data.ref = docRef
        data.id = docRef.id
        commit('addFavorite', data)
        dispatch('favorite/addToFavorites', data, { root: true })
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
    }
  },

  /*------------------------------------------------------------------------------
   * SEND SIGNUP NOTIFICATION
   *----------------------------------------------------------------------------*/
  sendSigupNotification({ dispatch }, user) {
    let data = {
      recipient: user.email,
      subject: 'Welcome to TeacherFinder!',
      message: `
      Hi there,<br>
      <br>
      Thank you for registering with TeacherFinder!<br>
      <br>
      You’ll soon experience why educators and employers across Australia are choosing TeacherFinder. Its powerful education-specific features, versatility, ease of use and competitive pricing is taking teacher recruitment to new (faster and simpler!) levels.<br>
      <br>
      We are dedicated to ensuring your positive and successful experience with TeacherFinder. Our customer support team are here to help you with whatever you need, simply email support@teacherfinder.com.au or contact us via the live chat option from the website.<br>
      <br>
      As a member of the TeacherFinder community you can also have your say in the development of future versions of the software. If there is something you would like prioritised in the next software update just let us know (hello@teacherfinder.com.au) and we will do our best to make it happen.<br>
      <br>
      We look forward to helping you secure your next contract!<br>
      <br>
      Regards,<br>
      The Team at TeacherFinder
      `
    }

    dispatch('notifications/send', data, { root: true })
  },

  /*------------------------------------------------------------------------------
   * CONFIRM RESET PASSWORD
   *----------------------------------------------------------------------------*/
  async confirmResetPassword({ commit }, data) {
    commit('confirmingState', true)
    
    await firebase.auth().confirmPasswordReset(data.code, data.password)
    .then(() => {
      commit('confirmingState', false)
      commit('successState', true)
    })
    .catch(error => {
      commit('confirmingState', false)
      commit('setError', error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE DECLARATION
   *----------------------------------------------------------------------------*/
  updateDeclaration({ state, dispatch }) {
    state.user.ref
    .update({ declaration: state.user.declaration })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  }

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
