import db from '@/firebase/init'
import firebase from 'firebase'
import Vue from 'vue'
var storageRef = firebase.storage().ref()

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  data: {},
  messages: [],
  replies: [],
  attachments: [],
  status: {
    creating: false,
    getting: false,
    gettingData: false,
    replying: false,
    gettingReplies: false,
    uploading: 0,
    attached: [],
    progress: 0,
  }
}

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  message: (state) => (id) => {
    if (state.messages.length) {
      return state.messages.find(m => m.id == id)
    }
  },

  countMessagesByJobId: (state) => (id) => {
    let count = 0

    if (state.messages.length) {
      let message = state.messages.find(m => m.job == id)
      
      if (message) {
        count = 1

        if (state.replies.length) {
          let replies = state.replies.filter(reply => reply.job == id)
          count += replies.length
        }
      }
    }

    return count
  },

  getMessagesByJobId: (state) => (id) => {
    let message = {}

    if (state.messages.length) {
      message = state.messages.find(m => m.job == id)
    }

    return message || {}
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  creatingState(state, bol) {
    state.status.creating = bol
  },

  resetData(state) {
    state.data = {}
    state.attachments = []
    state.status.attached = []
  },

  gettingState(state, bol) {
    state.status.getting = bol
  },

  clearMessages(state) {
    state.messages = []
  },

  setMessages(state, payload) {
    if (payload.size) {
      payload.forEach(doc => {
        let data = doc.data()
        data.id = doc.id
        data.ref = doc.ref
        if (!state.messages.find(m => m.id == data.id)) state.messages.push(data)
      })
    }
  },

  insertMessage(state, payload) {
    if (!state.messages.find(m => m.id == payload.id)) {
      let message = payload.data()
      message.id = payload.id
      message.ref = payload.ref
      state.messages.push(message)
    }
  },

  gettingDataState(state, bol) {
    state.status.gettingData = bol
  },

  updateReadMessage(state, id) {
    let message = state.messages.find(m => m.id == id)
    let user = firebase.auth().currentUser

    if (message) {
      let newData = Object.assign({}, message)
      if (message.sender == user.uid) newData.recipientNew = false
      else newData.senderNew = false
      
      Vue.set(state.messages, state.messages.indexOf(message), newData)
    }
  },

  replyingState(state, bol) {
    state.status.replying = bol
  },

  gettingRepliesState(state, bol) {
    state.status.gettingReplies = bol
  },

  setReplies(state, payload) {
    state.replies = []

    if (payload.size) {
      payload.forEach(doc => {
        let reply = doc.data()
        reply.ref = doc.ref
        reply.id = doc.id
        state.replies.push(reply)
      })
    }

    state.status.gettingReplies = false
  },

  insertReply(state, payload) {
    state.replies.push(payload)
  },

  updateStarState(state, message) {
    let user = firebase.auth().currentUser
    let newData = Object.assign({}, message)

    if (message.sender == user.uid) {
      newData.senderStarred = message.senderStarred ? false : true
    }
    else {
      newData.recipientStarred = message.recipientStarred ? false : true
    }

    Vue.set(state.messages, state.messages.indexOf(message), newData)
  },
  
  updateArchiveState(state, message) {
    let user = firebase.auth().currentUser
    let newData = Object.assign({}, message)

    if (message.sender == user.uid) {
      newData.senderArchived = message.senderArchived ? false : true
    }
    else {
      newData.recipientArchived = message.recipientArchived ? false : true
    }

    Vue.set(state.messages, state.messages.indexOf(message), newData)
  },

  insertAttachments(state, payload) {
    if (!state.attachments.find(f => f == payload)) {
      state.attachments.push(payload)
    }
  },

  removeAttachment(state, i) {
    state.attachments.splice(i, 1)
  },

  uploadingState(state, count) {
    state.status.uploading += count
  },

  addAttached(state, payload) {
    state.status.attached.push(payload)
  },

  progressState(state, progress) {
    state.status.progress = progress
  },

  setSubject(state, text) {
    state.data.subject = text
  },
  
  setJob(state, id) {
    state.data.job = id
  },

  setMessage(state, value) {
    state.data.message = value
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * CREATE MEASSAGE
   *----------------------------------------------------------------------------*/
  async createMessage({ commit, dispatch, rootGetters }, recipient) {
    commit('creatingState', true)
    let user = firebase.auth().currentUser
    let date = Date.now()
    
    let data = state.data
    data.sender = user.uid
    data.recipient = recipient
    data.created = date
    data.updated = date
    data.senderNew = true
    data.recipientNew = false

    await db.collection('messages')
    .add(data)
    .then((docRef) => {
      if (state.status.attached.length) {
        let batch = db.batch()

        state.status.attached.forEach(attachment => {
          let doc = docRef.collection('attachments').doc()
          batch.set(doc, attachment)
        })

        batch.commit()
        .then(() => {
          commit('creatingState', false)
          commit('resetData')
        })
      }
      else {
        commit('creatingState', false)
        commit('resetData')
      }

      dispatch('notifications/send', {
        recipient: rootGetters['users/getEmail'](data.recipient),
        subject: 'TeacherFinder New Message',
        message: `
          Hi ${rootGetters['users/getFullName'](data.recipient)}, <br><br>
          You have a new message from ${rootGetters['users/getFullName'](data.sender)}.<br><br>
          <a href="${window.location.origin}/inbox/${docRef.id}" target="_blank">View Message</a>
        `
      }, { root: true })

      dispatch('notifications/createAlert', {
        recipient: data.recipient,
        type: 'message',
        source: docRef.id,
        created: Date.now()
      }, { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('creatingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * GET MESSAGES
   *----------------------------------------------------------------------------*/
  getMessages({ commit, dispatch }) {
    commit('clearMessages')
    commit('gettingState', true)
    let user = firebase.auth().currentUser

    Promise.all([
      db.collection('messages').where('sender', '==', user.uid).get(),
      db.collection('messages').where('recipient', '==', user.uid).get()
    ])
    .then(response => {
      response.forEach(snapshot => {
        if (snapshot.size) {
          commit('setMessages', snapshot)

          snapshot.forEach(doc => {
            dispatch('users/getUserByUid', doc.data().sender, { root: true })
            dispatch('users/getUserByUid', doc.data().recipient, { root: true })
          })
        }
      })

      commit('gettingState', false)
    })
    .catch(error => {
      commit(error.message)
      commit('gettingState', false)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * GET MESSAGE
   *----------------------------------------------------------------------------*/
  getMessage({ state, commit, dispatch }, id) {
    commit('gettingDataState', true)

    if (!state.messages.find(m => m.id == id)) {
      db.collection('messages')
      .doc(id).get()
      .then(doc => {
        if (doc.exists) {
          commit('insertMessage', doc)
          commit('gettingDataState', false)
          dispatch('users/getUserByUid', doc.data().sender, { root: true })
          dispatch('users/getUserByUid', doc.data().recipient, { root: true })
        }
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
        commit('gettingDataState', false)
      })
    }
    else {
      commit('gettingDataState', false)
    }
  },

  /*------------------------------------------------------------------------------
   * READ MESSAGE
   *----------------------------------------------------------------------------*/
  readMessage({ commit, dispatch }, message) {
    let user = firebase.auth().currentUser
    let task
    
    if (message.sender == user.uid) {
      task = message.ref.update({
        recipientNew: false
      })
    }
    else {
      task = message.ref.update({
        senderNew: false
      })
    }

    task.then(() => {
      commit('updateReadMessage', message.id)
      dispatch('notifications/deleteAlert', message.id, { root: true })
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * REPLY MESSAGE
   *----------------------------------------------------------------------------*/
  async replyMessage({ state, commit, dispatch, rootGetters }, message) {
    commit('replyingState', true)
    let user = firebase.auth().currentUser
    
    let data = {
      message: state.data.reply,
      sender: user.uid,
      created: Date.now(),
    }

    message.ref.collection('replies')
    .add(data)
    .then((docRef) => {
      if (state.status.attached.length) {
        let batch = db.batch()

        state.status.attached.forEach(attachment => {
          let doc = docRef.collection('attachments').doc()
          batch.set(doc, attachment)
        })

        batch.commit()
        .then(() => {
          commit('replyingState', false)
          dispatch('showSuccess', 'Reply added', { root: true })
          data.id = docRef.id
          data.ref = docRef
          commit('insertReply', data)
          commit('resetData')
        })
      }
      else {
        commit('replyingState', false)
        dispatch('showSuccess', 'Reply added', { root: true })
        data.id = docRef.id
        data.ref = docRef
        commit('insertReply', data)
        commit('resetData')
      }
      
      let sender = null
      let recipient = null

      if (message.sender == user.uid) {
        message.ref.update({ senderNew: true, updated: Date.now() })
        sender = message.sender
        recipient = message.recipient
      }
      else {
        message.ref.update({ recipientNew: true, updated: Date.now() })
        recipient = message.sender
        sender = message.recipient
      }

      dispatch('notifications/send', {
        recipient: rootGetters['users/getEmail'](recipient),
        subject: 'TeacherFinder Reply Message',
        message: `
          Hi ${rootGetters['users/getFullName'](recipient)}, <br><br>
          You have a reply message from ${rootGetters['users/getFullName'](sender)}.<br><br>
          <a href="${window.location.origin}/inbox/${message.id}" target="_blank">View Message</a>
        `
      }, { root: true })

      dispatch('notifications/createAlert', {
        recipient: recipient,
        type: 'message',
        source: message.id,
        created: Date.now()
      }, { root: true })
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
      commit('replyingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * GET MESSAGE REPLIES
   *----------------------------------------------------------------------------*/
  getReplies({ commit, dispatch }, id) {
    commit('gettingRepliesState', true)
    
    db.collection('messages')
    .doc(id).collection('replies')
    .orderBy('created', 'asc').get()
    .then(snapshot => {
      commit('gettingRepliesState', false)
      commit('setReplies', snapshot)
    })
    .catch(error => {
      console.log(error.message)
      commit('gettingRepliesState', false)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * STAR MESSAGE
   *----------------------------------------------------------------------------*/
  starMessage({ commit, dispatch }, message) {
    let user = firebase.auth().currentUser
    let task
    
    if (message.sender == user.uid) {
      task = message.ref.update({ senderStarred: message.senderStarred ? false : true })
    }
    else {
      task = message.ref.update({ recipientStarred: message.recipientStarred ? false : true })
    }

    task.then(() => {
      commit('updateStarState', message)
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  },
  
  /*------------------------------------------------------------------------------
   * ARCHIVE MESSAGE
   *----------------------------------------------------------------------------*/
  archiveMessage({ commit, dispatch }, message) {
    let user = firebase.auth().currentUser
    let task
    
    if (message.sender == user.uid) {
      task = message.ref.update({ senderArchived: message.senderArchived ? false : true })
    }
    else {
      task = message.ref.update({ recipientArchived: message.recipientArchived ? false : true })
    }

    task.then(() => {
      commit('updateArchiveState', message)
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * UPLOAD FILES
   *----------------------------------------------------------------------------*/
  uploadFiles({ state, commit, dispatch }) {
    commit('creatingState', true)
    
    state.attachments.forEach(async (file) => {
      let name = `${Date.now()}_${file.name}`

      var metadata = {
        contentType: file.type
      }

      var uploadTask  = storageRef.child(`attachments/${name}`).put(file, metadata)

      await uploadTask.on('state_changed', snapshot => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        commit('progressState', progress)
      }, error => {
        dispatch('showError', error.message, { root: true })
        console.log(error.message)
      }, () => {
        
        let data = {
          file: name,
          type: file.type,
          size: file.size,
          created: Date.now()
        }
        
        commit('uploadingState', -1)
        commit('addAttached', data)
      })
    })
  },

  /*------------------------------------------------------------------------------
   * GET MESSAGE BY JOB ID
   *----------------------------------------------------------------------------*/
  getMessageByJobId({ state, commit, dispatch }, id) {
    db.collection('messages')
    .where('job', '==', id)
    .limit(1).get()
    .then(snapshot => {
      if (snapshot.size) {
        let message = snapshot.docs[0]
        commit('insertMessage', message)
        dispatch('users/getUserByUid', message.data().sender, { root: true })
        dispatch('users/getUserByUid', message.data().recipient, { root: true })

        db.collection('messages')
        .doc(message.id)
        .collection('replies')
        .get()
        .then(snapshot => {
          if (snapshot.size) {
            snapshot.forEach(doc => {
              let data = doc.data()
              data.ref = doc.ref
              data.id = doc.id
              data.job = id

              if (!state.replies.find(r => r.id == data.id)) {
                commit('insertReply', data)
              }
            })
          }
        })
      }
    })
    .catch(error => {
      console.log(error.message)
    })
  }

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}