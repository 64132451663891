<template>
  <v-card max-width="270" outlined>
    <v-list-item :to="{ name: 'Admin' }" exact>
      <v-list-item-title class="font-weight-black primary--text heading-font">Dashboard</v-list-item-title>
    </v-list-item>
    <v-divider></v-divider>
    <v-card-text>
      <div class="font-weight-black primary--text heading-font">Users</div>
      <v-list class="custom-items">
        <v-list-item :to="{ name: 'Users' }" exact>
          <v-list-item-title>Users</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'ImportUsers' }">
          <v-list-item-title>Import</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text>
      <div class="font-weight-black primary--text heading-font">Jobs</div>
      <v-list class="custom-items">
        <v-list-item :to="{ name: 'AllJobs' }">
          <v-list-item-title>All open job ads</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'MyJobs' }">
          <v-list-item-title>Open job ads</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'JobsBooked' }">
          <v-list-item-title>Previous job ads</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'Job' }" exact>
          <v-list-item-title>Create a new job ad</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-divider></v-divider>
    <v-list-item :to="{ name: 'Packages' }">
      <v-list-item-title class="font-weight-black primary--text heading-font">Packages</v-list-item-title>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item :to="{ name: 'Message' }">
      <v-list-item-title class="font-weight-black primary--text heading-font">Inbox</v-list-item-title>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item :to="{ name: 'Skillsets' }">
      <v-list-item-title class="font-weight-black primary--text heading-font">Skill sets</v-list-item-title>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item :to="{ name: 'Positions' }">
      <v-list-item-title class="font-weight-black primary--text heading-font">Positions</v-list-item-title>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item :to="{ name: 'AccountSettings' }">
      <v-list-item-title class="font-weight-black primary--text heading-font">Account settings</v-list-item-title>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item :to="{ name: 'Reporting' }">
      <v-list-item-title class="font-weight-black primary--text heading-font">Reporting</v-list-item-title>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item :to="{ name: 'GeneralSettings' }">
      <v-list-item-title class="font-weight-black primary--text heading-font">General Settings</v-list-item-title>
    </v-list-item>
  </v-card>
</template>

<script>
import signout from '@/mixins/signout'

export default {
  mixins: [signout]
}
</script>

<style lang="scss">
.custom-items {
  .v-list-item {
    min-height: 30px;
  }
}
</style>