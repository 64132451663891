<template>
  <v-card max-width="270" outlined>
    <v-list-item :to="{ name: 'MyAccount' }">
      <v-list-item-title class="font-weight-black primary--text heading-font">My Account Overview</v-list-item-title>
    </v-list-item>
    <v-divider></v-divider>
    <template v-if="$vuetify.breakpoint.xs">
      <v-list-item :to="{ name: 'Talent' }">
        <v-list-item-title class="font-weight-black primary--text heading-font">Find a teacher</v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>
    </template>
    <v-card-text>
      <div class="font-weight-black primary--text heading-font">My Jobs and Teachers</div>
      <v-list class="custom-items">
        <v-list-item :to="{ name: 'MyJobs' }">
          <v-list-item-title>Open job ads</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'LapsedJobs' }">
          <v-list-item-title>Lapsed job ads</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'JobsBooked' }">
          <v-list-item-title>Previous job ads</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'Job' }" exact>
          <v-list-item-title>Create a new job ad</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'Favorites' }">
          <v-list-item-title>Preferred teachers</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'JobTemplates' }">
          <v-list-item-title>Saved job template</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-divider></v-divider>
    <v-list-item :to="{ name: 'Message' }">
      <v-list-item-title class="font-weight-black primary--text heading-font">My Inbox</v-list-item-title>
    </v-list-item>
    <v-divider></v-divider>
    <v-card-text>
      <div class="font-weight-black primary--text heading-font">My Details</div>
      <v-list class="custom-items">
        <v-list-item :to="{ name: 'Schools' }">
          <v-list-item-title>My school profile</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'AccountSettings' }">
          <v-list-item-title>Account settings</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'Subscription' }">
          <v-list-item-title>Billing and Subscription</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'EmployerReports' }">
          <v-list-item-title>Reports</v-list-item-title>
        </v-list-item>
        <v-list-item @click="signout()" class="mt-5">
          <v-list-item-title>Log out</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import signout from '@/mixins/signout'

export default {
  mixins: [signout]
}
</script>

<style lang="scss">
.custom-items {
  .v-list-item {
    min-height: 30px;
  }
}
</style>