import db from '@/firebase/init'
import _omit from 'lodash/omit'
import Vue from 'vue'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  data: {},
  skillSets: [{
    name: 'Please wait'
  }],
  skillsArray: [],
  status: {
    error: null,
    showDialog: false,
    getting: false,
    updating: false,
    firstLoad: false,
    confirmError: null,
    adding: false,
    deleting: false,
  },
}


/*------------------------------------------------------------------------------
* MUTATIONS
*----------------------------------------------------------------------------*/
const mutations = {
  setError(state, message) {
    state.status.error = message
  },

  setShowDialog(state, bol) {
    state.status.showDialog = bol
  },

  setUpdatingState(state, bol) {
    state.status.updating = bol
  },

  setFirstLoad(state, bol) {
    state.status.firstLoad = bol
  },

  setGetting(state, bol) {
    state.status.getting = bol
  },

  setSkillSets(state, payload) {
    state.skillSets = []
    payload.forEach(school => {
      let data = school.data()
      data.id = school.id
      data.ref = school.ref
      state.skillSets.push(data)
      state.skillsArray.push(data.name)
    })
  },

  initSkillSets(state) {
    state.skillSets = []
  },

  addingState(state, bol) {
    state.status.adding = bol
  },

  insertSkill(state, payload) {
    state.skillSets.push(payload)
  },

  deletingState(state, bol) {
    state.status.deleting = bol
  },

  removeSkill(state, payload) {
    let skill = state.skillSets.find(s => s.id == payload.id)
    state.skillSets.splice(state.skillSets.indexOf(skill), 1)
  },

  setData(state, payload) {
    state.data = Object.assign({}, payload)
  },

  updateSkillData(state, payload) {
    let skill = state.skillSets.find(s => s.id == payload.id)
    Vue.set(state.skillSets, state.skillSets.indexOf(skill), payload)
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  
  /* -------------------------------------------------------------------------- */
  /*                               GET SKILLSETS LIST                              */
  /* -------------------------------------------------------------------------- */
  getSkillSets({ commit }) {
    commit('setGetting', true)
    commit('setFirstLoad', true)
    
    db.collection('skillsets')
    .orderBy('name', 'asc')
    .get()
    .then( snapshot => {
      if (snapshot.size) {
        commit('setSkillSets', snapshot)
        commit('setGetting', false)
      }
    })
    .catch( error => {
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * ADD SKILLSET
   *----------------------------------------------------------------------------*/
  async addSkillset({ state, commit, dispatch }) {
    commit('addingState', true)
    
    let data = state.data
    data.created = Date.now()
    
    await db.collection('skillsets').add(data)
    .then((docRef) => {
      data.id = docRef.id
      data.ref = docRef
      commit('insertSkill', data)
      commit('addingState', false)
      dispatch('showSuccess', 'Skillset successfully added', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('addingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE SKILLSET
   *----------------------------------------------------------------------------*/
  async deleteSkillset({ commit, dispatch }, skill) {
    commit('deletingState', true)
    
    await skill.ref.delete()
    .then(() => {
      dispatch('showSuccess', 'Skillset deleted', { root: true })
      commit('deletingState', false)
      commit('removeSkill', skill)
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
      commit('deletingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE SKILLSET
   *----------------------------------------------------------------------------*/
  async updateSkillset({ state, commit, dispatch }) {
    commit('addingState', true)
    let skill = Object.assign({}, state.data)

    await skill.ref.update(_omit(skill, ['ref', 'id']))
    .then(() => {
      dispatch('showSuccess', 'Skillset updated', {root: true})
      commit('addingState', false)
      commit('updateSkillData', skill)
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, {root: true})
      commit('addingState', false)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
