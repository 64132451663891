import _omit from 'lodash/omit'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const defaultData = () => {
  return {
    schoolType: [],
    yearLvl: [],
    locale: [],
    contract: [],
    region: null,
    distance: 100,
    skillsets: null,
    date: null,
    position: null,
  }
}

const state = {
  templates: [],
  hits: [],
  data: defaultData(),
  status: {
    saving: false,
    orderBy: 'createdAt',
    ordering: 'desc',
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  savingState(state, bol){
    state.status.saving = bol
  },

  addSearch(state, payload) {
    if (!state.templates.find(t => t.id == payload.id)) {
      state.templates.push(payload)
    }
  },

  setData(state, payload) {
    if (!payload) Object.assign(state.data, defaultData())
    else state.data = Object.assign({}, payload)
  },

  removeTemplate(state, payload) {
    state.templates.splice(state.templates.indexOf(payload), 1)
  },

  clearExperience(state) {
    state.data.experience = null
  },
  
  clearSchoolName(state) {
    state.data.schoolName = null
  },
  
  clearDistance(state) {
    state.data.distance = null
  },
  
  clearSchoolType(state) {
    state.data.schoolType = []
  },
  
  clearJobDetails(state) {
    state.data.jobDetails = null
  },
  
  clearContract(state) {
    state.data.contract = []
  },
  
  clearLocale(state) {
    state.data.locale = []
  },

  clearYearLvl(state) {
    state.data.yearLvl = []
  },

  setHits(state, payload) {
    state.hits = payload
  },

  setRegion(state, payload) {
    state.data.region = payload
  },

  setPosition(state, data) {
    state.data.position = data
  },

  setSkillsets(state, data) {
    state.data.skillsets = data
  },
  
  setDate(state, data) {
    state.data.date = data
  },
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
 const actions = {
  /*------------------------------------------------------------------------------
   * SAVE SEARCH TEMPLATE
   *----------------------------------------------------------------------------*/
  async saveSearch({ state, commit, dispatch, rootState }) {
    commit('savingState', true)
    let user = rootState.user.user
    let data = state.data

    await user.ref.collection('searchTemplates')
    .add(_omit(data, ['id', 'ref']))
    .then((docRef) => {
      data.ref = docRef
      data.id = docRef.id
      commit('addSearch', data)
      commit('savingState', false)
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
      commit('savingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * GET TEMPLATES
   *----------------------------------------------------------------------------*/
  getTemplates({ commit, rootState }) {
    let user = rootState.user.user

    if(user){
      user.ref.collection('searchTemplates')
      .get().then(snapshot => {
        if (snapshot.size) {
          snapshot.forEach(doc => {
            let data = doc.data()
            data.id = doc.id
            data.ref = doc.ref
            commit('addSearch', data)
          })
        }
      })
      .catch(error => {
        console.log(error.message)
      })
    }
  },

  /*------------------------------------------------------------------------------
   * DELETE TEMPLATE
   *----------------------------------------------------------------------------*/
  deleteTemplate({ commit, dispatch }, template) {
    template.ref.delete()
    .then(() => {
      commit('removeTemplate', template)
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  }
 }

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}