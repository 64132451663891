import db from '@/firebase/init'
import firebase from 'firebase'
import Vue from 'vue'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  job: {},
  selectedJob: null,
  schoolLogo: null,
  selectedSchool: null,
  jobFavorite: null,
  talentQuery: [],
  shortLists: [],
  status: {
    error: null,
    showDialog: false,
    updating: false,
    firstLoad: false,
    getting: false,
    confirmError: null,
    adding: false,
    duplicating: null,
  }
}

/*------------------------------------------------------------------------------
* MUTATIONS
*----------------------------------------------------------------------------*/
const mutations = {
  setError(state, message) {
    state.status.error = message
  },

  setFirstLoad(state, bol) {
    state.status.firstLoad = bol
  },

  setGetting(state, bol) {
    state.status.getting = bol
  },

  setAdding(state, bol) {
    state.status.adding = bol
  },

  insertSelectedJob(state, payload) {
    let data = payload.data()
    data.id = payload.id
    data.ref = payload.ref
    state.selectedJob = data
  },

  setJob(state, payload) {
    let data

    try {
      data = payload.data()
      data.ref = payload.ref
      data.id = payload.id
    }
    catch {
      data = payload
    }

    if (!data.contractDates) data.contractDates = []
    
    state.job = data
  },

  initJob(state) {
    state.job = {}
  },

  setJobFavorite(state, payload) {
    let data = payload.data()
    data.ref = payload.ref
    state.jobFavorite = data
  },

  setTalentQuery(state, payload) {
    let data = payload.data()
    data.ref = payload.ref
    state.talentQuery.push(data)
  },

  initTalentQuery(state) {
    state.talentQuery = []
  },

  initJobFavorite(state) {
    state.jobFavorite = null
  },

  insertSelectedSchool(state, payload) {
    let data = payload.data()
    data.id = payload.id
    data.ref = payload.ref
    state.selectedSchool = data
  },

  addShortList(state, payload) {
    if (!state.shortLists.find(s => s.id == payload.id)) {
      state.shortLists.push(payload)
    }
  },

  removeShortlist(state, payload) {
    state.shortLists.splice(state.shortLists.indexOf(payload), 1)
  },

  duplicatingState(state, id) {
    state.status.duplicating = id
  },

  privateState(state, job) {
    job.private = !job.private
  },

  addContractRange(state, payload) {
    if (!state.job.contractDates) Vue.set(state.job, 'contractDates', [])
    let range = Object.assign({}, payload)
    state.job.contractDates.push(range)
  },
  
  updateRange(state, payload) {
    state.job.contractDates[payload.index] = Object.assign({}, payload.value)
  },

  removeContractRange(state, payload) {
    state.job.contractDates.splice(state.job.contractDates.indexOf(payload), 1)
  },

  clearSelectedSchool(state) {
    state.selectedSchool = null
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {

  async getSelectedJobById({ state, commit, dispatch }, data) {
    commit('setGetting', true)
    let currentUser = firebase.auth().currentUser

    await db.collection(data.template ? 'JobTemplates' : 'jobs')
      .doc(data.id)
      .get()
      .then(async doc => {
        if (doc.exists) {
          let job = doc.data()
          commit('setGetting', false)
          commit('insertSelectedJob', doc)

          await db.collection('favorites')
            .where('userid', '==', currentUser.uid)
            .where('jobId', '==', doc.id)
            .limit(1)
            .get()
            .then(snapshot => {
              if (snapshot.size) {
                commit('setJobFavorite', snapshot.docs[0])
              }
            })

          var storage = firebase.storage()
          db.collection('schools')
            .where('useruid', '==', job.useruid)
            .limit(1).get()
            .then(snapshot => {
              
              if (snapshot.size) {
                commit('insertSelectedSchool', snapshot.docs[0])
                let school = snapshot.docs[0].data()
                
                storage.ref(`schoollogos/${school.logo}`).getDownloadURL()
                  .then(url => {
                    state.schoolLogo = url
                  })
                  .catch(error => {
                    console.log(error.message)
                    storage.ref(`schoollogos/${school.logo}`).getDownloadURL()
                      .then(url => {
                        state.schoolLogo = url
                      })
                  })
              }
            })
            .catch(error => {
              commit('setError', error.message)
            })
        }
      })
      .catch(error => {
        dispatch('showError', error.message, { root: true })
      })
  },

  
  modifyJob({ commit, dispatch }, data) {
    commit('setGetting', true)
    commit('initJob')
    
    db.collection(data.template ? 'JobTemplates' : 'jobs')
      .doc(data.id).get()
      .then(doc => {
        if (doc.exists) {
          commit('setJob', doc)
        } 
        else {
          dispatch('showError', 'Job not found.', { root: true })
        }
        commit('setGetting', false)
      })
      .catch(error => {
        commit('setError', error.message)
        console.log(error.message)
      })
  },

  checkFavorite({ commit }, payload) {
    let currentUser = firebase.auth().currentUser

    db.collection('favorites')
      .where('userid', '==', currentUser.uid)
      .where('jobId', '==', payload.id)
      .limit(1)
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          commit('setJobFavorite', snapshot.docs[0])
        } else {
          commit('initJobFavorite')
        }
      })
  },

  async queryThisJob({ commit, dispatch }, payload) {
    commit('setAdding', true)
    let currentUser = firebase.auth().currentUser

    await db.collection('jobs').doc(payload.jobId).collection('queries').add({
        jobId: payload.jobId,
        message: payload.Msg,
        talentId: currentUser.uid,
        createdAt: Date.now(),
        updatedAt: Date.now(),
    })
    .then(() => {
        dispatch('showSuccess', 'Query delivered.', { root: true })
        commit('setAdding', false)
    })
    .catch(error => {
        commit('setError', error.message)
        commit('setAdding', false)
    })

    db.collection('schools').doc(payload.schoolId).collection('queries').add({
        jobId: payload.jobId,
        message: payload.Msg,
        talentId: currentUser.uid,
        createdAt: Date.now(),
        updatedAt: Date.now(),
    })
    .then(() => {
        dispatch('showSuccess', 'Query delivered.', { root: true })
        commit('setAdding', false)
    })
    .catch(error => {
        commit('setError', error.message)
        commit('setAdding', false)
      })
  },

  async getJobQueryByUser({ commit }, payload) {
    commit('setGetting', true)

    let currentUser = firebase.auth().currentUser

    try {
      commit('initTalentQuery')
      await db.collection('jobs').doc(payload).collection('queries')
        .where('talentId', '==', currentUser.uid)
        .where('jobId', '==', payload)
        .limit(1)
        .get()
        .then(snapshot => {
          if (snapshot.size) {
            let doc = snapshot.docs[0]
            commit('setTalentQuery', doc)
          }
        })
        .catch(error => {
          console.log(error.messag)
          commit('setError', error.message)
          commit('setAdding', false)
        })
    } catch (error) {
      console.log('No queries from the talent')
    }
  },

  /*------------------------------------------------------------------------------
   * TOGGLE VISIBILITY
   *----------------------------------------------------------------------------*/
  togglePrivate({ commit, dispatch }, job) {
    job.ref.update({ private: !job.private })
    .then(() => {
      commit('privateState', job)
      dispatch('showSuccess', 'Job visibility updated', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
