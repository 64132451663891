import db from '@/firebase/init'
import firebase from 'firebase'
import _omit from 'lodash/omit'
import Vue from 'vue'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  subscriptions: [],
  packages: [],
  data: {},
  status: {
    getting: false,
    updating: false,
  }
}

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  getSubscriberCount: (state, getters, rootState) => (price) => {
    if (state.subscriptions) {
      let subscriptions = state.subscriptions.filter(subscription => subscription.status == 'active')
      
      subscriptions = subscriptions.filter(subscription => {
        return subscription.priceId == price.id
      })

      subscriptions = subscriptions.filter(sub => {
        return rootState.users.users.find(u => u.userid == sub.customer)
      })
  
      return subscriptions.length
    }
    else {
      return 0
    }
  },
  
  getInactiveSubscriberCount: (state, getters, rootState) => (price) => {
    if (state.subscriptions) {
      let subscriptions = state.subscriptions.filter(subscription => subscription.status == 'canceled')
      
      subscriptions = subscriptions.filter(subscription => {
        return subscription.priceId == price.id
      })

      subscriptions = subscriptions.filter(sub => {
        return rootState.users.users.find(u => u.userid == sub.customer)
      })
  
      return subscriptions.length
    }
    else {
      return 0
    }
  },

  getSubscribers: (state) => (price) => {
    if (state.subscriptions) {
      let subscriptions = state.subscriptions.filter(subscription => subscription.status == 'active')
      
      subscriptions = subscriptions.filter(subscription => {
        return subscription.priceId == price.id 
      })
  
      return subscriptions
    }
    else {
      return null
    }
  },

  withActiveSubscription: (state) => (user) => {
    let subscriptions = state.subscriptions.filter(subscription => {
      return subscription.status == 'active'
    })

    let subscription = subscriptions.find(sub => sub.customer == user.userid)
    return !!subscription
  },

  onFreeTrial: (state, getters, rootState) => {
    let users = rootState.users.users
    let onFreeTrials = users.filter(user => {
      return user.trialEnd && user.trialEnd > Date.now()
    })

    return onFreeTrials
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  addSubscription(state, payload) {
    state.subscriptions.push(payload)
  },

  clearSubscriptions(state) {
    state.subscriptions = []
  },

  addPackages(state, payload) {
    state.packages = []

    payload.forEach(doc => {
      let data = doc.data()
      data.id = doc.id
      data.ref = doc.ref
      state.packages.push(data)
    })
  },

  setData(state, payload) {
    state.data = Object.assign({}, payload)
  },

  gettingState(state, bol) {
    state.status.getting = bol
  },

  updatingState(state, bol) {
    state.status.updating = bol
  },

  updatePriceData(state) {
    let price = state.packages.find(p => p.id == state.data.id)
    Vue.set(state.packages, state.packages.indexOf(price), state.data)
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * GET CUSTOMERS
   *----------------------------------------------------------------------------*/
  getCustomers({ commit, dispatch }) {
    commit('clearSubscriptions')

    db.collection('customers').get()
    .then(snapshot => {
      if (snapshot.size) {
        dispatch('getSubscriptions', snapshot)
      }
    })
    .catch(error => {
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * GET SUBSCRIPTIONS
   *----------------------------------------------------------------------------*/
  getSubscriptions({ commit, dispatch }, customers) {
    customers.forEach(customer => {
      customer.ref.collection('subscriptions')
      .get().then(snapshot => {
        if (snapshot.size) {
          snapshot.forEach(async doc => {
            await doc.data().price.get()
            .then(price => {
              let data = doc.data()
              data.id = doc.id
              data.ref = doc.ref
              data.priceId = price.id
              data.customer = customer.id
              commit('addSubscription', data)
              dispatch('users/getUserByUid', customer.id, { root: true })
            })
          })
        }
      })
      .catch(error => {
        console.log(error.message)
      })
    })
  },

  /*------------------------------------------------------------------------------
   * GET PACKAGES
   *----------------------------------------------------------------------------*/
  async getPackages({ commit }) {
    commit('gettingState', true)
    
    await db.collection('packages')
    .limit(1).get()
    .then(snapshot => {
      if (snapshot.size) {
        let doc = snapshot.docs[0]
        
        doc.ref.collection('prices').get()
        .then(snapshot => {
          if (snapshot.size) {
            commit('addPackages', snapshot)
          }

          commit('gettingState', false)
        })
      }
    })
    .catch(error => {
      console.log(error.message)
      commit('gettingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE PRICE
   *----------------------------------------------------------------------------*/
  async updatePrice({ state, commit }) {
    commit('updatingState', true)
    var updateData = firebase.functions().httpsCallable('price-updatePriceData')
    
    await updateData({ price: _omit(state.data, ['ref']) })
    .then(() => {
      commit('updatingState', false)
      commit('updatePriceData')
    })
    .catch(error => {
      console.log(error.message)
      commit('updatingState', false)
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}