import Vue from 'vue'

Vue.component('AdminTitle',
  () => import('@/components/dashboard/AdminTitle')
)

Vue.component('UserTitle',
  () => import('@/components/dashboard/UserTitle')
)

Vue.component('preloader',
  () => import('@/components/common/Preloader')
)

Vue.component('ConfirmPassword',
  () => import('@/components/auth/ConfirmPassword')
)

Vue.component('UserPhoto',
  () => import('@/components/user/UserPhoto')
)

Vue.component('UserPhotoCover',
  () => import('@/components/user/UserPhotoCover')
)

Vue.component('ConfirmDelete',
  () => import('@/components/common/ConfirmDelete')
)

Vue.component('VideoEmbed',
  () => import('@/components/common/VideoEmbed')
)

Vue.component('GlobalDialog',
  () => import('@/components/common/GlobalDialog')
)

Vue.component('back',
  () => import('@/views/common/Back')
)

Vue.component('FavouriteTeacher',
  () => import('@/views/talent/components/Favourite')
)

Vue.component('FavouriteJob',
  () => import('@/views/job/employer/components/FavouriteJob')
)

Vue.component('JobPhoto',
  () => import('@/components/dashboard/job/JobPhoto')
)

Vue.component('SchoolField',
  () => import('@/components/common/SchoolField')
)

Vue.component('SkillSetsField',
  () => import('@/components/common/SkillSetsField')
)

Vue.component('CustomAlert',
  () => import('@/components/common/CustomAlert')
)

Vue.component('AdminAlert',
  () => import('@/components/common/AdminAlert')
)