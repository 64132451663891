import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  methods: {
    signout() {
      firebase.auth().signOut()
      .then(() => {
        this.$store.commit('user/clearUser')
        if (this.$route.name !== 'Login') this.$router.push({ name: 'Login' })
      })
      .catch(error => {
        console.log(error.message)
      })
    }
  }
}