
import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import users from './modules/users'
import job from './modules/job'
import jobs from './modules/jobs'
import schools from './modules/schools'
import profile from './modules/profile'
import favorite from './modules/favorite'
import apply from './modules/apply'
import availability from './modules/availability'
import message from './modules/message'
import notifications from './modules/notifications'
import pricing from './modules/pricing'
import invite from './modules/invite'
import schoolsList from './modules/schoolsList'
import search from './modules/search'
import skillSets from './modules/skillSets'
import jobSearch from './modules/jobSearch'
import position from './modules/position'
import subscription from './modules/subscription'
import settings from './modules/settings'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    view: 'build',
    success: null,
    error: null,
    updating: false,
    preview: false,
    showDelete: false,
    deleteMessage: null,
    deleting: false,
  },

  mutations: {
    setViewState(state, type) {
      state.view = type
    },

    setSuccess(state, message) {
      state.success = message

      setTimeout(() => {
        state.success = null
      }, 3000)
    },

    setError(state, message) {
      state.error = message

      setTimeout(() => {
        state.error = null
      }, 3000)
    },

    updatingState(state, bol) {
        state.updating = bol
    },

    previewMode(state, bol) {
      state.preview = bol
    },

    confirmDeleteState(state, bol) {
      state.showDelete = bol
    },

    deletingState(state, bol) {
      state.deleting = bol
    },

    setDeleteMessage(state, message) {
      state.deleteMessage = message
    }
  },

  actions: {
    showSuccess({ commit }, message) {
      commit('setSuccess', message)
    },

    showError({ commit }, message) {
      commit('setError', message)
    },

    showUpdating({ commit }, bol) {
      commit('updatingState', bol)
    },
  },
  modules: {
    user,
    users,
    job,
    jobs,
    schools,
    profile,
    favorite,
    apply,
    availability,
    message,
    notifications,
    pricing,
    invite,
    schoolsList,
    search,
    skillSets,
    jobSearch,
    position,
    subscription,
    settings
  }
})
