import Vue from 'vue'
import App from './App.vue'

import VueClipboard from 'vue-clipboard2'
import vuetify from './plugins/vuetify'
import VueScrollTo from 'vue-scrollto'
import Embed from 'v-video-embed'
import firebase from 'firebase'
import VueMeta from 'vue-meta'
import router from './router'
import store from './store'
import '@/firebase/init'
import './prototypes'
import './components'
import './filters'
import 'prismjs/themes/prism-tomorrow.css'
import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"

VueClipboard.config.autoSetContainer = true
Vue.config.productionTip = false
Vue.use(VueClipboard)
Vue.use(VueScrollTo)
Vue.use(VueMeta)
Vue.use(Embed)

Sentry.init({
  Vue,
  dsn: "https://18a3a80bb41342dabe4d219bc0e1f289@o1038223.ingest.sentry.io/6013057",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "app.teacherfinder.com.au", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


let app = null

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      vuetify,
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})
