import generator from 'generate-password'
import db from '@/firebase/init'
import firebase from 'firebase'
import _ from 'lodash'
import Vue from 'vue'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  users: [],
  jobSeekers: [],
  talents: [],
  jobseekerAccount: null,
  data: {},
  photos: [],
  status: {
    getting: false,
    firstLoad: false,
    addDialog: false,
    error: null,
    adding: false,
    deleting: false,
    displayRange: 60,
    remoteWork: 0,
  }
}

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  getFullName: (state) => (id) => {
    if (state.users) {
      let user = state.users.find(u => u.userid == id)
      return user ? user.fullName : ''
    }
  },
  
  getEmail: (state) => (id) => {
    if (state.users) {
      let user = state.users.find(u => u.userid == id)
      return user ? user.email : ''
    }
  },

  userData: (state) => (id) => {
    if (state.users) {
      let user = state.users.find(u => u.userid == id)
      return user || {}
    }
  },
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *-----------------------------------------------------------------------------*/
const mutations = {
  setGettingUsers(state, bol) {
    state.status.getting = bol
  },

  setUsers(state, payload) {
    state.users = []

    if (payload.size) {
      payload.forEach(item => {
        let data = item.data()
        data.id = item.id
        data.ref = item.ref
        state.users.push(data)
      })
    }

    state.status.getting = false
    state.status.firstLoad = true
  },

  setJobseekerAccount(state, payload) {
    state.jobseekerAccount = []
    let data = payload.data()
    data.ref = payload.ref
    state.jobseekerAccount = data
  },

  initJobseekerAccount(state) {
    state.jobseekerAccount = []
  },

  setJobSeekers(state, payload) {
    if (payload.size) {
      payload.forEach(item => {

        let data = item.data()
        data.id = item.id
        data.ref = item.ref
        if (data.role == 'jobseeker') {
          state.jobSeekers.push(data)
        }
      })
    }
  },

  initJobSeekers(state) {
    state.jobSeekers = []
  },

  setTalent(state, payload) {
    state.talents.push(payload)
  },

  initTalent(state) {
    state.talents = []
  },

  setAddDialog(state, bol) {
    state.status.addDialog = bol
  },

  setAddingState(state, bol) {
    state.status.adding = bol
  },

  setError(state, message) {
    state.status.error = message
  },

  insertUser(state, user) {
    if (!state.users.find(u => u.id == user.id)) {
      let data = user.data()
      data.id = user.id
      data.ref = user.ref
      state.users.push(data)
    }
  },

  setData(state, payload) {
    state.data = payload
  },

  setDeleting(state, bol) {
    state.status.deleting = bol
  },

  removeUser(state, user) {
    state.users.splice(state.users.indexOf(user), 1)
  },

  setDisplayRange(state, num) {
    state.status.displayRange = num
  },

  firstLoadState(state, bol) {
    state.status.firstLoad = bol
  },

  updateUserData(state, payload) {
    let user = state.users.find(u => u.id == payload.id)
    Vue.set(state.users, state.users.indexOf(user), payload)
  },

  insertPhotos(state, payload) {
    if (!state.photos.find(p => p.id == payload.id))
      state.photos.push(payload)
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {

  /*------------------------------------------------------------------------------
   * GET USERS
   *----------------------------------------------------------------------------*/
  getUsers({ commit, dispatch }) {
    commit('setGettingUsers', true)

    db.collection('users')
    .get().then(snapshot => {
      if (snapshot.size) {
        commit('setUsers', snapshot)

        snapshot.forEach(user => {
          if (user.data().role == 'employer') {
            dispatch('schools/getSchoolByUid', user.data().userid, { root: true })
          }
        })
      }
      else {
        commit('setUsers', [])
      }
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * ADD USER
   *----------------------------------------------------------------------------*/
  addUser({ commit, state, dispatch }) {
    var importUser = firebase.functions().httpsCallable('user-importUsers')
    commit('setAddingState', true)
    let data = state.data

    db.collection('users')
    .where('email', '==', data.email)
    .limit(1).get()
    .then(snapshot => {
      // IF NOT AN EXISTING USER
      if (!snapshot.size) {
        data.password = generator.generate({length: 10, numbers: true})
        data.toCreate = true
        data.createdAt = Date.now()
        data.updatedAt = Date.now()
    
        importUser({ user: data })
        .then(async () => {
          commit('setAddingState', false)
          commit('setAddDialog', false)
          dispatch('showSuccess', 'User successfully added.', { root: true })

          let emailData = {
            recipient: data.email,
            subject: 'TeacherFinder Account',
            message: `
              Hi ${data.firstName},<br>
              <br>
              Here are your login details:<br>
              Email: ${data.email},<br>
              Password: ${data.password}<br>
              Login Page: <a href="${window.origin}/login">${window.origin}/login</a><br>
              <br>
              Regards,<br>
              TeacherFinder Team
            `
          }

          dispatch('notifications/send', emailData, { root: true })
          commit('setAddingState', false)
        })
      }
      else {
        let doc = snapshot.docs[0]

        doc.ref.update(_.pick(data, ['firstName', 'lastName', 'role']))
        .then(() => {
          commit('setAddDialog', false)
          commit('setAddingState', false)
          dispatch('showSuccess', 'User data updated.', { root: true })
        })
        .catch(error => {
          dispatch('showError', error.message, { root: true })
          console.log(error.message)
        })
      }
    })
    .catch(error => {
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * GET USER DATA
   *----------------------------------------------------------------------------*/
  getUser({ dispatch, commit }, id) {
    db.collection('users')
      .doc(id)
      .get()
      .then(doc => {
        commit('insertUser', doc)
      })
      .catch(error => {
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * GET USER By UID
   *----------------------------------------------------------------------------*/
  async getUserByUid({ state, commit, dispatch }, id) {
    if (!state.users.find(u => u.userid == id)) {
      await db.collection('users')
        .where('userid', '==', id)
        .limit(1).get()
        .then(snapshot => {
          if (snapshot.size) {
            let doc = snapshot.docs[0]
            let data = doc.data()
            data.id = doc.id
            data.ref = doc.ref
            commit('insertUser', doc)
            dispatch('profile/getProfileById', _.pick(data, ['id', 'userid']), { root: true })
          }
        })
        .catch(error => {
          dispatch('showError', error.message, { root: true })
        })
    }
  },

  /* -------------------------------------------------------------------------- */
  /*                               GET JOBSEEKERS                               */
  /* -------------------------------------------------------------------------- */
  async getJobSeeker({ commit, dispatch }) {
    commit('setGettingUsers', true)

    let users = db.collection('users')
    users = users.where('role', '==', 'jobseeker')
    users
    .get()
    .then(snapshot => {
        commit('initJobSeekers')
      if (snapshot.size) {
        commit('setJobSeekers', snapshot)
      }
      else {
        commit('setJobSeekers', [])
      }
      commit('setGettingUsers', false)
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
    })

  },

  /*------------------------------------------------------------------------------
   * DELETE AUTH USER
   *----------------------------------------------------------------------------*/
  async deleteAuthUser({ commit, dispatch }, user) {
    commit('setDeleting', true)
    var deleteUser = firebase.functions().httpsCallable('user-deleteAuthUser')
    
    await deleteUser({ userid: user.userid })
    .then(() => {
      commit('setDeleting', false)
      commit('removeUser', user)
      dispatch('showSuccess', 'User successfully deleted', { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE
   *----------------------------------------------------------------------------*/
  async delete({ commit, dispatch }, user) {
    commit('setDeleting', true)

    await user.ref.delete()
    .then(() => {
      commit('setDeleting', false)
      commit('removeUser', user)
      dispatch('showSuccess', 'User successfully deleted.', { root: true })
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
    })
  },


  /* -------------------------------------------------------------------------- */
  /*                                   TALENTS                                  */
  /* -------------------------------------------------------------------------- */


  /* -------------------------------------------------------------------------- */
  /*                               GET JOBSEEKERS                               */
  /* -------------------------------------------------------------------------- */
  getTalents({ commit, dispatch }) {
    commit('setGettingUsers', true)
    commit('firstLoadState', true)

    db.collection('users')
    .where('role', '==', 'jobseeker')
    .get()
    .then(snapshot => {
      commit('initTalent')
      if (snapshot.size) {
        commit('initJobSeekers')
        
        snapshot.forEach(item => {
          let data = item.data()
          data.id = item.id
          data.ref = item.ref
          commit('setTalent', data)
          dispatch('profile/getProfileById', _.pick(data, ['id', 'userid']), { root: true })
        })
      } else {
        commit('setTalent', [])
      }
      commit('setGettingUsers', false)
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  },

/* -------------------------------------------------------------------------- */
/*                             GET REMOTE TALENTS                             */
/* -------------------------------------------------------------------------- */
  getRemoteTalents({ state, commit, dispatch }) {
    commit('setGettingUsers', true)

    let users = db.collection('users')
    
    users = users.where('public', '==', true)
    users = users.where('role', '==','jobseeker')

    .orderBy('createdAt', 'desc')
    users
      .get()
      .then(snapshot => {
        commit('initTalent')
        if (snapshot.size) {
          
          state.jobSeekers = []
          snapshot.forEach(item => {
            let data = item.data()
            data.id = item.id
            data.ref = item.ref
            
            db.collection('users').doc(data.id).collection('profile')
            .limit(1)
            .get()
            .then(profile => {
              if (profile.size) {
                let profileData = profile.docs[0].data()

                if(state.status.remoteWork == 'all') {
                  data.profile = profileData
                  commit('setTalent', data)
                }else if(state.status.remoteWork == 'yes') {
                        if(profileData.remote) {
                        data.profile = profileData
                        commit('setTalent', data)
                        }    
                }else if(state.status.remoteWork == 'no') {
                    if(!profileData.remote) {
                        data.profile = profileData
                        commit('setTalent', data)
                    }
                }
              }
            })
            .catch(error => {
              console.log(error.message)
              commit('setError', error.message)
            })

          })
        } else {
          commit('setTalent', [])
        }
        commit('setGettingUsers', false)
      })
      .catch(error => {
        dispatch('showError', error.message, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * SET CURRENT USER
   *----------------------------------------------------------------------------*/
  getTalentAccount({ commit }, payload) {
    let userId = payload

    db.collection('users')
    .where('userid', '==', userId)
    .limit(1).get()
    .then(snapshot => {
      commit('initJobseekerAccount')
      
      if (snapshot.size) {
        commit('setJobseekerAccount', snapshot.docs[0])
      }
    })
    .catch(error => {
      commit('setError', error.message)
    })
  },

  searchedTalents({ commit }, payload) {
    commit('setGettingUsers', true)
    commit('initTalent')
    if(payload.data.length){
      payload.data.forEach( user => {
          let users = db.collection('users')
          let userSnapshot = users.doc(user.userID)
          userSnapshot
          .get()
          .then(snapshot => {
              if (snapshot.exists) {   
                  let userData = snapshot.data()
                  userData.id = snapshot.id
                  userData.ref = snapshot.ref  
                                
                  if(userData.public == true) {
                    userData.ref.collection('profile')
                    .limit(1)
                    .get()
                    .then(snapshot => {
                        if (snapshot.size) {
                          userData.profile = snapshot.docs[0].data()
                          commit('setTalent', userData)
                        }
                        commit('setGettingUsers', false)
                    })
                    .catch(error => {
                      commit('setError', error.message)
                    })
                  }
              } else {
                commit('setTalent', [])
              }
              commit('setGettingUsers', false)
          })
          .catch(error => {
              commit('setError', error.message)
          })
      })
    }
    commit('setGettingUsers', false)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
