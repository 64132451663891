import db from '@/firebase/init'
import firebase from 'firebase'
import _ from 'lodash'
import Vue from 'vue'
var storageRef = firebase.storage().ref()

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  user: null,
  profile: {},
  education: [],
  profiles: [],
  refers: [],
  experience: [],
  referees: [],
  hasProfile: false,
  backgroundCover: null,
  files: [],
  attachments: [],
  status: {
    error: null,
    showDialog: false,
    getting: false,
    updating: false,
    firstLoad: false,
    confirmError: null,
    adding: false,
    deletingEducation: false,
    uploading: false,
    updatingExperience: false,
    noneAttachment: false,
    deleting: false,
  }
}

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  userProfile: (state) => (id) => {
    if (state.profiles.length) {
      return state.profiles.find(p => p.id == id) || {}
    }
    else {
      return {}
    }
  },
  
  userReferees: (state) => (id) => {
    if (state.refers.length) {
      return state.refers.filter(p => p.user == id) || []
    }
    else {
      return []
    }
  },
}


/*------------------------------------------------------------------------------
* MUTATIONS
*----------------------------------------------------------------------------*/
const mutations = {
  setError(state, message) {
    state.status.error = message
  },

  setAddingState(state, bol) {
    state.status.adding = bol
  },

  setShowDialog(state, bol) {
    state.status.showDialog = bol
  },

  setUpdatingState(state, bol) {
    state.status.updating = bol
  },

  setConfirmError(state, message) {
    state.status.confirmError = message
  },

  setActionType(state, type) {
    state.status.action = type
  },

  setFirstLoad(state, bol) {
    state.status.firstLoad = bol
  },

  setGetting(state, bol) {
    state.status.getting = bol
  },

  selectedUser(state, obj) {
    state.user = obj
  },

  setUserData(state, payload) {
    let data = payload.data()
    data.ref = payload.ref
    state.user = data
  },

  setHasProfile(state, bol) {
    state.hasProfile = bol
  },

  setProfile(state, payload) {
    let data = payload.data()
    data.id = payload.id
    data.ref = payload.ref
    state.profile = data
  },
  
  initProfile(state) {
    state.profile = []
  },

  setEducation(state, payload) {
    let data = payload.data()
    data.id = payload.id
    data.ref = payload.ref
    state.education.push(data)
  },
  
  reInitEducation(state) {
    state.education = []
  },
  
  setReferees(state, payload) {
    let data = payload.data()
    data.id = payload.id
    data.ref = payload.ref
    state.referees.push(data)
  },

  reInitReferees(state) {
    state.referees = []
  },

  setExperience(state, payload) {
    let data = payload.data()
    data.id = payload.id
    data.ref = payload.ref
    state.experience.push(data)
  },

  reInitExperience(state) {
    state.experience = []
  },

  insertFile(state, file) {
    state.files.push(file)
  },

  insertAttachment(state, payload) {
    if (!state.attachments.find(a => a.id == payload.id)) {
      state.attachments.push(payload)
    }
  },

  removeFile(state, file) {
    state.files.splice(state.files.indexOf(file), 1)
  },

  removeAttachment(file) {
    state.attachments.splice(state.attachments.indexOf(file), 1)
  },

  addProfile(state, payload) {
    if (!state.profiles.find(p => p.id == payload.id)) {
      state.profiles.push(payload)
    }
  },
  
  addReferee(state, payload) {
    if (!state.refers.find(p => p.id == payload.id)) {
      state.refers.push(payload)
    }
  },

  deleteEducationState(state, bol) {
    state.status.deletingEducation = bol
  },

  removeEducation(state, payload) {
    state.education.splice(state.education.indexOf(payload), 1)
  },

  insertEducation(state, payload) {
    if (!state.education.find(e => e.id == payload.id)) {
      state.education.push(payload)
    }
  },

  updateEducationData(state, payload) {
    let educ = state.education.find(e => e.id == payload.id)
    Vue.set(state.education, state.education.indexOf(educ), payload)
  },

  uploadingState(state, bol) {
    state.status.uploading = bol
  },

  updateProfileResume(state, filename) {
    state.profile.resume = filename
  },

  updatingExperienceState(state, bol) {
    state.status.updatingExperience = bol
  },

  updateExperience(state, payload) {
    let exp = state.experience.find(e => e.id == payload.id)
    Vue.set(state.experience, state.experience.indexOf(exp), payload)
  },

  noneAttachmentState(state, bol) {
    state.status.noneAttachment = bol
  },

  insertExperience(state, payload) {
    if (!state.experience.find(e => e.id == payload.id)) state.experience.push(payload)
  },

  deletingState(state, bol) {
    state.status.deleting = bol
  },

  resetAll(state) {
    state.profile = {}
    state.education = []
    state.profiles = []
    state.refers = []
    state.experience = []
    state.referees = []
    state.hasProfile = false
    state.backgroundCover = null
    state.files = []
    state.attachments = []
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /* -------------------------------------------------------------------------- */
  /*                          Store Profile Information                         */
  /* -------------------------------------------------------------------------- */
  async storeProfileDetails({ state, commit, dispatch }, payload) {
    commit('setAddingState', true)

    let data = {
      phone: payload.profile.phone,
      website: payload.profile.website ? payload.profile.website : null,
      location: payload.profile.location,
      remote: payload.profile.remote || false,
      facebook: payload.profile.facebook ? payload.profile.facebook : null,
      twitter: payload.profile.twitter ? payload.profile.twitter : null,
      instagram: payload.profile.instagram ? payload.profile.instagram : null,
      linkedin: payload.profile.linkedin ? payload.profile.linkedin : null,
      videoPresentation: payload.profile.videoPresentation ? payload.profile.videoPresentation : null,
      skillset: payload.profile.skillset,
      teachingLvl: payload.profile.teachingLvl,
      ableToSupervise: payload.profile.ableToSupervise || null,
      schoolWorkedAt: payload.profile.schoolWorkedAt || null,
      aboutMe: payload.profile.aboutMe,
      workingWithChildrenCheck: !!payload.profile.workingWithChildrenCheck || false,
      teacherRegistration: !!payload.profile.teacherRegistration || false,
      resume: !!payload.profile.resume || false,
      public: payload.profile.public || false,
      updatedAt: Date.now(),
    }

    if (payload.type == 'add') {
      data.createdAt = Date.now()

      // ADD
      if (payload.profile) {
        await db.collection('users')
        .doc(state.user.id)
        .collection('profile')
        .doc(state.user.userid)
        .set(data)
        .then(() => {
          dispatch('showSuccess', 'Profile Information successfully added.', { root: true })
          commit('setAddingState', false)
        })
        .catch(error => {
          commit('setError', error.message)
          commit('setAddingState', false)
        })

      }

      if (payload.educations.length) {
        await payload.educations.forEach(function (education) {
          db.collection('users').doc(state.user.ref.id).collection('education').add({
            schoolName: education.schoolName,
            fieldOfStudy: education.fieldOfStudy,
            yearStart: education.yearStart,
            yearEnd: education.yearEnd,
            introduction: education.introduction,
            achievements: education.achievements ? education.achievements : null,

            createdAt: Date.now(),
            updatedAt: Date.now(),
          })
            .then(() => {
              commit('setAddingState', false)
            })
            .catch(error => {
              commit('setError', error.message)
              commit('setAddingState', false)
            })
        })
      }

      if (payload.recentExperience.length) {
        await payload.recentExperience.forEach(function (experience) {
          db.collection('users').doc(state.user.ref.id).collection('experience').add({
            schoolName: experience.schoolName,
            city: experience.city,
            yearStart: experience.yearStart,
            yearEnd: experience.yearEnd,
            position: experience.position,
            createdAt: Date.now(),
            updatedAt: Date.now(),
          })
            .then(() => {
              commit('setAddingState', false)
            })
            .catch(error => {
              commit('setError', error.message)
              commit('setAddingState', false)
            })
        })
      }
      
      if (payload.referees.length) {
        await payload.referees.forEach(function (data) {
          db.collection('users').doc(state.user.ref.id).collection('referees').add({
            name: data.name,
            position: data.position,
            organisation: data.organisation,
            phone: data.phone,
            email: data.email,
            createdAt: Date.now(),
            updatedAt: Date.now(),
          })
            .then(() => {
              commit('setAddingState', false)
            })
            .catch(error => {
              commit('setError', error.message)
              commit('setAddingState', false)
            })
        })
      }
    } 
    else {
      // UPDATE USER PROFILE
      if (payload.profile) {
        await payload.profile.ref.update(data, { merge: true })
          .then(() => {
            dispatch('showSuccess', 'Profile Information successfully updated.', { root: true })
            commit('setAddingState', false)
          })
          .catch(error => {
            commit('setError', error.message)
            commit('setAddingState', false)
          })
      }

      if (payload.education.length) {

        let errors = []
        let success = []

        // get education and delete
        db.collection('users').doc(state.user.ref.id)
          .collection('education')
          .orderBy('yearStart')
          .get()
          .then(snapshot => {
            if (snapshot.size) {
              commit('reInitEducation')
              snapshot.forEach(item => {
                item.ref.delete()
                  .then(() => {
                    success.push(item.schoolName + 'deleted')
                  })
                  .catch(error => {
                    errors.push(error.message)
                  })
              })

              //Store after delete
              payload.education.forEach(function (_item) {
                db.collection('users').doc(state.user.ref.id).collection('education').add({
                  schoolName: _item.schoolName,
                  fieldOfStudy: _item.fieldOfStudy,
                  yearStart: _item.yearStart,
                  yearEnd: _item.yearEnd,
                  introduction: _item.introduction,
                  achievements: _item.achievements ? _item.achievements : null,
                  createdAt: Date.now(),
                  updatedAt: Date.now(),
                })
                  .then(() => {
                    success.push(_item.schoolName + 'added')
                  })
                  .catch(error => {
                    errors.push(error.message)
                  })
              })
            }
          })
          .catch(error => {
            commit('setError', error.message)
          })

        if (errors.length) {
          commit('setError', errors)
        }
      }
      
      if (payload.recentExperience.length) {

        let errors = []
        let success = []

        // get experience and delete
        db.collection('users')
        .doc(state.user.ref.id)
        .collection('experience')
          .get()
          .then(snapshot => {
            if (snapshot.size) {
              commit('reInitExperience')
              snapshot.forEach(item => {
                item.ref.delete()
                  .then(() => {
                    success.push(item.schoolName + 'deleted')
                  })
                  .catch(error => {
                    errors.push(error.message)
                  })
              })

              //Store after delete
              payload.recentExperience.forEach(function (_item) {
                db.collection('users').doc(state.user.ref.id).collection('experience').add({
                  schoolName: _item.schoolName,
                  city: _item.city,
                  yearStart: _item.yearStart,
                  yearEnd: _item.yearEnd,
                  position: _item.position,
                  createdAt: Date.now(),
                  updatedAt: Date.now(),
                })
                  .then(() => {
                    success.push(_item.schoolName + 'added')
                  })
                  .catch(error => {
                    errors.push(error.message)
                  })
              })
            }
            else {
              //Store after delete
              payload.recentExperience.forEach(function (_item) {
                db.collection('users').doc(state.user.ref.id).collection('experience').add({
                  schoolName: _item.schoolName,
                  city: _item.city,
                  yearStart: _item.yearStart,
                  yearEnd: _item.yearEnd,
                  position: _item.position,
                  createdAt: Date.now(),
                  updatedAt: Date.now(),
                })
                  .then(() => {
                    success.push(_item.schoolName + 'added')
                  })
                  .catch(error => {
                    errors.push(error.message)
                  })
              })
            }

          })
          .catch(error => {
            commit('setError', error.message)
          })

        if (errors.length) {
          commit('setError', errors)
        }
      }


      if (payload.referees.length) {

        let errors = []
        let success = []

        // get education and delete
        db.collection('users').doc(state.user.ref.id).collection('referees')
          .get()
          .then(snapshot => {
            if (snapshot.size) {
              commit('reInitReferees')
              snapshot.forEach(item => {
                item.ref.delete()
                  .then(() => {
                    success.push(item.name + 'deleted')
                  })
                  .catch(error => {
                    errors.push(error.message)
                  })
              })

              //Store after delete
              payload.referees.forEach(function (_item) {
                db.collection('users').doc(state.user.ref.id).collection('referees').add({
                  name: _item.name,
                  position: _item.position,
                  organisation: _item.organisation,
                  phone: _item.phone,
                  email: _item.email,
                  createdAt: Date.now(),
                  updatedAt: Date.now(),
                })
                  .then(() => {
                    success.push(_item.name + 'added')
                  })
                  .catch(error => {
                    errors.push(error.message)
                  })
              })
            }else{
              //Store after delete
              payload.referees.forEach(function (_item) {
                db.collection('users').doc(state.user.ref.id).collection('referees').add({
                  name: _item.name,
                  position: _item.position,
                  organisation: _item.organisation,
                  phone: _item.phone,
                  email: _item.email,
                  createdAt: Date.now(),
                  updatedAt: Date.now(),
                })
                  .then(() => {
                    success.push(_item.name + 'added')
                  })
                  .catch(error => {
                    errors.push(error.message)
                  })
              })
            }

          })
          .catch(error => {
            commit('setError', error.message)
          })

        if (errors.length) {
          commit('setError', errors)
        }
      }

    }
  },

  /*------------------------------------------------------------------------------
   * GET PROFILE INFORMATION
   *----------------------------------------------------------------------------*/
  async getProfile({ commit, dispatch, rootState }, id = null) {
    let user = id ? rootState.users.users.find(u => u.userid == id) : rootState.user.user
    
    if (user) {
      let userId = id || user.userid
      commit('setGetting', true)
      commit('initProfile')
      
      // GET PROFILE
      await user.ref.collection('profile')
      .doc(userId).get()
      .then(profileDoc => {
        if (profileDoc.exists) {
          commit('setProfile', profileDoc)
          commit('setFirstLoad', true)
          commit('setHasProfile', true)
          dispatch('getAttachments')
          commit('setGetting', false)
        }
        else {
          commit('setGetting', false)
        }
      })
      .catch(error => {
        commit('setError', error.message)
      })

      // get education
      await user.ref.collection('education')
        .orderBy('yearStart')
        .get()
        .then(snapshot => {
          if (snapshot.size) {
            commit('reInitEducation')
            snapshot.forEach(item => {
              commit('setEducation', item)
            })
          }
        })
        .catch(error => {
          commit('setError', error.message)
        })

      // get experience
      await user.ref.collection('experience')
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          commit('reInitExperience')
          snapshot.forEach(item => {
            commit('setExperience', item)
          })
        }
      })
      .catch(error => {
        commit('setError', error.message)
      })
      
      // get referees
      await user.ref.collection('referees')
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          commit('reInitReferees')
          snapshot.forEach(item => {
            commit('setReferees', item)
          })
        }
      })
      .catch(error => {
        commit('setError', error.message)
      })

      commit('setGetting', false)
    }
  },

  /* -------------------------------------------------------------------------- */
  /*                       Profile Background Cover Photo                       */
  /* -------------------------------------------------------------------------- */
  getBackgroundPhotoUrl({ state }, payload) {
    let userId = ''
    if (payload) {
      userId = payload
    } else {
      let currentUser = firebase.auth().currentUser
      userId = currentUser.uid
    }

    let currentUser = null
    db.collection('users').where('userid', '==', userId)
      .limit(1)
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          let data = snapshot.docs[0]
          currentUser = data.data()
          currentUser.id = data.id
          currentUser.ref = data.ref
          if (currentUser.profileBackground) {
            var storage = firebase.storage()
            storage.ref(`profilebackground/${currentUser.profileBackground}`).getDownloadURL()
              .then(url => {
                state.backgroundCover = url
              })
              .catch(error => {
                console.log(error.message)
                storage.ref(`profilebackground/${currentUser.profileBackground}`).getDownloadURL()

                  .then(url => {
                    state.backgroundCover = url
                  })
              })
          }
        }
      })
      .catch(error => {
        this.$store.dispatch('showError', error.message)
        console.log('UserPhotoCover', error.message)
      })
  },

  /*------------------------------------------------------------------------------
   * UPLOAD FILES
   *----------------------------------------------------------------------------*/
  async uploadFile({ state, commit, dispatch }, file) {
    let name = `${Date.now()}_${file.name}`

    var metadata = {
      contentType: file.type
    }

    var uploadTask  = storageRef.child(`attachments/${name}`).put(file, metadata)

    await uploadTask.on('state_changed', snapshot => {
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      console.log(progress)
    }, error => {
      dispatch('showError', error.message, { root: true })
      console.log(error.message)
    }, () => {
      
      dispatch('showSuccess', 'File successfully uploaded', { root: true })


      if (!state.status.noneAttachment) {
        let data = {
          file: name,
          type: file.type,
          size: file.size,
          created: Date.now()
        }

        if (!state.profile.ref) state.profile.ref = state.user.ref.collection('profile').doc()
        
        state.profile.ref.collection('attachments')
        .add(data)
        .then((docRef) => {
          data.ref = docRef
          data.id = docRef.id
          commit('insertAttachment', data)
          commit('removeFile', file)
        })
        .catch(error => {
          console.log(error.message)
        })
      }
      else {
        commit('noneAttachmentState', false)
      }
    })

    return name
  },

  /*------------------------------------------------------------------------------
   * GET ATTACHMENTS
   *----------------------------------------------------------------------------*/
  getAttachments({ state, commit }) {
    if (state.profile) {
      state.profile.ref.collection('attachments')
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          snapshot.forEach(doc => {
            let file = doc.data()
            file.id = doc.id
            file.ref = doc.ref
            commit('insertAttachment', file)
          })
        }
      })
    }
  },

  /*------------------------------------------------------------------------------
   * DELETE ATTACHMENT
   *----------------------------------------------------------------------------*/
  deleteAttachment({ commit, dispatch }, file) {
    file.ref.delete()
    .then(() => {
      commit('removeAttachment', file)
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * GET PROFILE BY ID
   *----------------------------------------------------------------------------*/
  getProfileById({ state, commit }, data) {
    if (!state.profiles.find(p => p.user == data.id)) {
      db.collection('users')
      .doc(data.id)
      .collection('profile')
      .doc(data.userid)
      .get()
      .then(doc => {
        if (doc.exists) {
          let data = doc.data()
          data.ref = doc.ref
          data.id = doc.id
          data.user = data.id
          commit('addProfile', data)
        }
      })
      .catch(error => {
        console.log(error.message)
      })
    }
  },
  
  /*------------------------------------------------------------------------------
   * GET REFEREE BY ID
   *----------------------------------------------------------------------------*/
  getRefereeById({ commit }, id) {
    db.collection('users')
    .doc(id)
    .collection('referees')
    .get()
    .then(snapshot => {
      if (snapshot.size) {
        snapshot.forEach(doc => {
          let data = doc.data()
          data.id = doc.id
          data.ref = doc.ref
          data.user = id
          commit('addReferee', data)
        })
      }
    })
    .catch(error => {
      console.log(error.message)
    })
  },

/* -------------------------------------------------------------------------- */
/*                         DELETE PARTICULAR EDUCATION                        */
/* -------------------------------------------------------------------------- */
  async deleteEducation({ commit, dispatch }, educ) {
    commit('deleteEducationState', true)

    await educ.ref.delete()
    .then(() => {
      dispatch('showSuccess', 'Education removed', { root: true })
      commit('deleteEducationState', false)
      commit('removeEducation', educ)
    })
    .catch(error => {
      console.log(error.message)
      commit('deleteEducationState', false)
      dispatch('showError', error.message, { root: true })
    })
  },

/* -------------------------------------------------------------------------- */
/*                                ADD EDUCATION                               */
/* -------------------------------------------------------------------------- */
  addEducation({ commit, dispatch, rootState }, data) {
    commit('setAddingState', true)
    let newData = data

    newData.createdAt = Date.now()
    newData.updatedAt = Date.now()
    
    db.collection('users')
    .doc(rootState.user.user.ref.id)
    .collection('education')
    .add(_.omitBy(newData, _.isUndefined))
    .then((docRef) => {
      dispatch('showSuccess', 'Qualications successfully added.', { root: true })
      commit('setAddingState', false)
      
      newData.ref = docRef
      newData.id = docRef.id
      commit('insertEducation', newData)
    })
    .catch(error => {
      commit('setError', error.message)
      commit('setAddingState', false)
    })

    dispatch('getEducation')

  },

/* -------------------------------------------------------------------------- */
/*                               UPDATE PROFILE                               */
/* -------------------------------------------------------------------------- */
updateProfileInfo({ commit, dispatch, rootState }, payload) {
  let user = rootState.user.user
  
  if (payload.id) {
    commit('setUpdatingState', true)

    payload.ref.update(_.omit(payload, ['ref', 'id']))
    .then(() => {
      dispatch('showSuccess', 'Information was successfully updated.', { root: true })
      dispatch('getProfile')
      commit('setUpdatingState', false)
    })
    .catch(error => {
      commit('setError', error.message)
      commit('setUpdatingState', false)
    })
  }
  else {
    payload.createdAt = Date.now()
    payload.updatedAt = Date.now()
    commit('setAddingState', false)
    
    user.ref.collection('profile')
    .doc(user.userid)
    .set(_.omitBy(payload, _.isUndefined))
    .then(() => {
      dispatch('showSuccess', 'Information successfully added.', { root: true })
      commit('setAddingState', false)
    })
    .catch(error => {
      commit('setError', error.message)
      commit('setAddingState', false)
    })
  }
},


/* -------------------------------------------------------------------------- */
/*                              UPDATE EDUCATION                              */
/* -------------------------------------------------------------------------- */
  async updateEducation({ commit, dispatch }, educ) {
    commit('setUpdatingState', true)

    await educ.ref.update(_.omit(educ, ['ref', 'id']))
    .then(() => {
      dispatch('showSuccess', 'Qualication data was successfully updated.', { root: true })
      commit('setUpdatingState', false)
      commit('updateEducationData', educ)
    })
    .catch(error => {
      commit('setError', error.message)
      commit('setUpdatingState', false)
    })
  },

/* -------------------------------------------------------------------------- */
/*                                GET EDUCATION                               */
/* -------------------------------------------------------------------------- */
  getEducation({ commit, rootState }) {
    commit('setGetting', true)

    try{
      db.collection('users').doc(rootState.user.user.ref.id).collection('education')
      .orderBy('yearStart')
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          commit('reInitEducation')
          snapshot.forEach(item => {
            commit('setEducation', item)
          })
        }
        commit('setGetting', false)
      })
      .catch(error => {
        commit('setError', error.message)
      })
    } catch(e) {
      console.log(e.message)
    }
  },

/* -------------------------------------------------------------------------- */
/*                                ADD REFEREES                                */
/* -------------------------------------------------------------------------- */
  addReferees({ commit, dispatch, rootState }, payload) {
    commit('setAddingState', true)

    try{
      payload.forEach( referees => {
        referees.createdAt = Date.now()
        referees.updatedAt = Date.now()
        db.collection('users').doc(rootState.user.user.ref.id).collection('referees')
        .add(_.omitBy(referees, _.isUndefined))
        .then(() => {
          dispatch('showSuccess', 'Professional Referees successfully added.', { root: true })
          commit('setAddingState', false)
        })
        .catch(error => {
          commit('setError', error.message)
          commit('setAddingState', false)
        })
      })
    } catch(e) {
      console.log(e.message)
    }

    dispatch('getReferees')
  },

/* -------------------------------------------------------------------------- */
/*                               UPDATE REFEREES                              */
/* -------------------------------------------------------------------------- */
  updateReferees({ commit, dispatch }, payload) {
    commit('setGetting', true)

    try{
      payload.ref.update(_.omit(payload, ['ref', 'id']))
      .then(() => {
        dispatch('showSuccess', 'Professional Referees was successfully updated.', { root: true })
        dispatch('getReferees')
        commit('setGetting', false)
      })
      .catch(error => {
        commit('setError', error.message)
      })
    } catch(e) {
      console.log(e.message)
    }
  },

  async deleteReferees({ commit, dispatch }, payload) {
    commit('setGetting', true)

    try{
      await payload.ref.delete()
      .then(() => {
        commit('setGetting', false)
        dispatch('getReferees')
        dispatch('showSuccess', 'Removed Professional Referees', { root: true })
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
    } catch(e) {
      console.log(e.message)
    }
  },

/* -------------------------------------------------------------------------- */
/*                          GET PROFESSIONAL REFEREES                         */
/* -------------------------------------------------------------------------- */
getReferees({ commit, rootState }) {
  commit('setGetting', true)

  db.collection('users')
  .doc(rootState.user.user.ref.id)
  .collection('referees')
  .get()
  .then(snapshot => {
    if (snapshot.size) {
      commit('reInitReferees')
      
      snapshot.forEach(item => {
        commit('setReferees', item)
      })
    }
    commit('setGetting', false)
  })
  .catch(error => {
    commit('setError', error.message)
  })
},

/* -------------------------------------------------------------------------- */
/*                                ADD EXPERIENCE                                */
/* -------------------------------------------------------------------------- */
addExperience({ commit, dispatch, rootState }, payload) {
    commit('setAddingState', true)
    let experience = payload

    experience.createdAt = Date.now()
    experience.updatedAt = Date.now()
    
    db.collection('users')
    .doc(rootState.user.user.ref.id)
    .collection('experience')
    .add(_.omitBy(experience, _.isUndefined))
    .then((docRef) => {
      dispatch('showSuccess', 'Experience successfully added.', { root: true })
      
      experience.ref = docRef
      experience.id = docRef.id
      commit('insertExperience', experience)
      commit('setAddingState', false)
    })
    .catch(error => {
      commit('setError', error.message)
      commit('setAddingState', false)
    })
  },

/* -------------------------------------------------------------------------- */
/*                               UPDATE EXPERIENCE                              */
/* -------------------------------------------------------------------------- */
  async updateExperience({ commit, dispatch }, payload) {
    commit('updatingExperienceState', true)

    await payload.ref.update(_.omit(payload, ['ref', 'id']))
    .then(() => {
      dispatch('showSuccess', 'Successfully updated.', { root: true })
      commit('updatingExperienceState', false)
      commit('updateExperience', payload)
    })
    .catch(error => {
      console.log(error.message)
      commit('updatingExperienceState', false)
      dispatch('showError', error.message, { root: true })
    })
  },

/* -------------------------------------------------------------------------- */
/*                              DELETE EXPERIENCE                             */
/* -------------------------------------------------------------------------- */
  async deleteExperience({ commit, dispatch }, payload) {
    commit('deletingState', true)

    await payload.ref.delete()
    .then(() => {
      dispatch('getExperience')
      commit('deletingState', false)
      dispatch('showSuccess', 'Removed Experience', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      dispatch('deletingState', error.message, { root: true })
    })
  },

  /* -------------------------------------------------------------------------- */
  /*                          GET EXPERIENCE                                    */
  /* -------------------------------------------------------------------------- */
  getExperience({ commit, rootState }) {
    commit('setGetting', true)

    try{
      db.collection('users').doc(rootState.user.user.ref.id).collection('experience')
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          commit('reInitExperience')
          snapshot.forEach(item => {
            commit('setExperience', item)
          })
        }
        commit('setGetting', false)
      })
      .catch(error => {
        commit('setError', error.message)
      })
    } catch(e) {
      console.log(e.message)
    }
  },

  /*------------------------------------------------------------------------------
   * UPLOAD RESUME
   *----------------------------------------------------------------------------*/
  async uploadResume({ state, commit, dispatch }, file) {
    commit('uploadingState', true)
    commit('noneAttachmentState', true)

    await Promise.all([dispatch('uploadFile', file)])
    .then((response) => {
      if (state.profile.resume) {
        storageRef.child(`attachments/${state.profile.resume}`).delete()
      }

      let filename = response[0]
      
      if (!state.profile.ref) {
        state.profile.ref = state.user.ref.collection('profile').doc()
      }
      
      return state.profile.ref.set({ resume: filename }, { merge: true })
      .then(() => {
        commit('uploadingState', false)
        commit('updateProfileResume', filename)
      })
      .catch(error => {
        console.log(error.message)
        commit('uploadingState', false)
        dispatch('showError', error.message, { root: true })
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
